import { Directive, ElementRef, EventEmitter, Output, OnInit, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[elementViewInit]'
})
export class ElementViewInitDirective implements AfterViewInit {
    @Output('elementViewInit') elementViewInit = new EventEmitter<void>();

    constructor(private el: ElementRef) { }

    ngAfterViewInit(): void {
        // Emit the event after the view has been initialized
        // and element is rendered
        setTimeout(() => this.elementViewInit.emit());
    }

}