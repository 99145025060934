import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { AppModule, closedAccessItems, freeAccessItems } from 'src/app/shared/models';
import { DomainService } from 'src/app/shared/services/domain.service';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { AuthService } from '../auth/services/auth.service';
import { appModules } from '../models/app-modules-config.const';
import { UnsubscribeOnDestroy } from '../utils';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from 'src/app/core/state';

@Component({
    selector: 'ayac-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends UnsubscribeOnDestroy implements OnInit {
    menu: AppModule[] = []; // Modules need to be checked and filtered
    navStates = appModules;
    currentState: string = '';
    userSignedIn = false;
    showConsent = false;
    showMenu = false;
    footerText!: string;
    privacyUrl!: string;
    terms!: string;
    consent!: string;
    env: string;
    nonVendorLogin = true;
    readonly featureFlag = FeatureFlag;

    constructor(
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly _domainService: DomainService,
        private readonly _router: Router,
        private readonly _location: Location,
        private readonly _identityService: IdentityService,
        private readonly _authService: AuthService,
        private readonly _store: Store,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        this.env = this._domainService.environment();
        this.setCurrentState();
        this.handleRouteChanges();
        this.handleAuthEvents();

        this._store
            .select(selectIsAuthenticated)
            .pipe(takeUntil(this.d$))
            .subscribe((isAuth) => {
                if (isAuth) {
                    this.runChecks();
                }
            });
        this.runChecks();
    }

    runChecks(): void {
        this.checkSignedIn();
        this.checkHideMenu();
        this.checkAppModuleScopes();
        this.checkForVendorType();
    }

    setValues(): void {
        this.showConsent = this._domainService.check('ELECTRONIC_CONSENT');
        this.consent = this._domainService.getValue('ELECTRONIC_CONSENT');
        this.footerText = this._domainService.getValue('FOOTER_COPY');
        this.privacyUrl = this._domainService.getValue('PRIVACY_URL');
        this.terms = this._domainService.getValue('TERMS_OF_USE_URL');
    }

    setCurrentState(): void {
        const pathParts = this._location
            .path()
            .split('/')
            // Remove any query params
            .map((p) => {
                if (p.includes('?')) {
                    p = p.substring(0, p.indexOf('?'));
                }
                if (p.includes('%')) {
                    p = p.substring(0, p.indexOf('%'));
                }
                return p;
            })
            // Remove all items that include 'new', 'old', blanks and numbers
            .filter(
                (p) =>
                    p !== 'new' &&
                    p !== 'old' &&
                    p !== 'detail' &&
                    p !== 'details' &&
                    p !== '' &&
                    Number.isNaN(Number(p))
            )
            .join('/');

        this.currentState = pathParts;
    }

    handleRouteChanges(): void {
        this._router.events
            .pipe(
                filter((e): e is NavigationEnd => e instanceof NavigationEnd),
                takeUntil(this.d$)
            )
            .subscribe(() => {
                this.setCurrentState();
                this.setValues();
                this.runChecks();
            });
    }

    handleAuthEvents(): void {
        this._authService.OAuthSignedIn$.asObservable()
            .pipe(takeUntil(this.d$))
            .subscribe((signedIn) => {
                this.userSignedIn = signedIn;
                if (!signedIn) {
                    this.menu = [];
                    return;
                }
                this.runChecks();
            });
    }

    checkAppModuleScopes(): void {
        this.menu = [];
        this.navStates = appModules;
        appModules.forEach((mod) => {
            const states = [];
            mod.states.forEach((s) => {
                if (this._identityService.inScopeByModule(s.state) && s.name) {
                    states.push(s);
                }
            });

            if (states.length) {
                this.menu.push({
                    appModuleName: mod.appModuleName,
                    stateName: mod.stateName,
                    menuName:
                        mod.menuName.search(/^%+|%+$/g) !== -1
                            ? this._domainService.getValue(mod.menuName.replace(/^%+|%+$/g, ''))
                            : mod.menuName,
                    icon: mod.icon,
                    states: states
                });
            }
        });
    }

    checkSignedIn(): void {
        this.userSignedIn = this._identityService.isSignedIn();
    }

    checkHideMenu(): void {
        this.showMenu = true;
        if (!this.userSignedIn) {
            this.showMenu = false;
        }

        this._router.url.split('/').forEach((part) => {
            const partWithoutQueryParams = part.split('?');

            if (freeAccessItems.includes(part) || freeAccessItems.includes(partWithoutQueryParams[0])) {
                this.showMenu = false;
            }
            if (closedAccessItems.includes(part)) {
                this.showMenu = true;
            }
        });
    }

    checkForVendorType(): void {
        const loginType = this._identityService.type;
        this.nonVendorLogin = loginType === 'vendor' ? false : true;
    }

    toggleMenuOpen(elementId: number): void {
        // Get length of menu
        const indexes = this.menu.length - 1;

        for (let i = 0; indexes >= i; ++i) {
            // Grab each element to check
            const el = this._document.getElementById(`sidebar-${i}`);

            // If i and elementId match then that is the element we clicked
            if (i === elementId) {
                if (el.classList?.contains('in')) {
                    el.classList.remove('in');
                } else {
                    el.classList?.add('in');
                }
            } else {
                // These are all the other menu items
                if (el.classList?.contains('in')) {
                    el.classList.remove('in');
                }
            }
        }
    }
}
