import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { State } from 'src/app/shared/grid/models/state.model';
import { emptyValidator, notWhiteSpaceValidator } from 'src/app/shared/utilities';
import { VendorContact, ContactTag, User, UserTypeEnum } from 'src/app/shared/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { startWith, debounceTime, map, distinctUntilChanged, takeUntil, skip, switchMap } from 'rxjs/operators';
import { VendorContactChange } from '../vendor-contacts-change.model';
import { Vendor } from 'src/app/admin/vendor-contact-details/models/vendor.model';
import { VendorContactService } from 'src/app/admin/vendor-details/services';
import { availableUsernameValidator, businessEmailRequired } from 'src/app/admin/vendor-details/contacts/utilities';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { ApplicationPermissions } from 'src/app/shared/models/enums/application-permissions.enum';
import { OptionModel } from 'src/app/shared/models/option.model';
import { AuthService } from 'src/app/core/auth/services/auth.service';

@Component({
    selector: 'aya-vendor-contact-profile',
    templateUrl: 'vendor-contact-profile.component.html',
    styleUrls: ['./vendor-contact-profile.component.scss']
})
export class VendorContactProfileComponent extends UnsubscribeOnDestroy implements OnInit {
    // This is the only way to properly clear out the autocomplete inputs if attached to a mat-chip-list
    // See here: https://github.com/angular/components/issues/10968
    @ViewChild('tagsInput', { static: true }) tagsInput: ElementRef<HTMLInputElement>;
    @ViewChild('statesInput', { static: true }) statesInput: ElementRef<HTMLInputElement>;

    @Output() contactChanged: EventEmitter<VendorContactChange> = new EventEmitter<VendorContactChange>();
    @Input() contact: VendorContact;
    @Input() vendors: Vendor[] | null = null;
    @Input() states: State[];
    @Input() contactTags: ContactTag[];
    @Input('mainEmail') mainEmail$: Observable<string>;
    filteredTags$: Observable<ContactTag[]>;
    filteredStates$: Observable<State[]>;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    contactProfileForm: UntypedFormGroup;
    showPasswordField = true;
    canImpersonate: boolean;
    canManageLinkedProfiles: boolean;

    searchProfilesText$: BehaviorSubject<string> = new BehaviorSubject('');
    filteredProfiles$: Observable<OptionModel[]>;
    linkedProfilesSourse: OptionModel[] = [];
    linkedToParentProfile: OptionModel = null;
    currentEmail = '';
    initialLinkedProfilesLoad = true;
    userNameToEmailWarning = false;

    private originalUsername: string | null = null;
    private originalPassword: string | null = null;

    get hasParentProfile(): boolean {
        return !!this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.value;
    }
    get requiresPasswordReset(): boolean {
        return (
            !!this.contact.user?.linkedToParentProfileId &&
            !this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.value
        );
    }

    constructor(
        private readonly vendorService: VendorContactService,
        private readonly _authService: AuthService,
        private readonly _identityService: IdentityService
    ) {
        super();
    }

    ngOnInit(): void {
        this.contactProfileForm = this.createContactProfileForm(this.contact);

        this.contactProfileForm.valueChanges
            .pipe(
                skip(1),
                distinctUntilChanged(),
                debounceTime(500),
                map((contactForm: unknown) => this.convertContactForSaving(contactForm)),
                takeUntil(this.d$)
            )
            .subscribe((updatedContact: VendorContact) => {
                this.contactChanged.emit({
                    vendorContact: updatedContact,
                    changeType: 'profile',
                    isInvalidChange: this.isInvalid(updatedContact)
                });
            });

        if (this.contact.user && this.contact.user.username) {
            this.originalUsername = this.contact.user.username;
            this.originalPassword = this.contact.user.password;
            this.contactProfileForm.addControl('user', this.createUserForm(this.contact.user));
        }

        this.filteredTags$ = this.contactProfileForm.get('tagInput').valueChanges.pipe(
            startWith(null as string),
            debounceTime(300),
            map((value) => this.filterTags(value)),
            takeUntil(this.d$)
        );

        this.filteredStates$ = this.contactProfileForm.get('stateInput').valueChanges.pipe(
            startWith(null as string),
            debounceTime(300),
            map((value) => this.filterStates(value)),
            takeUntil(this.d$)
        );

        
        if (!this.contact?.id) {
            this.refreshPossibleParentLinkedProfiles();
        }
        this.mainEmail$
            .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.d$))
            .subscribe((emailAddress) => {
                const emailChanged = this.currentEmail !== emailAddress;
                this.currentEmail = emailAddress;
                if (!this.hasParentProfile) {
                    const usernameControl = this.contactProfileForm.get('user')?.get('username');
                    if (
                        usernameControl?.value !== emailAddress &&
                        this.contactProfileForm.get('portalSignIn')?.value
                    ) {
                        usernameControl?.setValue(emailAddress);
                        if (this.initialLinkedProfilesLoad) {
                            if (
                                this.originalUsername &&
                                emailAddress &&
                                this.contact.user?.isActive &&
                                this.originalUsername.toLowerCase() !== emailAddress.toLowerCase() &&
                                !this.contact.user?.linkedToParentProfileId
                            ) {
                                this.userNameToEmailWarning = true;
                            }
                        } else {
                            this.userNameToEmailWarning = false;
                            usernameControl?.markAsTouched();
                        }
                    }
                }
                if (emailChanged) {
                    this.refreshPossibleParentLinkedProfiles();
                }
            });

        this.canImpersonate = this._identityService.hasSecurityPermission(
            ApplicationPermissions.ImpersonateUsers
        );
        this.canManageLinkedProfiles = this._identityService.hasSecurityPermission(
            ApplicationPermissions.ManageLinkedProfiles
        );

        this.filteredProfiles$ = this.searchProfilesText$.pipe(
            takeUntil(this.d$),
            debounceTime(250),
            switchMap((search) =>
                of(
                    search
                        ? this.linkedProfilesSourse.filter((p) =>
                              p.name.toLowerCase().includes(search.toLowerCase())
                          )
                        : this.linkedProfilesSourse
                )
            )
        );

        this._checkPasswordRequresValidation();
    }

    private _checkPasswordRequresValidation(): void {
        if (!this.contactProfileForm) {
            return;
        }
        const passwordControl = this.contactProfileForm.get('user')?.get('password');
        this.showPasswordField =
            !this.hasParentProfile && this.contactProfileForm?.get('portalSignIn')?.value;
        if ((this.contact?.user?.id > 0 && !this.requiresPasswordReset) || !this.showPasswordField) {
            passwordControl?.clearValidators();
        } else {
            passwordControl?.setValidators([Validators.required, emptyValidator(), notWhiteSpaceValidator()]);
        }
        if (this.requiresPasswordReset) {
            passwordControl?.markAsTouched();
        }
        passwordControl?.updateValueAndValidity({ emitEvent: false });
    }

    filterTags(inputValue: string | null | ContactTag): ContactTag[] {
        const formContactTags = this.contactProfileForm.get('tags').value;

        if (formContactTags) {
            const ids = formContactTags.map((tag: ContactTag) => tag.id);

            if (inputValue && typeof inputValue === 'string') {
                const filterValue = inputValue.toLowerCase();
                return this.contactTags.filter(
                    (tag: ContactTag) => !ids.includes(tag.id) && tag.name.toLowerCase().includes(filterValue)
                );
            } else {
                return this.contactTags.filter((tag: ContactTag) => !ids.includes(tag.id));
            }
        }

        return this.contactTags;
    }

    filterStates(inputValue: string | null | State): State[] {
        const formContactStates = this.contactProfileForm.get('states').value;

        if (formContactStates) {
            const ids = formContactStates.map((state: State) => state.id);

            if (inputValue && typeof inputValue === 'string') {
                const filterValue = inputValue.toLowerCase();
                return this.states.filter(
                    (state: State) => !ids.includes(state.id) && state.name.toLowerCase().includes(filterValue)
                );
            } else {
                return this.states.filter((state: State) => !ids.includes(state.id));
            }
        }

        return this.states;
    }

    setUsernameRequiresBusinessEmailError(contact: VendorContact): void {
        this.contact = contact;
        this.contactProfileForm.get('user')?.get('username')?.markAsTouched();
        this.contactProfileForm.get('user')?.get('username')?.updateValueAndValidity();
    }

    addUser(): void {
        const userForm = this.contactProfileForm.get('user');

        if (userForm) {
            const updatedUser: any = {
                username: userForm.get('username').value,
                linkedToParentProfileId: userForm.get('linkedToParentProfileId')?.value ?? null,
                password: userForm.get('password').value,
                isActive: true
            };
            if (this.contact.user?.id) {
                updatedUser.id = this.contact.user.id;
            }
            this.contact.user = { ...this.contact.user, ...updatedUser };
            this.contactProfileForm.removeControl('user');
            this.contactProfileForm.addControl('user', this.createUserForm(updatedUser));
            this.contactProfileForm.get('user').markAllAsTouched();
            this.contactProfileForm.get('user').updateValueAndValidity({ emitEvent: false });
        } else {
            const newUser: any = { username: '', linkedToParentProfileId: null, password: '', isActive: true };
            this.contact.user = newUser;
            this.contactProfileForm.addControl('user', this.createUserForm(newUser));
        }

        this.contactProfileForm.patchValue({ portalSignIn: true });
        this._checkPasswordRequresValidation();
        this.removeLinkedToParent();
    }

    removeUser(): void {
        this.contact.user.isActive = false;

        const usernameControl = this.contactProfileForm.get('user').get('username');
        usernameControl.clearValidators();
        usernameControl.clearAsyncValidators();
        usernameControl.updateValueAndValidity({ emitEvent: false });

        const passwordControl = this.contactProfileForm.get('user').get('password');

        if (passwordControl) {
            passwordControl.clearValidators();
            passwordControl.updateValueAndValidity({ emitEvent: false });
        }

        this.contactProfileForm.patchValue({
            portalSignIn: false,
            user: {
                username: this.originalUsername,
                password: this.originalPassword
            }
        });

        this.removeLinkedToParent();
    }

    addTag(event: MatAutocompleteSelectedEvent): void {
        const tag: ContactTag | null = event.option?.value || null;
        if (tag) {
            const tags = this.contactProfileForm.get('tags').value;
            if (tags) {
                tags.push(tag);
                this.contactProfileForm.patchValue({ tags });
            } else {
                this.contactProfileForm.patchValue({ tags: [tag] });
            }
            this.clearTagInput();
        } else {
            console.error('Tag invalid or not found.');
        }
    }

    removeTag(tag: ContactTag): void {
        const tagIndex: number = this.contactProfileForm
            .get('tags')
            .value.findIndex((contactTag: ContactTag) => contactTag.id === tag.id);

        if (tagIndex >= 0) {
            this.clearTagInput();
            this.contactProfileForm.get('tags').value.splice(tagIndex, 1);
            this.contactProfileForm.get('tags').updateValueAndValidity();
        } else {
            console.error(`Unable to find tag matching id '${tag.id}'`);
        }
    }

    addState(event: MatAutocompleteSelectedEvent): void {
        const state: State | null = event.option?.value || null;
        if (state) {
            const states = this.contactProfileForm.get('states').value;
            if (states) {
                states.push(state);
                this.contactProfileForm.patchValue({ states });
            } else {
                this.contactProfileForm.patchValue({ states: [state] });
            }
            this.clearStateInput();
        } else {
            console.error('State invalid or not found.');
        }
    }

    removeState(state: State): void {
        const stateIndex: number = this.contactProfileForm
            .get('states')
            .value.findIndex((contactTag: ContactTag) => contactTag.id === state.id);

        if (stateIndex >= 0) {
            this.clearStateInput();
            this.contactProfileForm.get('states').value.splice(stateIndex, 1);
            this.contactProfileForm.get('states').updateValueAndValidity();
        } else {
            console.error(`Unable to find state matching id '${state.id}'`);
        }
    }

    createUserForm(user: User): UntypedFormGroup {
        const form = new UntypedFormGroup({
            username: new UntypedFormControl(
                user.username,
                [Validators.required, emptyValidator(), notWhiteSpaceValidator()],
                [
                    availableUsernameValidator(this.vendorService, this.originalUsername, this.contact.user?.isActive),
                    () => of(businessEmailRequired(this.contact))
                ]
            ),
            linkedToParentProfileId: new UntypedFormControl(user?.linkedToParentProfileId)
        });
        form.addControl(
            'password',
            new UntypedFormControl(user.password, [Validators.required, emptyValidator(), notWhiteSpaceValidator()])
        );
        return form;
    }

    createContactProfileForm(contact: VendorContact): UntypedFormGroup {
        return new UntypedFormGroup({
            vendor: new UntypedFormControl(
                contact.vendorId
                    ? {
                          id: contact.vendorId,
                          name: this.getVendorName(contact.vendorId)
                      }
                    : null,
                this.vendors ? Validators.required : null
            ),
            firstName: new UntypedFormControl(contact.firstName, [
                Validators.required,
                emptyValidator(),
                notWhiteSpaceValidator()
            ]),
            lastName: new UntypedFormControl(contact.lastName, [
                Validators.required,
                emptyValidator(),
                notWhiteSpaceValidator()
            ]),
            portalSignIn: new UntypedFormControl(
                contact.user && contact.user.username && contact.user.isActive ? true : false
            ),
            tags: new UntypedFormControl(this.convertTagIdsToContactTags(contact.tags)),
            states: new UntypedFormControl(this.convertStateIdsToStates(contact.states)),
            tagInput: new UntypedFormControl(),
            stateInput: new UntypedFormControl()
        });
    }

    isInvalid(updatedContact: VendorContact): boolean {
        // Have to do a manual check here as the validators
        // don't catch the invalid data if the value is an empty string
        let userInvalid = false;
        if (updatedContact.user.isActive) {
            const missingUsername: boolean = updatedContact.user?.username.length === 0;
            const missingPassword: boolean = this.showPasswordField && updatedContact.user?.password?.length === 0;
            userInvalid = missingUsername || (missingPassword && updatedContact.user?.id === 0);
        }

        return this.contactProfileForm.invalid || !updatedContact.lastName || !updatedContact.firstName || userInvalid;
    }

    convertContactForSaving(contactForm): VendorContact {
        return {
            ...this.contact,
            vendorId: contactForm.vendor?.id,
            firstName: contactForm.firstName,
            lastName: contactForm.lastName,
            tags: contactForm.tags.map((tag: ContactTag) => tag.id),
            states: contactForm.states.map((state: State) => state.id),
            user: {
                ...this.contact.user,
                id: this.contact.user?.id || 0,
                username: contactForm.user?.username,
                linkedToParentProfileId: contactForm.user?.linkedToParentProfileId,
                password: contactForm.user?.password,
                isActive: contactForm.portalSignIn
            }
        };
    }

    impersonate() {
        if (this.contact.user?.coreUserId) {
            this._identityService.changeProfile(this.contact.user.coreUserId, true);
        }
    }

    parentProfileChanged(profileModel) {
        if (!profileModel?.id || !profileModel?.value) {
            this.removeLinkedToParent();
        } else {
            this.addLinkedToParent({ id: profileModel.id, name: profileModel.value });
        }
        this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.markAsDirty();
        this.userNameToEmailWarning = false;
    }

    private addLinkedToParent(profileModel: OptionModel) {
        if (!profileModel?.id) {
            return;
        }
        this.linkedToParentProfile = profileModel;
        const linkedToParentControl = this.contactProfileForm?.get('user')?.get('linkedToParentProfileId');
        if (linkedToParentControl?.value !== profileModel?.id) {
            linkedToParentControl?.setValue(profileModel?.id);
        }
        this.searchProfilesText$.next('');
        this._checkPasswordRequresValidation();
    }

    private removeLinkedToParent() {
        this.linkedToParentProfile = null;
        const linkedToParentControl = this.contactProfileForm?.get('user')?.get('linkedToParentProfileId');
        if (linkedToParentControl?.value) {
            linkedToParentControl?.setValue(null);
        }
        this.searchProfilesText$.next('');

        if (this.contactProfileForm.get('portalSignIn')?.value) {
            const userNameControl = this.contactProfileForm?.get('user')?.get('username');
            if (userNameControl?.value !== this.currentEmail) {
                userNameControl?.setValue(this.currentEmail);
                userNameControl?.markAllAsTouched();
            }
        }
        const passwordControl = this.contactProfileForm?.get('user')?.get('password');
        if (passwordControl?.value) {
            passwordControl?.setValue('');
        }
        this._checkPasswordRequresValidation();
    }

    refreshPossibleParentLinkedProfiles() {
        this._authService
            .searchParentLinkedProfiles(this.currentEmail, UserTypeEnum.VendorContact, this.contact.user?.coreUserId)
            .pipe(takeUntil(this.d$))
            .subscribe((parentProfiles: OptionModel[]) => {
                this.linkedProfilesSourse = parentProfiles;
                this.searchProfilesText$.next('');
                if (this.initialLinkedProfilesLoad) {
                    this.initialLinkedProfilesLoad = false;
                    const linkedProfileId = this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.value;
                    const parentProfile = this.linkedProfilesSourse.find(
                        (s) => s.id.toLowerCase() === linkedProfileId?.toLowerCase()
                    );
                    if (parentProfile) {
                        this.addLinkedToParent(parentProfile);
                    } else {
                        this.removeLinkedToParent();
                    }
                }
            });
    }

    private getVendorName(vendorId: number): string | null {
        const vendor = this.vendors?.find((v: Vendor) => v.id === vendorId);
        if (vendor) {
            return vendor.name;
        }

        return null;
    }

    private convertTagIdsToContactTags(contactTagIds: number[]): ContactTag[] {
        if (contactTagIds && contactTagIds.length) {
            return this.contactTags.filter((tag: ContactTag) => contactTagIds.includes(tag.id));
        }

        return [];
    }

    private convertStateIdsToStates(contactStateIds: number[]): State[] {
        if (contactStateIds && contactStateIds.length) {
            return this.states.filter((state: State) => contactStateIds.includes(state.id));
        }

        return [];
    }

    private clearTagInput(): void {
        this.contactProfileForm.get('tagInput').setValue(null, { emitEvent: false });
        this.tagsInput.nativeElement.value = '';
    }

    private clearStateInput(): void {
        this.contactProfileForm.get('stateInput').setValue(null, { emitEvent: false });
        this.statesInput.nativeElement.value = '';
    }
}
