import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import {
    exportContractRequirements,
    loadViewRequirementDetails
} from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-details.actions';
import {
    ContractRequirementDocument,
    ContractRequirement,
    DocumentRequirementStatus,
    ContractRequirements,
    Candidate
} from 'src/app/shared/models/candidate';
import { selectContractRequirements, selectContractsLoading, selectVendorCandidate } from '../../store/selectors';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

const MAX_FILE_SIZE_MB = 50;
const MAX_FILE_SIZE = 1024 * 1024 * MAX_FILE_SIZE_MB; // 50Mb
const SUPPORT_FILE_TYPES = ['PDF', 'TIFF', 'JPEG', 'JPG', 'DOC', 'DOCX', 'HTML', 'PNG', 'TXT'];
const SUPPORTED_MIME_TYPES = [
    'application/pdf',
    'image/tiff',
    'image/jpeg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/html',
    'image/png',
    'text/plain'
];

@Component({
    selector: 'ayac-contract-requirements-tab',
    templateUrl: 'contract-requirements-tab.component.html',
    styleUrls: ['contract-requirements-tab.component.scss']
})
export class ContractRequirementsTabComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild('viewRequirementDetails', { static: true }) viewRequirementDetails: TemplateRef<unknown>;
    @Output() uploadDocuments: EventEmitter<{ document: ContractRequirementDocument; files: File[] }> =
        new EventEmitter<{ document: ContractRequirementDocument; files: File[] }>();

    @Input() credentialingSpecialist: string | null = null;
    @Input() credentialingSpecialistEmail: string | null = null;
    @Input() contractRequirements: ContractRequirement[] = [];
    @Input() candidateId = 0;
    @Input() candidateName = '';
    @Input() isContractRequirementsLoading: boolean;
    @Input() isLoading: boolean;
    documentUploadError: string | null = null;
    displayedColumns = ['status', 'upload-error', 'title', 'expiration', 'view-details', 'upload-document'];
    status = DocumentRequirementStatus;
    panelIsOpen = true;
    isLoading$: Observable<LoadingTypes>;
    contractRequirements$: Observable<ContractRequirements>;
    dataFlowFixFlag = false;
    isContractRequirementsLoading$: Observable<LoadingTypes>;
    candidate$: Observable<Candidate>;
    readonly loadingTypes = LoadingTypes;
    readonly featureFlag = FeatureFlag;

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this._ldFeatureManager
            .isEnabled(FeatureFlag.VendorConnectCandidateDetailsDataFlowFix)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.dataFlowFixFlag = isEnabled;

                if (this.dataFlowFixFlag) {
                    this.handleInitialData();
                }
            });
    }

    handleInitialData() {
        this.isLoading$ = this.store.select(selectContractsLoading);
        this.candidate$ = this.store
            .select(selectVendorCandidate)
            .pipe(tap((candidate) => (this.candidateName = candidate?.firstName + ' ' + candidate?.lastName)));
        this.contractRequirements$ = this.store.select(selectContractRequirements);
        this.store.dispatch(actions.getContractRequirements({ candidateId: this.candidateId }));
    }

    viewDocumentDetails(document: ContractRequirementDocument): void {
        this.isLoading$ = this.store.select(selectContractsLoading);

        this.store.dispatch(
            loadViewRequirementDetails({
                docTypeId: document.documentTypeId,
                contractId: document.contractId,
                title: document.title
            })
        );
    }

    onFileChange(event: Event, document: ContractRequirementDocument): void {
        const files = Array.from((event.target as HTMLInputElement).files);
        const fileError = this.validateFiles(files);

        if (fileError === null) {
            document.isUploadError = false;
            this.uploadDocuments.emit({ document, files });
        } else {
            document.isUploadError = true;
            if (fileError.fileTooLarge) {
                this.showDocumentUploadError(`Your file was too large to add. ${MAX_FILE_SIZE_MB} Mb max.`);
            } else if (fileError.fileTypeUnsupported) {
                this.showDocumentUploadError(
                    `That file type isn't supported. Supported files: ${SUPPORT_FILE_TYPES.join(', ')}.`
                );
            }
        }
    }

    validateFiles(files: File[] | null): null | ValidationErrors {
        if (files && files.length) {
            const filesTotalSize: number = files.reduce((partialSum, file) => partialSum + file.size, 0);

            const filesWithBadType = files.filter((file: File) => {
                if (file.type) {
                    return !SUPPORTED_MIME_TYPES.includes(file.type);
                } else {
                    const fileParts = file.name.split('.');
                    const extension = fileParts.length > 1 ? fileParts[fileParts.length - 1].toUpperCase() : null;
                    return extension ? !SUPPORT_FILE_TYPES.includes(extension) : true;
                }
            });

            if (filesTotalSize > MAX_FILE_SIZE) {
                return { fileTooLarge: true };
            }

            if (filesWithBadType.length > 0) {
                return { fileTypeUnsupported: true };
            }

            return null;
        }

        return { noFiles: true };
    }

    showDocumentUploadError(message: string): void {
        this.documentUploadError = message;

        timer(5000)
            .pipe(takeUntil(this.d$))
            .subscribe(() => {
                this.documentUploadError = null;
            });
    }

    exportContractRequirements() {
        this.store.dispatch(
            exportContractRequirements({ candidateId: this.candidateId, candidateName: this.candidateName })
        );
    }

    docUploadOrEditClick(event: Event, document: ContractRequirementDocument, isUpdate: boolean): void {
        if (isUpdate) {
            this.router.navigate([`/vendor/candidate/${this.candidateId}/document-upload`], {
                queryParams: {
                    docType: document.documentTypeId,
                    edit: true,
                    candidateDocumentId: document.candidateDocumentId
                }
            });
        } else {
            this.router.navigate([`/vendor/candidate/${this.candidateId}/document-upload`], {
                queryParams: { docType: document.documentTypeId }
            });
        }
    }
}
