import { createAction, props } from '@ngrx/store';
import { JobsQueryState } from './reducers/jobs-query.reducer';
import { JobList } from '../models/job-list.model';
import { ApprovalStatusLookup } from '../models/lookups/approval-status.lookup';
import { ReleaseStatusLookup } from '../models/lookups/release-status.lookup';
import { FacilityLookup } from '../models/lookups/facility.lookup';
import { UnitLookup } from '../models/lookups/unit.lookup';
import { Job } from '../models/job.model';
import { Submittal } from '../models/submittal.model';
import { ExpertiseLookup, ProfessionLookup, JobTemplateLookup, FilledPosition } from '../models';
import { JobTemplateExpanded } from '../models/job-template-expanded.model';
import { JobTemplateListItem } from '../models/job-template-list-item.model';
import { JobTemplateSaveModel } from '../models/job-template-save.model';
import { ScheduledReleaseRule } from '../models/lookups/scheduled-release-rule.lookup';
import { JobExtentionsState } from 'src/app/jobs/store/reducers/jobs-extentions.reducer';
import { FileValidationResult } from '../services/job-attachments.service';
import { JobFeatureList } from '../models/lookups/job-feature-list.lookup';
import { FileAttachment } from 'src/app/shared/models/attachment';
import { AttachmentFeature } from 'src/app/shared/models';
import { OriginalJob } from '../models/original-job.model';
import { FacilityAddressInfo } from '../models/lookups/facilityaddressinfo';
import { WorkLocation } from '../models/work-location.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LineOfBusinessEnum } from 'src/app/shared/models/facilities/enums/line-of-business.enum';
import { ListItem } from '../../shared/models/list-item';
import { DuplicateJobAttachmentResult } from '../models/responses/duplicate-jobAttachment-result';
import { CoverageTypeLookup } from '../models/lookups/coverage-type.lookup';
import { SubmittalStatusLookup } from 'src/app/shared/models/submittals';
import { HttpErrorResponse } from '@angular/common/http';

export enum JobsActionEnum {
    LoadJobs = '[JOBS] Load Jobs',
    LoadJobsSuccess = '[JOBS API] Load Jobs success',
    LoadJobsFail = '[JOBS API] Load Jobs fail',
    LoadJobsTemplateFail = '[JOBS TEMPLATE API] Load Jobs Template Fail',
    SetJobsSearchQuery = '[JOBS] Set Jobs search query',
    SetDashboardJobsSearchQuery = '[Dashboard component] Set Dashboard Jobs search query',

    SaveJob = '[JOBS] Save Job',
    SaveJobSuccess = '[JOBS] Save Job success',
    SaveJobFail = '[JOBS] Save Job fail',
    SaveDuplicateJobSuccess = '[JOBS] Save Duplicate Job With Copied Attachments',

    LoadJobsLookups = '[JOBS] Load Jobs Lookups',
    LoadJobsLookupsSuccess = '[JOBS] Load Jobs Lookups success',
    LoadJobsLookupsFail = '[JOBS] Load Jobs Lookups fail',

    LoadJobPayrollLocationLookups = '[JOBS] Load Job Payroll Location Lookups',
    LoadJobPayrollLocationLookupsSuccess = '[JOBS] Load Job Payroll Location Lookups success',
    LoadJobPayrollLocationLookupsFail = '[JOBS] Load Job Payroll Location Lookups fail',
    ClearJobPayrollLocationLookups = '[JOBS] Clear Job Payroll Location Lookups',

    LoadJobBusinessUnitLookups = '[JOBS] Load Job Business Unit Lookups',
    LoadJobBusinessUnitLookupsSuccess = '[JOBS] Load Job Business Unit Lookups success',
    LoadJobBusinessUnitLookupsFail = '[JOBS] Load Job Business Unit Lookups fail',
    ClearJobBusinessUnitLookups = '[JOBS] Clear Job Business Unit Lookups',

    LoadJobBuildingLookups = '[JOBS] Load Job Building Lookups',
    LoadJobBuildingLookupsSuccess = '[JOBS] Load Job Building Lookups success',
    LoadJobBuildingLookupsFail = '[JOBS] Load Job Building Lookups fail',
    ClearJobBuildingLookups = '[JOBS] Clear Job Building Lookups',

    LoadJobDepartmentLookups = '[JOBS] Load Job Department Lookups',
    LoadJobDepartmentLookupsSuccess = '[JOBS] Load Job Department Lookups success',
    LoadJobDepartmentLookupsFail = '[JOBS] Load Job Department Lookups fail',
    ClearJobDepartmentLookups = '[JOBS] Clear Job Department Lookups',

    LoadJobFloorLookups = '[JOBS] Load Job Floor Lookups',
    LoadJobFloorLookupsSuccess = '[JOBS] Load Job Floor Lookups success',
    LoadJobFloorLookupsFail = '[JOBS] Load Job Floor Lookups fail',
    ClearJobFloorLookups = '[JOBS] Clear Job Floor Lookups',

    LoadGroupMetadata = '[JOBS] Load Group Metadata',
    LoadGroupMetadataSuccess = '[JOBS] Load Group Metadata success',
    LoadGroupMetadataFail = '[JOBS] Load Group Metadata fail',
    ClearGroupMetadata = '[JOBS] Clear Group Metadata',

    LoadFacilityMetadata = '[JOBS] Load Facility Metadata',
    LoadFacilityMetadataSuccess = '[JOBS API] Load Facility Metadata success',
    LoadFacilityMetadataFail = '[JOBS API] Load Facility Metadata fail',
    LoadJobsBillingRateFail = '[JOBS BILLING API] Load Jobs Billing Rate Fail',
    MetadataDisable = '[JOBS] Metadata Disable',
    ClearMetadata = '[JOBS] Clear Metadata',
    ClearMetadataNoGroupId = '[JOBS EDIT COMPONENT] Clear Metadata No Facility GroupID',

    ApproveJobs = '[JOBS] Approve Jobs',
    ApproveJobsSuccess = '[JOBS] Approve Jobs success',
    ApproveJobsFail = '[JOBS] Approve Jobs fail',
    ApproveJobsAndReloadGrid = '[JOBS] Approve Jobs and Reload Grid',

    RejectJobs = '[JOBS] Reject Jobs',
    RejectJobsSuccess = '[JOBS] Reject Jobs success',
    RejectJobsFail = '[JOBS] Reject Jobs fail',
    RejectJobsAndReloadGrid = '[JOBS] Reject Jobs and Reload Grid',

    ReleaseJobs = '[JOBS] Release Jobs',
    ReleaseJobsSuccess = '[JOBS] Release Jobs success',
    ReleaseJobsFail = '[JOBS] Release Jobs fail',
    ReleaseJobsAndReloadGrid = '[JOBS] Release Jobs and Reload Grid',

    LoadJobDetails = '[JOBS] Load Job Details',
    LoadJobDetailsSuccess = '[JOBS] Load Job Details success',
    LoadJobDetailsFail = '[JOBS] Load Job Details fail',

    ClearJobDetails = '[JOBS] Clear Job Details',

    LoadJobTemplate = '[JOBS] Load Job Template',
    LoadJobTemplateSuccess = '[JOBS] Load Job Template success',
    LoadJobTemplateFail = '[JOBS] Load Job Template fail',

    SetJobPositions = '[JOBS] Set Job Positions',
    DeleteJob = '[JOBS] Delete Job',

    LoadJobSubmittals = '[JOBS] Load Job Submittals',
    LoadJobSubmittalsSuccess = '[JOBS] Load Job Submittals success',

    LoadJobFilledPositions = '[JOBS] Load Job Filled Positions',
    LoadJobFilledPositionsSuccess = '[JOBS] Load Job Filled Positions success',

    ShowFailMessage = '[JOBS] Show fail message',

    NullAction = '[JOBS] Do nothig awesome action!',

    LoadJobTemplates = '[JOBS] Load Job Templates',
    LoadJobTemplatesSuccess = '[JOBS] Load Job Templates success',
    LoadJobTemplatesFail = '[JOBS] Load Job Templates fail',

    SetJobTemplatesSearchQuery = '[JOBS] Set Jobs Templates search query',

    LoadJobTemplateDetails = '[JOBS] Load Job Template Details',
    LoadJobTemplateDetailsSuccess = '[JOBS] Load Job Template Details success',
    LoadJobTemplateDetailsFail = '[JOBS] Load Job Template Details fail',

    LoadJobBillingRate = '[JOBS] Load Jobs Billing Rate',
    LoadJobBillingRateSuccess = '[JOBS] Load Job Billing Rate success',
    LoadJobBillingRateFail = '[JOBS] Load Job Billing Rate fail',
    ClearJobBillingRate = '[JOBS] Clear Jobs Billing Rate',

    ClearJobTemplateDetails = '[JOBS] Clear Job Template Details',

    LoadJobExtentionsSuccess = '[JOBS] Load Job Extentions Success',
    ClearJobExtentions = '[JOBS] Clear Job Extentions',

    SaveJobTemplate = '[JOBS] Save Job Template',
    SaveJobTemplateSuccess = '[JOBS] Save Job Template success',
    SaveJobTemplateFail = '[JOBS] Save Job Template fail',

    DeleteJobTemplate = '[JOBS] Delete Job Template',
    DeleteJobTemplateSuccess = '[JOBS] Delete Job Template success',
    DeleteJobTemplateFail = '[JOBS] Delete Job Template fail',

    LoadJobTemplatesLookups = '[JOBS] Load Job Templates Lookups',
    LoadJobTemplatesLookupsSuccess = '[JOBS] Load Job Templates Lookups success',
    LoadJobTemplatesLookupsFail = '[JOBS] Load Job Templates Lookups fail',

    UpdateMspFieldsVisibility = '[JOBS] Update Msp Fields Visibility',

    ExportJobs = '[JOBS] Export Jobs',
    ExportJobsSuccess = '[JOBS] Export Jobs success',
    ExportJobsFail = '[JOBS] Export Jobs fail',

    ImportJobs = '[JOBS] Import Jobs',
    ImportJobsSuccess = '[JOBS] Import jobs Success',
    ImportJobsFail = '[JOBS] Import Jobs Fail',
    ImportJobsReset = '[JOBS] Import Jobs Reset',

    LoadSubmittalStatuses = '[JOBS] Load Submittal Statuses',
    LoadSubmittalStatusesV2 = '[JOBS] Load Submittal Statuses V2',
    LoadSubmittalStatusesSuccess = '[JOBS] Load Submittal Statuses Success',
    LoadSubmittalStatusesFail = '[JOBS] Load Submittal Statuses Failure',
    IrpLookupsStatusesSuccess = '[JOBS] Load Submittals IRP Statuses Success'
}

export const loadJobs = createAction(JobsActionEnum.LoadJobs);

export const loadJobsSuccess = createAction(
    JobsActionEnum.LoadJobsSuccess,
    props<{
        jobs: Array<JobList>;
        total: number;
        openPositionsCount: number;
        allPositionsCount: number;
        hasIneligibleApprovers: boolean;
    }>()
);

export const loadJobsFail = createAction(JobsActionEnum.LoadJobsFail, props<{ error: any }>());

export const setJobsSearchQuery = createAction(JobsActionEnum.SetJobsSearchQuery, props<{ query: JobsQueryState }>());

export const setDashboardJobsSearchQuery = createAction(
    JobsActionEnum.SetDashboardJobsSearchQuery,
    props<{
        lineOfBusinessId?: number;
        facilityIds?: number[];
        unitId?: number;
        statusId?: number;
    }>()
);

// Lookups

export const loadJobsLookups = createAction(JobsActionEnum.LoadJobsLookups);

export const loadJobsLookupsSuccess = createAction(
    JobsActionEnum.LoadJobsLookupsSuccess,
    props<{
        facilities?: Array<FacilityLookup>;
        facilitiesWithoutEmploymentTypes?: Array<FacilityLookup>;
        units?: Array<UnitLookup>;
        jobSecuritySystemFeatures?: Array<JobFeatureList>;
        approvalStatuses: Array<ApprovalStatusLookup>;
        releaseStatuses: Array<ReleaseStatusLookup>;
        unionCodes: Array<ListItem>;
        expertises: ExpertiseLookup[];
        canApprove: boolean;
        canReject: boolean;
        canRelease: boolean;
        canRevoke: boolean;
        canViewApprovalStatus: boolean;
    }>()
);

export const loadJobsLookupsFail = createAction(JobsActionEnum.LoadJobsLookupsFail, props<{ error: any }>());

export const loadJobPayrollLocationLookups = createAction(
    JobsActionEnum.LoadJobPayrollLocationLookups,
    props<{ businessUnitId: number; filter: string }>()
);

export const clearJobPayrollLocationLookups = createAction(JobsActionEnum.ClearJobPayrollLocationLookups);

export const loadJobPayrollLocationLookupsSuccess = createAction(
    JobsActionEnum.LoadJobPayrollLocationLookupsSuccess,
    props<{
        lookups: Array<any>;
    }>()
);

export const loadJobPayrollLocationLookupsFail = createAction(
    JobsActionEnum.LoadJobPayrollLocationLookupsFail,
    props<{ error: any }>()
);

export const loadWorkLocations = createAction('[JOBS EDIT] Load Work Locations', props<{ facilityId: number }>());

export const loadWorkLocationsSuccess = createAction(
    '[JOBS EDIT] Load Work Locations Success',
    props<{ workLocations: WorkLocation[] }>()
);

export const loadWorkLocationsFail = createAction('[JOBS EDIT] Load Work Locations Fail', props<{ error: unknown }>());

export const loadFacilityId = createAction(
    '[JOBS EDIT] Load Facility Id',
    props<{ profileGroupId: number; professionId: number }>()
);

export const loadFacilityIdSuccess = createAction(
    '[JOBS EDIT] Load Facility Id Success',
    props<{ facilityId: number }>()
);

export const loadFacilityIdFail = createAction('[JOBS EDIT] Load Facility Id Fail', props<{ error: any }>());

export const loadJobBusinessUnitLookups = createAction(
    JobsActionEnum.LoadJobBusinessUnitLookups,
    props<{ systemId: number; filter: string }>()
);

export const clearJobBusinessUnitLookups = createAction(JobsActionEnum.ClearJobBusinessUnitLookups);

export const loadJobBusinessUnitLookupsSuccess = createAction(
    JobsActionEnum.LoadJobBusinessUnitLookupsSuccess,
    props<{
        lookups: Array<any>;
    }>()
);

export const loadJobBusinessUnitLookupsFail = createAction(
    JobsActionEnum.LoadJobBusinessUnitLookupsFail,
    props<{ error: any }>()
);

export const loadJobBuildingLookups = createAction(JobsActionEnum.LoadJobBuildingLookups, props<{ filter: string }>());

export const clearJobBuildingLookups = createAction(JobsActionEnum.ClearJobBuildingLookups);

export const loadJobBuildingLookupsSuccess = createAction(
    JobsActionEnum.LoadJobBuildingLookupsSuccess,
    props<{
        lookups: Array<any>;
    }>()
);

export const loadJobBuildingLookupsFail = createAction(
    JobsActionEnum.LoadJobBuildingLookupsFail,
    props<{ error: any }>()
);

export const loadJobDepartmentLookups = createAction(
    JobsActionEnum.LoadJobDepartmentLookups,
    props<{ businessUnitId: number; filter: string }>()
);

export const clearJobDepartmentLookups = createAction(JobsActionEnum.ClearJobDepartmentLookups);

export const loadJobDepartmentLookupsSuccess = createAction(
    JobsActionEnum.LoadJobDepartmentLookupsSuccess,
    props<{
        lookups: Array<any>;
    }>()
);

export const loadJobDepartmentLookupsFail = createAction(
    JobsActionEnum.LoadJobDepartmentLookupsFail,
    props<{ error: any }>()
);

export const loadJobFloorLookups = createAction(
    JobsActionEnum.LoadJobFloorLookups,
    props<{ buildingId: number; filter: string }>()
);

export const clearJobFloorLookups = createAction(JobsActionEnum.ClearJobFloorLookups);

export const loadJobFloorLookupsSuccess = createAction(
    JobsActionEnum.LoadJobFloorLookupsSuccess,
    props<{
        lookups: Array<any>;
    }>()
);

export const loadJobFloorLookupsFail = createAction(JobsActionEnum.LoadJobFloorLookupsFail, props<{ error: any }>());

// Metadata
export const loadGroupMetadata = createAction(JobsActionEnum.LoadGroupMetadata, props<{ facilityGroupId: number }>());

export const loadGroupMetadataSuccess = createAction(
    JobsActionEnum.LoadGroupMetadataSuccess,
    props<{
        canSaveTemplate: boolean;
        canViewBillingRate: boolean;
        canEditBillingRate: boolean;
        templates: Array<JobTemplateLookup>;
        professions: Array<ProfessionLookup>;
        showPrismaFields: boolean;
        showBjcFields: boolean;
        showNyuFields: boolean;
        showClientJobIdField: boolean;
    }>()
);

export const loadGroupMetadataFail = createAction(JobsActionEnum.LoadGroupMetadataFail, props<{ error: any }>());

export const clearGroupMetadata = createAction(JobsActionEnum.ClearGroupMetadata);

export const loadFacilityMetadata = createAction(
    JobsActionEnum.LoadFacilityMetadata,
    props<{ facilityGroupId: number; professionId: number; expertiseId: number }>()
);

export const loadFacilityMetadataSuccess = createAction(
    JobsActionEnum.LoadFacilityMetadataSuccess,
    props<{
        canSaveTemplate: boolean;
        canEditBillingRate: boolean;
        canViewBillingRate: boolean;
        isScheduledJobReleaseEnabled: boolean;
        expertises: Array<ExpertiseLookup>;
        units: Array<UnitLookup>;
        scheduledReleaseRules: Array<ScheduledReleaseRule>;
        facilityAddressInfo: FacilityAddressInfo;
    }>()
);

export const loadFacilityMetadataFail = createAction(JobsActionEnum.LoadFacilityMetadataFail, props<{ error: any }>());

export const metadataDisable = createAction(
    JobsActionEnum.MetadataDisable,
    props<{ disabled: boolean; message: string }>()
);

export const clearMetadata = createAction(JobsActionEnum.ClearMetadata);

export const clearMetadataNoGroupId = createAction(JobsActionEnum.ClearMetadataNoGroupId);

// Approve/Reject/Release

export const approveJobs = createAction(JobsActionEnum.ApproveJobs, props<{ ids: Array<number> }>());

export const approveJobsSuccess = createAction(JobsActionEnum.ApproveJobsSuccess);

export const approveJobsFail = createAction(JobsActionEnum.ApproveJobsFail);

export const approveJobsAndReloadGrid = createAction(
    JobsActionEnum.ApproveJobsAndReloadGrid,
    props<{ ids: Array<number> }>()
);

export const loadJobBillingRate = createAction(
    JobsActionEnum.LoadJobBillingRate,
    props<{
        facilityId: number;
        professionId: number;
        expertiseId: number;
        shiftH?: number;
        hours?: number;
    }>()
);

export const loadJobBillingRateSuccess = createAction(
    JobsActionEnum.LoadJobBillingRateSuccess,
    props<{
        jobBillingRate: number;
    }>()
);

export const loadJobBillingRateFail = createAction(JobsActionEnum.LoadJobsBillingRateFail, props<{ error: any }>());

export const clearJobBillingRate = createAction(JobsActionEnum.ClearJobBillingRate);

export const rejectJobs = createAction(JobsActionEnum.RejectJobs, props<{ comment: string; ids: Array<number> }>());

export const rejectJobsSuccess = createAction(JobsActionEnum.RejectJobsSuccess);

export const rejectJobsFail = createAction(JobsActionEnum.RejectJobsFail);

export const rejectJobsAndReloadGrid = createAction(
    JobsActionEnum.RejectJobsAndReloadGrid,
    props<{ comment: string; ids: Array<number> }>()
);

// Job Details

export const loadJobDetails = createAction(JobsActionEnum.LoadJobDetails, props<{ jobId: number }>());

export const loadJobDetailsSuccess = createAction(JobsActionEnum.LoadJobDetailsSuccess, props<{ job: Job }>());

export const loadJobDetailsFail = createAction(JobsActionEnum.LoadJobDetailsFail, props<{ error: any }>());

export const clearJobDetails = createAction(JobsActionEnum.ClearJobDetails);

// Job Save
export const saveJob = createAction(JobsActionEnum.SaveJob, props<{ job: Job }>());

export const saveJobSuccess = createAction(JobsActionEnum.SaveJobSuccess, props<{ jobId: number }>());

export const saveJobFail = createAction(JobsActionEnum.SaveJobFail, props<{ error: any }>());

export const saveDuplicateJobSuccess = createAction(
    JobsActionEnum.SaveDuplicateJobSuccess,
    props<{ jobId: number; attachmentResult: DuplicateJobAttachmentResult }>()
);

// Job Template

export const loadJobTemplate = createAction(JobsActionEnum.LoadJobTemplate, props<{ templateId: number }>());

export const loadJobTemplateSuccess = createAction(JobsActionEnum.LoadJobTemplateSuccess, props<{ template: Job }>());

export const loadJobTemplateFail = createAction(JobsActionEnum.LoadJobTemplateFail, props<{ error: any }>());

// Job Positions

export const setJobPositions = createAction(
    JobsActionEnum.SetJobPositions,
    props<{ jobId: number; positionsCount: number }>()
);

export const deleteJob = createAction(JobsActionEnum.DeleteJob, props<{ jobId: number }>());

// Submittals

export const loadJobSubmittals = createAction(JobsActionEnum.LoadJobSubmittals, props<{ jobId: number }>());

export const loadJobSubmittalsSuccess = createAction(
    JobsActionEnum.LoadJobSubmittalsSuccess,
    props<{ submittals: Submittal[] }>()
);

// Filled Positions

export const loadJobFilledPositions = createAction(JobsActionEnum.LoadJobFilledPositions, props<{ jobId: number }>());

export const loadJobFilledPositionsSuccess = createAction(
    JobsActionEnum.LoadJobFilledPositionsSuccess,
    props<{ filledPositions: FilledPosition[] }>()
);

// Misc

export const showFailMessage = createAction(JobsActionEnum.ShowFailMessage, props<{ error: string }>());

export const nullAction = createAction(JobsActionEnum.NullAction);

// export const loadJobHistory = createAction(
// 	JobsActionEnum.LoadJobHistory
// );

// export const loadJobHistorySuccess = createAction(
// 	JobsActionEnum.LoadJobHistorySuccess, props<{history: Array<JobHistory>}>()
// );

// export const loadJobHistoryFail = createAction(
// 	JobsActionEnum.LoadJobHistoryFail, props<{error: any}>()
// );

// export const editJobMode = createAction(
// 	JobsActionEnum.EditJobMode, props<{enable: boolean}>()
// );

export const loadJobTemplates = createAction(JobsActionEnum.LoadJobTemplates);

export const loadJobTemplatesSuccess = createAction(
    JobsActionEnum.LoadJobTemplatesSuccess,
    props<{
        jobTemplates: Array<JobTemplateListItem>;
        total: number;
    }>()
);

export const loadJobTemplatesFail = createAction(JobsActionEnum.LoadJobsTemplateFail, props<{ error: any }>());

export const loadJobTemplateDetails = createAction(JobsActionEnum.LoadJobTemplateDetails, props<{ id: number }>());

export const loadJobTemplateDetailsSuccess = createAction(
    JobsActionEnum.LoadJobTemplateDetailsSuccess,
    props<{ jobTemplateDetails: JobTemplateExpanded }>()
);

export const loadJobTemplateDetailsFail = createAction(
    JobsActionEnum.LoadJobTemplateDetailsFail,
    props<{ error: any }>()
);

export const saveJobTemplate = createAction(
    JobsActionEnum.SaveJobTemplate,
    props<{ jobTemplate: JobTemplateSaveModel }>()
);

export const saveJobTemplateSuccess = createAction(JobsActionEnum.SaveJobTemplateSuccess);

export const saveJobTemplateFail = createAction(JobsActionEnum.SaveJobTemplateFail, props<{ error: any }>());

export const deleteJobTemplate = createAction(JobsActionEnum.DeleteJobTemplate, props<{ id: number }>());

export const deleteJobTemplateSuccess = createAction(JobsActionEnum.DeleteJobTemplateSuccess);

export const deleteJobTemplateFail = createAction(JobsActionEnum.DeleteJobTemplateFail, props<{ error: any }>());

export const setJobTemplatesSearchQuery = createAction(
    JobsActionEnum.SetJobTemplatesSearchQuery,
    props<{ query: GridSearchQuery }>()
);

export const clearJobTemplateDetails = createAction(JobsActionEnum.ClearJobTemplateDetails);

export const loadJobExtentionsSuccess = createAction(
    JobsActionEnum.LoadJobExtentionsSuccess,
    props<{ extentions: Partial<JobExtentionsState> }>()
);

export const clearJobExtentions = createAction(JobsActionEnum.ClearJobExtentions);

// job Template Lookups

export const loadJobTemplatesLookups = createAction(JobsActionEnum.LoadJobTemplatesLookups);

export const loadJobTemplatesLookupsSuccess = createAction(
    JobsActionEnum.LoadJobTemplatesLookupsSuccess,
    props<{
        facilities: Array<FacilityLookup>;
        units: Array<UnitLookup>;
    }>()
);

export const loadJobTemplatesLookupsFail = createAction(
    JobsActionEnum.LoadJobTemplatesLookupsFail,
    props<{ error: any }>()
);

export const updateMspFieldsVisibility = createAction(
    JobsActionEnum.UpdateMspFieldsVisibility,
    props<{ showBjcFields: boolean }>()
);

/* attachments */
export const deleteJobAttachment = createAction(
    '[JOBS DELETE ATTACHMENT] Delete Job Attachments',
    props<{ jobId: number; id: number }>()
);
export const deleteJobAttachmentSuccess = createAction(
    '[JOBS DELETE ATTACHMENT] Delete Job Attachments success',
    props<{ shiftId: number; id: number }>()
);
export const deleteJobAttachmentFail = createAction(
    '[JOBS DELETE ATTACHMENT] Delete Job Attachments fail',
    props<{ error: any }>()
);

export const saveJobAttachment = createAction(
    '[JOBS SAVE ATTACHMENT] Save Job Attachments',
    props<{ jobId: number; fileToUpload: any; internalOnly?: boolean }>()
);
export const saveJobAttachmentSuccess = createAction(
    '[JOBS SAVE ATTACHMENT] Save Job Attachments success',
    props<{ fileValidationResult: FileValidationResult[]; jobId: number }>()
);
export const saveJobAttachmentFail = createAction(
    '[JOBS SAVE ATTACHMENT] Save Job Attachments fail',
    props<{ fileValidationResult: FileValidationResult; error: any }>()
);

export const loadJobAttachments = createAction(
    '[JOBS LOAD ATTACHMENT] Load Job Attachments',
    props<{ jobId: number }>()
);
export const loadJobAttachmentsSuccess = createAction(
    '[JOBS LOAD ATTACHMENT] Load Job Attachments success',
    props<{ attachments: FileAttachment[] }>()
);
export const loadJobAttachmentsFail = createAction(
    '[JOBS LOAD ATTACHMENT] Load Job Attachments fail',
    props<{ error: any }>()
);

export const loadJobAttachment = createAction(
    '[JOBS LOAD ATTACHMENT] Load Job Attachment',
    props<{ jobId: number; id: number; fileName?: string }>()
);
export const loadJobAttachmentSuccess = createAction('[JOBS LOAD ATTACHMENT] Load Job Attachment success');
export const loadJobAttachmentFail = createAction(
    '[JOBS LOAD ATTACHMENT] Load Job Attachment fail',
    props<{ error: any }>()
);

export const updateJobAttachment = createAction(
    '[JOBS UPDATE ATTACHMENT] Update Job Attachments',
    props<{ jobId: number; fileToUpdate: FileAttachment; internalOnly: boolean }>()
);
export const updateJobAttachmentSuccess = createAction(
    '[JOBS UPDATE ATTACHMENT] Update Job Attachments success',
    props<{ jobId: number }>()
);
export const updateJobAttachmentFail = createAction(
    '[JOBS UPDATE ATTACHMENT] Update Job Attachments fail',
    props<{ error: any }>()
);

export const loadPreviewJobAttachments = createAction(
    '[JOBS PREVIEW LOAD ATTACHMENT] Load Preview Job Attachments',
    props<{ jobId: number; id: number; pageNum: number; file: FileAttachment }>()
);
export const loadPreviewJobAttachmentsSuccess = createAction(
    '[JOBS PREVIEW LOAD ATTACHMENT API] Load Preview Job Attachments success',
    props<{ jobPreviewAttachments: Blob }>()
);
export const loadPreviewJobAttachmentsFail = createAction(
    '[JOBS PREVIEW LOAD ATTACHMENT API] Load Preview Job Attachments fail',
    props<{ error: any }>()
);

export const loadPreviewNextPage = createAction(
    '[JOB ATTACHMENTS COMPONENT] Load Preview Next Page',
    props<{ pageNum: number }>()
);

export const loadPreviewJobPageCount = createAction(
    '[JOBS PREVIEW LOAD PAGE COUNT ATTACHMENT] Load Preview Page Count Job Attachments',
    props<{ jobId: number; id: number }>()
);
export const loadPreviewJobPageCountSuccess = createAction(
    '[JOBS PREVIEW LOAD PAGE COUNT ATTACHMENT API] Load Preview Page Count Job Attachments Success',
    props<{ total: number }>()
);
export const loadPreviewJobPageCountFail = createAction(
    '[JOBS PREVIEW LOAD ATTACHMENT API] Load Preview Page Count Job Attachments fail',
    props<{ error: any }>()
);

export const addJobWithAttachments = createAction(
    '[JOBS ATTACHMENTS] Add Job with Attachments',
    props<{ job: Job; attachments: FileAttachment[]; internalOnly?: boolean }>()
);
export const loadJobDetailsWithJobAttachments = createAction(
    '[JOBS LOAD JOB DETAILS WITH ATTACHMENTS] Load Job Details with Attachments',
    props<{ jobId: number }>()
);
export const loadJobDetailsInfo = createAction(
    '[JOBS LOAD DETAILS PAGE] Load Job Details Page',
    props<{ jobId: number }>()
);

export const clearJobAttachments = createAction('[JOBS CLEAR ATTACHMENT] Clear Job Attachments');

export const loadJobLookupsWithSystemId = createAction('[JOBS] Load Jobs Lookups with system features');

export const clearJobAttachmentPreview = createAction('[JOB ATTACHMENT COMPONENT] Clear Shift Attachment Preview');

export const loadJobAttachmentFeatures = createAction(
    '[JOBS] Load Jobs Attachment Features',
    props<{ facilityProfileGroupId: number }>()
);

export const loadJobAttachmentFeaturesSuccess = createAction(
    '[JOBS] Load Jobs Facility Attachment Features success',
    props<{ attachmentFeatures: AttachmentFeature }>()
);

export const loadJobAttachmentFeaturesFail = createAction(
    '[JOBS] Load Jobs Facility Attachment Features fail',
    props<{ error: any }>()
);

export const loadOriginalJob = createAction('[JOBS DETAIL COMPONENT] Load Job Traveler', props<{ jobId: number }>());
export const loadOriginalJobSuccess = createAction(
    '[JOBS DETAIL SERVICE] Load Original Job Success',
    props<{ originalJob: OriginalJob }>()
);
export const loadOriginalJobFail = createAction(
    '[JOBS DETAIL SERVICE] Load Original Job Fail',
    props<{ error: any }>()
);

export const exportJobs = createAction(JobsActionEnum.ExportJobs);

export const exportJobsSuccess = createAction(
    JobsActionEnum.ExportJobsSuccess,
    props<{ blob: Blob; filename: string }>()
);

export const exportJobsFail = createAction(JobsActionEnum.ExportJobsFail, props<{ error: any }>());

export const loadLineOfBusinessProfessions = createAction(
    '[EDIT JOBS] Load Line of Business Professions',
    props<{ lineOfBusiness: LineOfBusinessEnum }>()
);

export const loadLineOfBusinessProfessionsSuccess = createAction(
    '[EDIT JOBS] Load Line of Business Professions Success',
    props<{ professions: Array<ProfessionLookup> }>()
);

export const loadLineOfBusinessProfessionsFail = createAction(
    '[EDIT JOBS] Load Line of Business Professions Fail',
    props<{ error: string }>()
);

export const addDuplicateJobWithAttachments = createAction(
    '[DUPLICATE JOBS ATTACHMENTS] Add Duplicate Job With Attachments',
    props<{ job: Job; attachments: FileAttachment[]; existingAttachments: FileAttachment[] }>()
);

export const loadCoverageTypes = createAction('[EDIT JOBS] Load Coverate Types');

export const loadCoverageTypesSuccess = createAction(
    '[EDIT JOBS] Load Coverate Types Success',
    props<{ coverageTypes: Array<CoverageTypeLookup> }>()
);

export const loadCoverageTypesFail = createAction('[EDIT JOBS] Load Coverate Types Fail', props<{ error: string }>());

export const getSelectedProfessionName = createAction(
    '[EDIT JOBS] Get Profession Name',
    props<{ professionId: number; professionName?: string }>()
);
export const getSelectedSpecialtyName = createAction(
    '[EDIT JOBS] Get Specialty Name',
    props<{ specialtyId: number; specialtyName?: string }>()
);

export const loadSubmittalStatuses = createAction(JobsActionEnum.LoadSubmittalStatuses);

export const loadSubmittalStatusesV2 = createAction(
    JobsActionEnum.LoadSubmittalStatusesV2,
    props<{ canViewIRPSubmittalDetails?: boolean }>()
);

export const loadSubmittalStatusesSuccess = createAction(
    JobsActionEnum.LoadSubmittalStatusesSuccess,
    props<{ statuses: SubmittalStatusLookup[] }>()
);

export const loadIrpSubmittalStatusesSuccess = createAction(
    JobsActionEnum.IrpLookupsStatusesSuccess,
    props<{ irpStatuses: SubmittalStatusLookup[] }>()
);

export const loadSubmittalStatusesFail = createAction(
    JobsActionEnum.LoadSubmittalStatusesFail,
    props<{ error: unknown }>()
);

export const releaseAllJobsPositions = createAction(
    '[CLIENT JOBS RELEASE DIALOG COMPONENT] Release Multiple Positions Under Multiple Jobs',
    props<{ jobIds: number[]; vendorIds: number[] }>()
);

export const releaseAllJobsPositionsSuccess = createAction(
    '[CLIENT JOBS RELEASE API] Release Multiple Positions Under Multiple Jobs Success'
);

export const releaseAllJobsPositionsFailure = createAction(
    '[CLIENT JOBS RELEASE API] Release Multiple Positions Under Multiple Jobs Failure',
    props<{ error: HttpErrorResponse }>()
);

export const releaseAllJobPositions = createAction(
    '[CLIENT JOBS RELEASE DIALOG COMPONENT] Release Multiple Positions Under One Job',
    props<{ jobId: number; vendorIds: number[] }>()
);

export const releaseAllJobPositionsSuccess = createAction(
    '[CLIENT JOBS RELEASE API] Release Multiple Positions Under One Job Success',
    props<{ jobId: number }>()
);

export const releaseAllJobPositionsFailure = createAction(
    '[CLIENT JOBS RELEASE API] Release Multiple Positions Under One Job Failure',
    props<{ error: HttpErrorResponse }>()
);

export const releaseJobPosition = createAction(
    '[CLIENT JOBS RELEASE DIALOG COMPONENT] Release One Position',
    props<{ jobId: number; jobPositionId: number; vendorIds: number[] }>()
);

export const releaseJobPositionSuccess = createAction(
    '[CLIENT JOBS RELEASE API] Release One Position Success',
    props<{ jobId: number }>()
);

export const releaseJobPositionFailure = createAction(
    '[CLIENT JOBS RELEASE API] Release One Position Failure',
    props<{ error: HttpErrorResponse }>()
);
