import { createAction, props } from '@ngrx/store';
import { ScheduleTypes } from '../../shift-resource-fulfillment-scheduler/shift-resource-fulfillment-scheduler.component';
import { FacilityBonus, ShiftBonusManagementItem } from 'src/app/shift-bonus-management/models';

export const setShiftVisibility = createAction('[SHIFTS] Set Shift Visibility', props<{ isInternal: boolean }>());
export const setScheduleType = createAction('[SHIFTS] Set Schedule Type', props<{ scheduleType: ScheduleTypes }>());
export const setBonusRate = createAction('[SHIFTS] Set Bonus Rate', props<{ bonusRateId?: number }>());

export const loadShiftBonusManagementDetails = createAction(
    '[SHIFTS] Load Shift Bonus Management Details',
    props<{ facilityId: number }>()
);
export const loadShiftBonusManagementDetailsFail = createAction(
    '[SHIFTS] Load Shift Bonus Management Details Fail',
    props<{ error: any }>()
);
export const loadShiftBonusManagementDetailsSuccess = createAction(
    '[SHIFTS] Load Shift Bonus Management Details Success',
    props<{ bonusManagementDetails: ShiftBonusManagementItem }>()
);

export const clearShiftBonusManagementDetails = createAction(
    '[SHIFTS] Clear Shift Bonus Management Details'
);
