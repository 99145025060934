import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ListItem } from 'src/app/shared/models/list-item';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { SubmittalVendorListItem } from 'src/app/shared/models/submittals-vendor/submittal-vendor-list-item.model';

export const componentLoaded = createAction('[VENDOR SUBMITTALS] Component Loaded');

export const loadSubmittals = createAction('[VENDOR SUBMITTALS LOAD SUBMITTALS] Load Vendor Submittals');

export const loadSubmittalsSuccess = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTALS SUCCESS] Vendor Submittals Load Success',
    props<{ submittals: PagingToken<SubmittalVendorListItem[]> }>()
);

export const loadSubmittalsFail = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTALS FAILURE] Vendor Submittals Load Failure',
    props<{ error: any }>()
);

export const setSearchQuery = createAction(
    '[VENDOR SUBMITTALS SET SEARCH QUERY] Set Submittals Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);

export const loadVendorContacts = createAction('[VENDOR SUBMITTALS LOAD VENDOR CONTACTS] Load Vendor Contacts');

export const loadVendorContactsSuccess = createAction(
    '[VENDOR SUBMITTALS LOAD CONTACTS SUCCESS] Vendor Contacts Load Success',
    props<{ agents: ListItem[] }>()
);

export const loadVendorContactsFail = createAction(
    '[VENDOR SUBMITTALS LOAD CONTACTS FAILURE] Vendor Contacts Load Failure',
    props<{ error: any }>()
);

export const clearVendorSubmittalsFilters = createAction(
    '[VENDOR SUBMITTALS CLEAR FILTERS] Clear Vendor Submittals Filters'
);

export const exportAllSubmittals = createAction(
    '[VENDOR SUBMITTALS EXPORT ALL] Export All Submittals',
    props<{ submittalIds: number[] }>()
);

export const exportAllSubmittalsSuccess = createAction(
    '[VENDOR SUBMITTALS EXPORT ALL SUCCESS] Export All Submittals Successful'
);

export const exportAllSubmittalsFail = createAction(
    '[VENDOR SUBMITTALS EXPORT ALL FAILED] Export All Submittals Failed',
    props<{ error: any }>()
);

export const exportSelectedSubmittals = createAction(
    '[VENDOR SUBMITTALS EXPORT SELECTED] Export Selected Submittals',
    props<{ submittalIds: number[] }>()
);

export const exportSelectedSubmittalsSuccess = createAction(
    '[VENDOR SUBMITTALS EXPORT SELECTED SUCCESS] Export Selected Submittals Succesful'
);

export const exportSelectedSubmittalsFail = createAction(
    '[VENDOR SUBMITTALS EXPORT SELECTED FAILED] Export Selected Submittals Failed',
    props<{ error: any }>()
);
