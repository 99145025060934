import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToasterService, ToasterStatus } from 'src/app/core/services';
import { FileService } from '../../services/file.service';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as dayjs from 'dayjs';
import { DocumentAttributeFieldOption } from '../models/document-attribute-field-option';
import { DocumentAttributeFieldTypes } from '../enums/document-attribute-field-types';
import { DocumentAttributeField } from '../models/document-attribute-field';

@Injectable()
export class VendorCandidateRequirementDocumentUploadEffects {
    uploadRequirementFile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.uploadRequirementFilePreview),
            exhaustMap((action) =>
                this._fileService
                    .uploadRequirementFile(
                        action.candidateOldUserId,
                        action.contractId,
                        action.docTypeId,
                        action.fileToUpload
                    )
                    .pipe(
                        map((res) => {
                            return res
                                ? actions.uploadRequirementFilePreviewSuccess({
                                      candidateDocumentId: res.candidateDocumentId
                                  })
                                : actions.uploadRequirementFilePreviewFailure({
                                      error: null,
                                      fileName: action.fileToUpload.name
                                  });
                        }),
                        catchError((error: unknown) =>
                            of(
                                actions.uploadRequirementFilePreviewFailure({
                                    error,
                                    fileName: action.fileToUpload.name
                                })
                            )
                        )
                    )
            )
        );
    });

    uploadRequirementFilePreviewSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.uploadRequirementFilePreviewSuccess),
            map((action) => {
                return actions.getDocumentUploaded({ isEdit: false });
            })
        );
    });

    uploadRequirementFilePreviewFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.uploadRequirementFilePreviewFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File could not be uploaded. File ' +
                            action.fileName +
                            ' does not meet the file upload requirements.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'center', verticalPosition: 'top' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    getDocumentUploaded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getDocumentUploaded),
            concatLatestFrom(() => [this.store.select(selectors.selectCandidateDocumentId)]),
            exhaustMap(([actionInput, candidateDocumentId]) => {
                return this._fileService.getDocumentUploadedFile(candidateDocumentId).pipe(
                    map((res) => {
                        return res
                            ? actions.getDocumentUploadedSuccess({
                                  file: res
                              })
                            : actions.getDocumentUploadedFailure({ error: res });
                    }),
                    catchError((error: unknown) => {
                        return of(actions.getDocumentUploadedFailure({ error }));
                    })
                );
            })
        );
    });

    getDocumentUploadedSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getDocumentUploadedSuccess),
            concatLatestFrom(() => [this.store.select(selectors.selectCandidateDocumentId)]),
            map(([actionInput, candidateDocumentId]) => {
                return actions.getDocumentDetails({ candidateDocumentId });
            })
        );
    });

    getDocumentUploadedFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getDocumentUploadedFailure),
                concatLatestFrom(() => [this.store.select(selectors.selectIsEdit)]),
                tap(([action, isEdit]) =>
                    this._toasterService.openCustomToastWithDismiss(
                        isEdit
                            ? 'File could not be downloaded for preview for edit. Please try again.'
                            : 'File could not be downloaded for preview after upload success. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'center', verticalPosition: 'top' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    getDocumentDetails$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getDocumentDetails),
            exhaustMap((action) => {
                return this._fileService.getComplianceCandidateDocumentDetails(action.candidateDocumentId).pipe(
                    map((res) => {
                        return res
                            ? actions.getUploadedDocumentDetailsSuccess({
                                  fileName: res.fileName,
                                  uploadedBy: res.postedByUser,
                                  uploadedDate: res.postedDate
                              })
                            : actions.getUploadedDocumentDetailsFailure({ error: res });
                    }),
                    catchError((error: unknown) => {
                        return of(actions.getUploadedDocumentDetailsFailure({ error }));
                    })
                );
            })
        );
    });

    getUploadedDocumentDetailsFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getUploadedDocumentDetailsFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File upload details could not be loaded. Please try again or contact support.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'center', verticalPosition: 'top' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    getUploadedDocumentDetailsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getUploadedDocumentDetailsSuccess),
            concatLatestFrom(() => [this.store.select(selectors.selectCandidateDocumentPreviewFile)]),
            map(([actionInput, candidateDocumentPreviewFile]) => {
                return actions.getMetadataForRequirementFile({ file: candidateDocumentPreviewFile });
            })
        );
    });

    getMetadataForRequirementFile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getMetadataForRequirementFile),
            concatLatestFrom(() => [
                this.store.select(selectors.selectCandidateDocumentId),
                this.store.select(selectors.selectDocumentUploadDocTypeId)
            ]),
            exhaustMap(([actionInput, candidateDocumentId, docTypeId]) =>
                this._fileService.getMetadataForRequirementFile(candidateDocumentId, docTypeId).pipe(
                    map((res) => {
                        if (res) {
                            return actions.getMetadataForRequirementFileSuccess({
                                documentRequirementAttributeFields: res,
                                documentAttributes: res.map((f) => ({
                                    fieldId: f.fieldId,
                                    fieldName: f.fieldName,
                                    fieldType: f.fieldType,
                                    selectedOption: f.selectedOption,
                                    value: f.fieldValue
                                }))
                            });
                        } else {
                            return actions.getMetadataForRequirementFileFailure({
                                error: res
                            });
                        }
                    }),
                    catchError((error: unknown) =>
                        of(
                            actions.getMetadataForRequirementFileFailure({
                                error
                            })
                        )
                    )
                )
            )
        );
    });

    getMetadataForRequirementFileFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getMetadataForRequirementFileFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File metadata could not be downloaded. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'center', verticalPosition: 'top' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    getMetadataForRequirementFileSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getMetadataForRequirementFileSuccess),
                concatLatestFrom(() => [this.store.select(selectors.selectIsEdit)]),
                tap(([action, isEdit]) =>
                    this._toasterService.openCustomToastWithDismiss(
                        isEdit ? 'File loaded successfully.' : 'File attached successfully.',
                        action,
                        ToasterStatus.success,
                        { horizontalPosition: 'center', verticalPosition: 'top' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    setDocumentAttributeValue$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.setDocumentAttributeValue),
            concatLatestFrom(() => [
                this.store.select(selectors.selectDocumentUploadMetadataAttributes),
                this.store.select(selectors.selectDocumentUploadDocTypeId),
                this.store.select(selectors.selectDocumentReviewerExpirationDate),
                this.store.select(selectors.selectDocumentUploadMetadataFields)
            ]),
            map(([actionInput, metadataAttributes, docTypeId, docReviewerExpirationDate, metadataFields]) => {
                const idx = metadataAttributes.findIndex((da) => da.fieldName === actionInput.fieldName);
                if (idx === -1) {
                    return actions.setDocumentAttributeValueEnd();
                }

                const documentAttribute = metadataAttributes[idx];
                let selectedOption: DocumentAttributeFieldOption = null;
                let value: string = null;

                if (actionInput.fieldValue !== null) {
                    switch (documentAttribute.fieldType) {
                        case DocumentAttributeFieldTypes.Singleselect:
                        case DocumentAttributeFieldTypes.Multiselect:
                            selectedOption = actionInput.fieldValue;
                            break;
                        case DocumentAttributeFieldTypes.Date:
                        case DocumentAttributeFieldTypes.DateCompare:
                            value = this.formatDate(actionInput.fieldValue);
                            break;
                        default:
                            value = actionInput.fieldValue;
                            break;
                    }
                }

                if (
                    documentAttribute.selectedOption?.fieldOptionId === selectedOption?.fieldOptionId &&
                    documentAttribute.value === value
                ) {
                    return actions.setDocumentAttributeValueEnd();
                }

                if (documentAttribute.fieldName === 'expirationDate') {
                    this.setExpirationDate(metadataFields, value);
                }

                const documentAttributes = [
                    ...metadataAttributes.slice(0, idx),
                    {
                        ...documentAttribute,
                        selectedOption,
                        value
                    },
                    ...metadataAttributes.slice(idx + 1)
                ];

                return actions.setDocumentAttributeValueSuccess({
                    documentAttributes,
                    docReviewerExpirationDate,
                    documentRequirementAttributeFields: metadataFields
                });

                //* REMOVE FOR NOW - WE MAY NEED THIS LATER IF WE DECIDE TO ADD DYNAMIC META DATA FIELDS IN VENDOR CONNECT COMPLIANCE. CNW-16791 - CNW-16482
                // return actions.getFilteredMetadataFieldsForRequirementFile({
                //     documentTypeId: action[2].toString(),
                //     documentAttributes,
                //     fieldName: action[0].fieldName,
                //     docReviewerExpirationDate: action[3]
                // });
            })
        );
    });

    setDocumentAttributeValueSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.setDocumentAttributeValueSuccess),
            map((action) => {
                return actions.getFilteredMetadataForRequirementFileSuccess({
                    documentRequirementAttributeFields: action.documentRequirementAttributeFields,
                    documentAttributes: action.documentAttributes
                });
            })
        );
    });

    //* REMOVE FOR NOW - WE MAY NEED THIS LATER IF WE DECIDE TO ADD DYNAMIC META DATA FIELDS IN VENDOR CONNECT COMPLIANCE. CNW-16791 - CNW-16956
    // filterDocumentAttributeFields$ = createEffect(() => {
    //     return this._actions$.pipe(
    //         ofType(actions.getFilteredMetadataFieldsForRequirementFile),
    //         concatLatestFrom(() => [
    //             this.store.select(selectors.selectDocumentUploadMetadataFields),
    //             this.store.select(selectors.selectDocumentUploadDocTypeId),
    //             this.store.select(selectors.selectDocumentReviewerExpirationDate)
    //         ]),
    //         //* For testing purposes only
    //         // map(([actionParams, metadataFields]) => {
    //         //     return actions.getFilteredMetadataForRequirementFileSuccess({
    //         //         documentRequirementAttributeFields: metadataFields,
    //         //         documentAttributes: actionParams.documentAttributes
    //         //     });
    //         // })
    //         switchMap(([actionParams, metadataFields, docTypeId, docReviewerExpirationDate]) => {
    //             return this._fileService
    //                 .filterDocumentAttributeFields(docTypeId.toString(), actionParams.documentAttributes)
    //                 .pipe(
    //                     map((res) => {
    //                         this.setExpirationDate(res, docReviewerExpirationDate);
    //                         if (res) {
    //                             const docAttributes = res.map((f) => ({
    //                                 fieldId: f.fieldId,
    //                                 fieldName: f.fieldName,
    //                                 fieldType: f.fieldType,
    //                                 selectedOption: f.selectedOption,
    //                                 value: f.fieldValue
    //                             }));
    //                             return actions.getFilteredMetadataForRequirementFileSuccess({
    //                                 documentRequirementAttributeFields: res,
    //                                 documentAttributes: docAttributes
    //                             });
    //                         } else {
    //                             return actions.getFilteredMetadataForRequirementFileFailure({
    //                                 error: res
    //                             });
    //                         }
    //                     }),
    //                     catchError((error: unknown) =>
    //                         of(
    //                             actions.getFilteredMetadataForRequirementFileFailure({
    //                                 error
    //                             })
    //                         )
    //                     )
    //                 );
    //         })
    //     );
    // });

    saveDocumentMetadata$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.saveDocumentMetadata),
            concatLatestFrom(() => [
                this.store.select(selectors.selectDocumentUploadMetadataAttributes),
                this.store.select(selectors.selectCandidateDocumentId),
                this.store.select(selectors.selectDocumentUploadDocTypeId),
                this.store.select(selectors.selectDocumentUploadContractId),
                this.store.select(selectors.selectIsEdit)
            ]),
            switchMap(
                ([
                    actionInput,
                    metadataAttributes,
                    candidateDocumentId,
                    documentUploadTypeId,
                    documentUploadContractId,
                    isEdit
                ]) => {
                    return this._fileService
                        .saveDocumentMetadata(
                            actionInput.documentMetadataInputForSave,
                            metadataAttributes,
                            candidateDocumentId,
                            documentUploadTypeId,
                            documentUploadContractId,
                            isEdit
                        )
                        .pipe(
                            map((response) => {
                                return actions.saveDocumentMetadataSuccess();
                            }),
                            catchError((error: unknown) => {
                                return of(
                                    actions.saveDocumentMetadataFailure({
                                        error
                                    })
                                );
                            })
                        );
                }
            )
        );
    });

    saveDocumentMetadataSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveDocumentMetadataSuccess),
                concatLatestFrom(() => [this.store.select(selectors.selectIsEdit)]),
                tap(([actionParams, isEdit]) =>
                    isEdit
                        ? this._toasterService.openCustomToastWithDismiss(
                              'Document has been successfully edited.',
                              actionParams,
                              ToasterStatus.success,
                              {
                                  horizontalPosition: 'center',
                                  verticalPosition: 'top'
                              }
                          )
                        : this._toasterService.openCustomToastWithDismiss(
                              'File attached successfully.',
                              actionParams,
                              ToasterStatus.success,
                              {
                                  horizontalPosition: 'center',
                                  verticalPosition: 'top'
                              }
                          )
                )
            );
        },
        { dispatch: false }
    );

    saveDocumentMetadataFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveDocumentMetadataFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File metadata could not be saved. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'center', verticalPosition: 'top' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    deleteDocumentCalled$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.deleteDocumentCalled),
            concatLatestFrom(() => [
                this.store.select(selectors.selectCandidateDocumentId),
                this.store.select(selectors.selectDeleteDocumentCalledWithNavigation)
            ]),
            switchMap(([actionInput, candidateDocumentId, deleteDocumentCalledWithNavigation]) => {
                return this._fileService.deleteDocument(candidateDocumentId).pipe(
                    map((res) => {
                        if (res.ok) {
                            return actions.deleteDocumentSuccess({
                                deleteDocumentCalledWithNavigation
                            });
                        } else {
                            return actions.deleteDocumentFailure({
                                error: res
                            });
                        }
                    }),
                    catchError((error: unknown) => {
                        return of(
                            actions.deleteDocumentFailure({
                                error
                            })
                        );
                    })
                );
            })
        );
    });

    deleteDocumentSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.deleteDocumentSuccess),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss('File deleted.', action, ToasterStatus.success, {
                        horizontalPosition: 'center',
                        verticalPosition: 'top'
                    })
                )
            );
        },
        { dispatch: false }
    );

    deleteDocumentFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.deleteDocumentFailure),
                tap((action) =>
                    this._toasterService.openCustomToastWithDismiss(
                        'File could not be deleted. Please try again.',
                        action,
                        ToasterStatus.fail,
                        { horizontalPosition: 'center', verticalPosition: 'top' }
                    )
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly store: Store,
        private readonly _fileService: FileService,
        private readonly _toasterService: ToasterService
    ) {}

    private readonly formatDate = (value: any) => (value ? dayjs(value).format('YYYY-MM-DD') : null);

    private readonly setExpirationDate = (fields: DocumentAttributeField[], docReviewerExpirationDate: string) => {
        const expirationDateField = fields.find((f) => f.fieldName === 'expirationDate');
        if (expirationDateField) {
            expirationDateField.fieldValue = expirationDateField.fieldValue ?? docReviewerExpirationDate;
        }
    };
}
