import { Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Dayjs } from 'dayjs';
import { DateHelper } from '../../utilities';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

@Component({
    selector: 'ayac-datepicker-multiple-dayjs',
    templateUrl: './datepicker-multiple-dayjs.component.html',
    styleUrls: ['./datepicker-multiple-dayjs.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerMultipleDayjsComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatepickerMultipleDayjsComponent),
            multi: true
        }
    ]
})
export class DatepickerMultipleDayjsComponent extends UnsubscribeOnDestroy implements OnInit, ControlValueAccessor {
    @Input() minDate: Dayjs;
    @Input() maxDate: Dayjs;
    @Input() maxMultiSelectedDates = 7;
    @Input() title = 'Select Dates';
    @Input() disabledDates: Dayjs[];
    @Input() disabledDatesStyle: 'none' | 'fill' = 'none';
    @Input() extraDateClass: (date: Dayjs) => string;
    @Input() multi = true;
    @Input() showConfirmationCount = false;
    @Input() panelClass: string;
    @Input() validateField = false;
    @Input() requiredErrorMessage = 'Please select at least one date.';
    @Input() maxLengthErrorMessage = `You can only select up to ${this.maxMultiSelectedDates} days`;
    @Input() maxLengthHintMessage = `Select between 1 and ${this.maxMultiSelectedDates} days`;
    @Input() useMaterialIcon = false;
    @Input() showRequiredAsterisk = false;
    @Input() validators: Array<(control: AbstractControl) => ValidationErrors | null> = [];

    @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

    changed: any;
    touched: any;
    selectedCalendarDates: Dayjs[];
    datesControl: FormControl<Dayjs[] | string>;

    constructor(@Inject(MAT_DATE_FORMATS) private readonly dateFormats: MatDateFormats) {
        super();
    }

    ngOnInit(): void {
        this.datesControl = new FormControl<Dayjs[] | string>([], this.validators);
    }

    writeValue(dates: any): void {
        this.selectedCalendarDates = dates;
        this.setViewAndValidateDates(this.datesControl, dates, this.validateField);
        this.datesControl.markAsUntouched();
    }

    registerOnChange(fn: any): void {
        this.changed = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.datesControl.disable();
        } else {
            this.datesControl.enable();
        }
    }

    validate(_control: AbstractControl): ValidationErrors | null {
        return this.datesControl.errors;
    }

    onSave(dates: Dayjs[]) {
        this.trigger.closeMenu();

        this.datesControl.markAsTouched();
        this.setViewAndValidateDates(this.datesControl, dates, this.validateField);

        if (typeof this.changed === 'function') {
            this.changed(dates);
        }

        this.selectedCalendarDates = dates;
    }

    onTouched() {
        if (typeof this.touched === 'function') {
            this.touched();
        }
    }

    onCancel() {
        this.datesControl.markAsTouched();
        this.trigger.closeMenu();
    }

    onBlur() {
        this.datesControl.markAsTouched();
    }

    getFormattedDates = (dates: Dayjs[]) =>
        DateHelper.datesToCompactedStringDates(dates, this.dateFormats.display.dateInput);

    setViewAndValidateDates(formControl: FormControl, dates: Dayjs[] | null, validate: boolean = true) {
        // Set the value of the form control to the formatted dates
        formControl.setValue(this.getFormattedDates(dates));

        // Trigger validation using dates array, but don't update the displayed value
        if (validate && (formControl.touched || formControl.dirty)) {
            formControl.setValue(dates, { emitModelToViewChange: false });
        }
    }
}
