import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    Validators
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ListItem } from 'src/app/shared/models/list-item';
import { Certification } from 'src/app/shared/models/candidate';
import { DateHelper, expirationDateValidator } from 'src/app/shared/utilities';
import { FeatureFlag } from "src/app/shared/models/enums/feature-flag.enum";
import { LDFeatureManager } from "src/app/shared/feature-management/ld-feature-manager";

@Component({
    selector: 'ayac-certifications-form',
    templateUrl: './certifications-form.component.html',
    styleUrls: ['./certifications-form.component.scss']
})
export class CertificationsFormComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    formArray: UntypedFormArray;
    @Input() formGroupName = 'certifications';
    @Input() candidateId?: number;
    @Input() certificationsLabel = 'Certifications';
    @Input() certifications: ListItem[] = [];
    @Output() onChangeMade = new EventEmitter();
    dataPopulated = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredCertifications$: Observable<ListItem[]>;

    public certificationsAutocompleteFormControl = new UntypedFormControl();
    @ViewChild('certificationInput')
    certificationInput: ElementRef<HTMLInputElement>;
    disableUtcConversionForVendorFields = false;

    get selectedCertificationIds(): number[] {
        const result: number[] = [];
        this.formArray.controls.forEach((certificationForm: UntypedFormGroup) => {
            result.push(certificationForm.get('certificationId').value);
        });
        return result;
    }

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formGroupDirective: FormGroupDirective,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.formArray = this.formBuilder.array([]);
    }

    ngOnInit(): void {
        this.filteredCertifications$ = this.certificationsAutocompleteFormControl.valueChanges.pipe(
            startWith(null),
            map((filter: string | null) => {
                const certifications =
                    this.certifications?.filter((x) => this.selectedCertificationIds.indexOf(x.id) < 0) ?? [];
                return filter
                    ? certifications.filter((x) => x.name.toLowerCase().includes(filter.toString().toLowerCase()))
                    : certifications.slice();
            })
        );

        this.formGroupDirective.form.addControl(this.formGroupName, this.formArray);
        this.formArray.setParent(this.formGroupDirective.form);

        this.formArray.valueChanges.pipe(takeUntil(this.d$)).subscribe((valueChange) => {
            if (!this.dataPopulated) {
                return;
            }

            this.onChangeMade.emit();
        });

        this.ldFeatureManager
            .isEnabled(FeatureFlag.DisableUtcConversionForVendorFields)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.disableUtcConversionForVendorFields = isEnabled;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.certifications?.currentValue) {
            this.certificationsAutocompleteFormControl.setValue('');
        }
    }

    addCertification(certification?: Certification): void {
        const form = this.formBuilder.group({
            candidateId: this.formBuilder.control(this.candidateId),
            certificationId: this.formBuilder.control(certification.certificationId),
            expDate: this.formBuilder.control(certification.expDate, [Validators.required, expirationDateValidator]),
            name: this.formBuilder.control(certification.certificationDto.name)
        });

        const sortedFormArray = this.formArray.controls.sort((controlA, controlB) => {
            const certificationA = controlA.get('certificationId').value;
            const certificationB = controlB.get('certificationId').value;
            return certificationA < certificationB ? -1 : 1;
        });

        const higherCertificationIndex = sortedFormArray.findIndex(
            (x) => x.get('certificationId').value > certification.certificationId
        );

        if (higherCertificationIndex === -1) {
            this.formArray.push(form);
        } else {
            this.formArray.insert(higherCertificationIndex, form);
        }

        form.get('expDate').markAsTouched();
    }

    deleteCertification(certificationId: number): void {
        const controlToRemove = this.formArray.controls.find(
            (formGroup: UntypedFormGroup) => formGroup.get('certificationId').value === certificationId
        );
        this.formArray.removeAt(this.formArray.controls.indexOf(controlToRemove));
        this.certificationsAutocompleteFormControl.setValue('');
        this.certificationsAutocompleteFormControl.markAsTouched();
    }

    populate(certifications: Array<Certification>): void {
        this.formArray.clear();

        certifications?.forEach((certification) => {
            this.addCertification(certification);
        });

        this.certificationsAutocompleteFormControl.setValue('');

        this.dataPopulated = true;
    }

    getCertifications(): Array<Certification> {
        const result = new Array<Certification>();

        this.formArray.controls.forEach((certificationForm: UntypedFormGroup) => {
            const certification: Certification = {
                vendorCandidateId: certificationForm.get('candidateId').value,
                certificationId: certificationForm.get('certificationId').value,
                expDate: this.disableUtcConversionForVendorFields ? DateHelper.localDate(certificationForm.get('expDate')?.value) : certificationForm.get('expDate')?.value,
                certificationDto: {
                    id: certificationForm.get('certificationId').value,
                    oldId: certificationForm.get('certificationId').value,
                    name: certificationForm.get('name').value
                }
            };

            result.push(certification);
        });

        return result;
    }

    public getCertificationName(certificationId: number): string {
        return this.certifications?.find((x) => x.id === certificationId)?.name ?? '';
    }

    certificationSelected(event: MatAutocompleteSelectedEvent): void {
        const certification = this.certifications.find((x) => x.id === +event.option.value);

        this.addCertification({
            vendorCandidateId: this.candidateId,
            certificationId: certification.id,
            expDate: null,
            certificationDto: {
                id: certification.id,
                oldId: certification.id,
                name: certification.name
            }
        });

        if (this.certificationInput?.nativeElement) {
            this.certificationInput.nativeElement.value = '';
        }
        this.certificationsAutocompleteFormControl.setValue(null);
        this.certificationsAutocompleteFormControl.markAsTouched();
    }

    addFilteredCertification(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        const certification = this.certifications.find((x) => x.name.toLowerCase() === value?.toLowerCase());

        if (certification) {
            this.addCertification({
                vendorCandidateId: this.candidateId,
                certificationId: certification.id,
                expDate: null,
                certificationDto: {
                    id: certification.id,
                    oldId: certification.id,
                    name: certification.name
                }
            });
        }

        if (this.certificationInput?.nativeElement) {
            this.certificationInput.nativeElement.value = '';
        }
        this.certificationsAutocompleteFormControl.setValue(null);
        this.certificationsAutocompleteFormControl.markAsTouched();
    }
}
