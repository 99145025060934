export enum XAlchemistsFeatureFlags {
    ConnectStandaloneVMSClientOfferFlow = 'CONNECT_STANDALONE_VMS_CLIENT_OFFER_FLOW',
    ConnectVMSClientEditOffer = 'connect-vms-client-edit-offer',
    ConnectVMSClientRescindOffer = 'connect-vms-client-rescind-offer',
    ConnectVMSClientCreateOffer = 'connect-vms-client-create-offer',
    ConnectVMSOfferChip = 'connect-vms-offer-chip',
    ConnectVMSClientOfferNotes = 'connect-vms-client-offer-notes',
    ConnectVMSDeclineChangesOffer = 'connect-vms-client-decline-changes',
    ConnectVMSExtensionOffer = 'connect-vms-extension-offer'
}
