import { Action, createReducer, on } from '@ngrx/store';
import { ScheduleTypes } from '../../shift-resource-fulfillment-scheduler/shift-resource-fulfillment-scheduler.component';
import * as shiftsActions from '../actions';
import { LoadingTypes } from 'src/app/shared/models';
import { ShiftBonusManagementItem } from 'src/app/shift-bonus-management/models';

export interface ShiftBonusAssignmentState {
    isInternal: boolean;
    scheduleType: ScheduleTypes;
    bonusRateId?: number;

    bonusManagementDetails?: ShiftBonusManagementItem;
    loading: LoadingTypes;
}

export const initialState: ShiftBonusAssignmentState = {
    isInternal: true,
    scheduleType: ScheduleTypes.settings,
    bonusRateId: null,
    bonusManagementDetails: null,
    loading: LoadingTypes.INIT
};

const reducer = createReducer(
    initialState,
    on(shiftsActions.setShiftVisibility, (state, action) => ({ ...state, isInternal: action.isInternal })),
    on(shiftsActions.setScheduleType, (state, action) => ({ ...state, scheduleType: action.scheduleType })),
    on(shiftsActions.setBonusRate, (state, action) => ({ ...state, bonusRateId: action.bonusRateId })),
    on(shiftsActions.loadShiftBonusManagementDetails, (state, action) => ({
        ...state,
        bonusManagementDetails: null,
        loading: LoadingTypes.LOADING
    })),
    on(shiftsActions.loadShiftBonusManagementDetailsFail, (state, action) => ({
        ...state,
        loading: LoadingTypes.FAIL,
        error: action.error
    })),
    on(shiftsActions.loadShiftBonusManagementDetailsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        bonusManagementDetails: action.bonusManagementDetails
    })),
    on(shiftsActions.clearShiftBonusManagementDetails, (state, action) => ({
        ...state,
        ...initialState
    })),
);

export const shiftBonusAssignmentReducer = (state: ShiftBonusAssignmentState | undefined, action: Action) =>
    reducer(state, action);
