import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export class DateHelper {
    static dayNumber(d: string | Date): number {
        const date = d instanceof Date ? (d as Date) : new Date(d);
        const time = date.getTime();
        const timeZoneOffset = date.getTimezoneOffset() / 60 / 24;
        return Math.floor(time / 1000 / 60 / 60 / 24 - timeZoneOffset);
    }

    static diffDay(d1: string | Date, d2: string | Date): number {
        return DateHelper.dayNumber(d2) - DateHelper.dayNumber(d1);
    }

    static isSameDay(d1: string | Date, d2: string | Date): boolean {
        return DateHelper.dayNumber(d1) === DateHelper.dayNumber(d2);
    }

    static isAfterDay(d1: string | Date, d2: string | Date): boolean {
        return DateHelper.dayNumber(d1) > DateHelper.dayNumber(d2);
    }

    static isBeforeDay(d1: string | Date, d2: string | Date): boolean {
        return DateHelper.dayNumber(d1) < DateHelper.dayNumber(d2);
    }

    static inRangeDay(d: string | Date, start: string | Date, end: string | Date) {
        return (
            DateHelper.dayNumber(d) >= DateHelper.dayNumber(start) &&
            DateHelper.dayNumber(d) <= DateHelper.dayNumber(end)
        );
    }

    static inWorkday(date: string | Date, workdays: number[] = []): boolean {
        const d = new Date(date);
        return workdays.includes(d.getDay());
    }

    static isValidDate(d: Date) {
        return d instanceof Date && !isNaN(d.getTime());
    }

    static getUTCDate(date: string | Date): Date {
        date = date instanceof Date ? date : new Date(date);

        const utcDate = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        );

        return new Date(utcDate);
    }

    /**
     * It will format Date without applied timezone offset.
     * Example: Thu Jan 18 2024 10:15:51 GMT-0800 (Pacific Standard Time)
     * format result will be '2024-01-18T10:14:51Z'
     */
    static dateFormat(date: string | Date): string {
        date = date instanceof Date ? date : new Date(date);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    }

    static dateFormatUTC(date: string | Date): string {
        date = date instanceof Date ? date : new Date(date);

        return date.toISOString().substr(0, 19);
    }

    static localDate(date: string | Date): string {
        if (!date) {
            return '';
        }

        date = new Date(date);
        const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        return date ? dateTimeFormatter.format(date) : '';
    }

    static adjustForTimezone(date: string | Date): Date {
        date = date instanceof Date ? date : new Date(date);
        const offset = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + offset);

        return date;
    }

    static getDatesRange(start: Date, end: Date): Date[] {
        const dates = [];
        if (!start && !end) {
            return dates;
        }
        start = new Date(start);
        end = new Date(end);
        const current = start;

        while (DateHelper.isBeforeDay(current, end) || DateHelper.isSameDay(current, end)) {
            dates.push(new Date(current));
            current.setDate(current.getDate() + 1);
        }

        return dates;
    }

    static isOverHoursAwayFromDate(date: Date, hours: number): boolean {
        const now = new Date();
        now.setTime(now.getTime() + hours * 60 * 60 * 1000);
        return date > now;
    }

    static timeFormatToHHmm(date: string | Date): string {
        date = date instanceof Date ? date : new Date(date);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    static rtoDatesToString(rtoDates: Date[], dateFormat: string = 'MM/DD/YYYY', isUtc = false): string {
        if (!rtoDates?.length) {
            return '';
        }
        const sortedDates = rtoDates.map((date) => dayjs(date).utc(isUtc)).sort((a, b) => (a.isBefore(b) ? -1 : 1));
        const result: string[] = [];
        let startIndex = 0;

        while (startIndex < sortedDates.length) {
            let endIndex = startIndex;

            // Find the end index of the consecutive date range
            while (
                endIndex < sortedDates.length - 1 &&
                DateHelper.areDatesConsecutive(sortedDates[endIndex], sortedDates[endIndex + 1])
            ) {
                endIndex++;
            }

            if (startIndex === endIndex) {
                result.push(sortedDates[startIndex].format(dateFormat));
            } else {
                result.push(
                    `${sortedDates[startIndex].format(dateFormat)} - ${sortedDates[endIndex].format(dateFormat)}`
                );
            }

            startIndex = endIndex + 1;
        }
        return result.join(', ');
    }

    static areDatesConsecutive(date1: dayjs.Dayjs, date2: dayjs.Dayjs): boolean {
        return date1.add(1, 'day').isSame(date2, 'day');
    }

    static getLocalTimezoneName(): string {
        const localDate = new Date();
        const shortLocalDate = localDate.toLocaleDateString(undefined);
        const fullLocalDate = localDate.toLocaleDateString(undefined, { timeZoneName: 'long' });

        let timeZoneAcronym = fullLocalDate;

        const shortIndex = fullLocalDate.indexOf(shortLocalDate);
        if (shortIndex >= 0) {
            const longTimeZoneName =
                fullLocalDate.substring(0, shortIndex) + fullLocalDate.substring(shortIndex + shortLocalDate.length);

            timeZoneAcronym = longTimeZoneName.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
            const upperCaseLettersMatches = timeZoneAcronym.match(/\b(\w)/g);
            timeZoneAcronym = upperCaseLettersMatches?.join('') ?? '';
        }

        return timeZoneAcronym;
    }

    /***
     * Convert array of Dayjs dates to comma delimited string date(s), where
     * consecutive dates are compacted into start and end date separated with a dash.
     * Example:
     *     Given input of ['2024-01-01', '2024-01-05', '2024-02-01', '2024-02-05'] *replace dates in array with Dayjs dates
     *     Output would be '01/01/2024 - 01/05/2024, 02/01/2024, 02/05/2024'
     * @param dates: Dayjs array
     * @param format: optional date format string defaulted to 'MM/DD/YYYY'
     * @return string
     */
    static readonly datesToCompactedStringDates = (dates: Dayjs[], format: string = 'MM/DD/YYYY') =>
        DateHelper.rtoDatesToString(
            dates.map((d) => d.toDate()),
            format
        );
}
