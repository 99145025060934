import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { LDFeatureManager } from '../shared/feature-management/ld-feature-manager';
import { FeatureFlag } from '../shared/models/enums/feature-flag.enum';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from './utils';

@Injectable({
    providedIn: 'root'
})
export class ConnectVendorCheckGuard extends UnsubscribeOnDestroy implements CanActivate {
    featureFlag = FeatureFlag;
    privFlagEnabled = false;
    constructor(
        private readonly _identityService: IdentityService,
        private readonly _router: Router,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    canActivate(): boolean {
        if (this._identityService.type === 'vendor') {
            this._router.navigate(['/vendor/dashboard']);
            return false;
        } else {
            return true;
        }
    }

    private _isLdFeatureFlagEnabled(featureName: FeatureFlag): boolean {
        let isFeatureEnabled = false;
        this._ldFeatureManager
            .isEnabled(featureName)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                isFeatureEnabled = flagIsEnabled;
            });
        return isFeatureEnabled;
    }
}
