export enum VendorSubmittalStatuses {
    CandidateSubmitted = 1,
    PendingInformation = 2,
    Queued = 3,
    SentToClinical = 4,
    SubmittedToClient = 5,
    UnderReviewByHiringManager = 6,
    AdditionalReviewNeeded = 7,
    CandidateNotSelected = 8,
    CandidateWithdrawingFromSubmission = 9,
    Interview = 10,
    Offer = 11,
    OfferDeclined = 13,
    Placed = 14,
    OfferRescinded = 15,
    Canceled = 16,
    OfferAccepted = 17
}
