import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes } from 'src/app/shared/models';
import { Candidate, ContractRequirements } from 'src/app/shared/models/candidate';
import { PagingToken, getEmptyPagingToken } from 'src/app/shared/models/paging-token';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';
import {
    VendorCandidateProfileState,
    getInitialVendorCandidateProfileState
} from 'src/app/vendor/vendor-candidate-details/store/state/vendor-candidate-profile.state';
import {
    VendorCandidateRequirementDocumentUploadState,
    getInitialVendorCandidateRequirementDocumentUploadState
} from './vendor-candidate-requirement-document-upload.state';

export const vendorCandidateDetailsStoreKey = 'vendor/candidate-details';

export interface VendorCandidateState {
    candidateDetailsState: VendorCandidateDetailsState;
    candidateFileListState: VendorCandidateFileListState;
    currentShiftListState: CurrentShiftListState;
    availableShiftListState: AvailableShiftListState;
    contractRequirementsState: ContractRequirementsState;
    candidateProfile: VendorCandidateProfileState;
    documentUpload: VendorCandidateRequirementDocumentUploadState;
}

export const getInitialVendorCandidateState = (): VendorCandidateState => ({
    candidateDetailsState: getInitialVendorCandidateDetailsState(),
    candidateFileListState: getInitialVendorCandidateFileListState(),
    currentShiftListState: getInitialCurrentShiftListState(),
    availableShiftListState: getInitialAvailableShiftListState(),
    contractRequirementsState: getInitialVendorCandidateContractRequirementsState(),
    candidateProfile: getInitialVendorCandidateProfileState(),
    documentUpload: getInitialVendorCandidateRequirementDocumentUploadState()
});

export interface VendorCandidateDetailsState {
    loadingState: LoadingTypes;
    hasContractAtPerDiemHospital: boolean;
    error: string;
    candidateId: number;
    candidate: Candidate;
}

export const getInitialVendorCandidateDetailsState = (): VendorCandidateDetailsState => ({
    loadingState: LoadingTypes.INIT,
    hasContractAtPerDiemHospital: false,
    error: undefined,
    candidateId: null,
    candidate: null
});

export interface ContractRequirementsState {
    loadingState: LoadingTypes;
    contractRequirementsLoading: LoadingTypes;
    contractRequirements: ContractRequirements;
    error: string;
}

export const getInitialVendorCandidateContractRequirementsState = (): ContractRequirementsState => ({
    loadingState: LoadingTypes.INIT,
    contractRequirementsLoading: LoadingTypes.INIT,
    contractRequirements: null,
    error: undefined
});

export const getInitialVendorCandidateFileListState = (): VendorCandidateFileListState => ({
    request: {
        sort: [],
        filter: null,
        take: 10,
        skip: 0,
        groups: []
    } as unknown as GridSearchQuery,
    response: {
        data: [],
        total: 0,
        filtered: 0
    } as unknown as GridDataResult,
    candidateId: null,
    isLoading: false,
    error: undefined,
    isDeleting: false,
    deletingFileIds: [],
    totalFileSize: 0
});

export interface VendorCandidateFileListState {
    request: GridSearchQuery;
    response: GridDataResult;
    candidateId: number;
    isLoading: boolean;
    isDeleting: boolean;
    deletingFileIds: number[];
    isDeleteSuccess?: boolean;
    totalFileSize: number;
    error: unknown;
}

export interface CurrentShiftListState {
    loadingState: LoadingTypes;
    shifts: PagingToken<ShiftListItem[]>;
    gridSearchQuery: GridSearchQuery;
    error: string;
}

export const getInitialCurrentShiftListState = (): CurrentShiftListState => ({
    ...getInitialShiftGridState()
});

export interface AvailableShiftListState {
    loadingState: LoadingTypes;
    shifts: PagingToken<ShiftListItem[]>;
    gridSearchQuery: GridSearchQuery;
    error: string;
}

export const getInitialAvailableShiftListState = (): AvailableShiftListState => ({
    ...getInitialShiftGridState()
});

const getInitialShiftGridState = (): CurrentShiftListState => ({
    loadingState: LoadingTypes.INIT,
    shifts: getEmptyPagingToken<ShiftListItem>(),
    gridSearchQuery: {
        sort: [],
        take: 10,
        skip: 0
    },
    error: ''
});
