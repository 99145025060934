import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import { exportInvoices, exportInvoicesSuccess, exportInvoicesFail } from '../invoices.actions';
import { of } from 'rxjs';
import { selectInvoicesQuery } from '../invoices.selectors';
import { Utilities } from 'src/app/core/utils';
import { ToasterService } from 'src/app/core/services';
import * as dayjs from 'dayjs';

@Injectable()
export class InvoicesExportEffect {
    exportInvoices$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(exportInvoices),
            concatLatestFrom(() => this._store.select(selectInvoicesQuery)),
            switchMap(([action]) => {
                const filename = `Invoices ${dayjs().format('MMDDYYYY')}`;
                const timeZoneOffset = dayjs().utcOffset() / 60;

                return this._invoicesRepository.exportInvoices(action.invoiceIds, timeZoneOffset).pipe(
                    map((response) =>
                        exportInvoicesSuccess({
                            blob: response,
                            filename
                        })
                    ),
                    catchError((error: unknown) => of(exportInvoicesFail({ error })))
                );
            })
        );
    });

    downloadInvoices$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(exportInvoicesSuccess),
                tap((action) => {
                    Utilities.downloadBlob(action.blob, action.filename);
                })
            );
        },
        { dispatch: false }
    );

    exportInvoicesFail$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(exportInvoicesFail),
                tap(() => this._toaster.fail('Failed to export Invoices'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store,
        private readonly _invoicesRepository: InvoicesRepositoryService,
        private readonly _toaster: ToasterService
    ) {}
}
