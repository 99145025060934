import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import * as actions from 'src/app/admin/store/actions/vendor-insurance.actions';
import { LDFeatureManager } from "src/app/shared/feature-management/ld-feature-manager";
import { FeatureFlag } from "src/app/shared/models/enums/feature-flag.enum";
import { DateHelper } from "src/app/shared/utilities";

export const DAY_FIRST_FORMAT = {
    parse: {
        dateInput: 'DD-MMMM-YYYY'
    },
    display: {
        dateInput: 'DD-MMMM-YYYY',
        monthYearLabel: 'MMM YYYY'
    }
};

@Component({
    selector: 'ayac-vendor-insurance',
    templateUrl: './vendor-insurance.component.html',
    styleUrls: ['./vendor-insurance.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: DAY_FIRST_FORMAT }]
})
export class VendorInsuranceComponent implements OnInit, OnDestroy {
    @Output()
    formChanged = new EventEmitter<{ vendor: VendorDetail }>();

    @Output()
    exportInsurance = new EventEmitter<{ vendor: VendorDetail }>();

    datePickerOptions = {
        displayFormat: 'dd-MMMM-yyyy',
        inputFormat: 'yyyy-MM-dd'
    };

    vendorInsuranceForm: UntypedFormGroup = this._formBuilder.group({
        generalLiabilityExp: this._formBuilder.control(''),
        generalLiabilityPerOccurrence: this._formBuilder.control(''),
        generalLiabilityAggregrate: this._formBuilder.control(''),
        generalLiabilityWaiverOfSubrogation: this._formBuilder.control(''),
        generalLiabilityUmbrellaCoverageRequired: this._formBuilder.control(''),
        profecionalLiabilityExp: this._formBuilder.control(''),
        profecionalLiabilityPerOccurrence: this._formBuilder.control(''),
        profecionalLiabilityAggregrate: this._formBuilder.control(''),
        profecionalLiabilityWaiverOfSubrogation: this._formBuilder.control(''),
        profecionalLiabilityUmbrellaCoverageRequired: this._formBuilder.control(''),
        workersCompExp: this._formBuilder.control(''),
        workersCompWcaccident: this._formBuilder.control(''),
        workersCompDisease: this._formBuilder.control(''),
        workersCompLimit: this._formBuilder.control(''),
        workersCompWaiverOfSubrogation: this._formBuilder.control(''),
        workersCompUmbrellaCoverageRequired: this._formBuilder.control(''),
        umbrellaExp: this._formBuilder.control(''),
        umbrellaPerOccurrence: this._formBuilder.control(''),
        umbrellaAggregateLimit: this._formBuilder.control(''),
        autoExp: this._formBuilder.control(''),
        umbrellaAuto: this._formBuilder.control(''),
        umbrellaCoverageRequired: this._formBuilder.control(''),
        umbrellaWaiverSubrogation: this._formBuilder.control(''),
        cyberExp: this._formBuilder.control(''),
        umbrellaCyber: this._formBuilder.control(''),
        umbrellaCyberCoverage: this._formBuilder.control(''),
        umbrellaCyberWaiverSubrogation: this._formBuilder.control(''),
        employeeCrimeExp: this._formBuilder.control(''),
        umbrellaEmployeeCrimeTheftDishonesty: this._formBuilder.control(''),
        umbrellaCrimeWaiverSubrogation: this._formBuilder.control(''),
        sexualAssaultExp: this._formBuilder.control(''),
        umbrellaSexualAssaultCoverage: this._formBuilder.control(''),
        umbrellaSexualAssaultCoverageRequired: this._formBuilder.control(''),
        umbrellaSexualAssaultWaiverSubrogation: this._formBuilder.control(''),
        doExp: this._formBuilder.control(''),
        umbrellaDocoverage: this._formBuilder.control(''),
        umbrellaDocoverageRequired: this._formBuilder.control(''),
        umbrellaDowaiverSubrogation: this._formBuilder.control(''),
        umbrellaRequiredLanguage: this._formBuilder.control(''),
        umbrellaNotes: this._formBuilder.control('')
    });

    @Input()
    set vendorInsurance(vendorDetails: VendorDetail) {
        this._vendorDetails = vendorDetails;

        if (this.vendorInsuranceForm) {
            this.vendorInsuranceForm.get('generalLiabilityExp').patchValue(vendorDetails.generalLiabilityExp);
            this.vendorInsuranceForm
                .get('generalLiabilityPerOccurrence')
                .patchValue(vendorDetails.generalLiabilityPerOccurrence);
            this.vendorInsuranceForm
                .get('generalLiabilityAggregrate')
                .patchValue(vendorDetails.generalLiabilityAggregrate);
            this.vendorInsuranceForm
                .get('generalLiabilityWaiverOfSubrogation')
                .patchValue(vendorDetails.generalLiabilityWaiverOfSubrogation);
            this.vendorInsuranceForm
                .get('generalLiabilityUmbrellaCoverageRequired')
                .patchValue(vendorDetails.generalLiabilityUmbrellaCoverageRequired);
            this.vendorInsuranceForm.get('profecionalLiabilityExp').patchValue(vendorDetails.profecionalLiabilityExp);
            this.vendorInsuranceForm
                .get('profecionalLiabilityPerOccurrence')
                .patchValue(vendorDetails.profecionalLiabilityPerOccurrence);
            this.vendorInsuranceForm
                .get('profecionalLiabilityAggregrate')
                .patchValue(vendorDetails.profecionalLiabilityAggregrate);
            this.vendorInsuranceForm
                .get('profecionalLiabilityWaiverOfSubrogation')
                .patchValue(vendorDetails.profecionalLiabilityWaiverOfSubrogation);
            this.vendorInsuranceForm
                .get('profecionalLiabilityUmbrellaCoverageRequired')
                .patchValue(vendorDetails.profecionalLiabilityUmbrellaCoverageRequired);
            this.vendorInsuranceForm.get('workersCompExp').patchValue(vendorDetails.workersCompExp);
            this.vendorInsuranceForm.get('workersCompWcaccident').patchValue(vendorDetails.workersCompWcaccident);
            this.vendorInsuranceForm.get('workersCompDisease').patchValue(vendorDetails.workersCompDisease);
            this.vendorInsuranceForm.get('workersCompLimit').patchValue(vendorDetails.workersCompLimit);
            this.vendorInsuranceForm
                .get('workersCompWaiverOfSubrogation')
                .patchValue(vendorDetails.workersCompWaiverOfSubrogation);
            this.vendorInsuranceForm
                .get('workersCompUmbrellaCoverageRequired')
                .patchValue(vendorDetails.workersCompUmbrellaCoverageRequired);
            this.vendorInsuranceForm.get('umbrellaExp').patchValue(vendorDetails.umbrellaExp);
            this.vendorInsuranceForm.get('umbrellaPerOccurrence').patchValue(vendorDetails.umbrellaPerOccurrence);
            this.vendorInsuranceForm.get('umbrellaAggregateLimit').patchValue(vendorDetails.umbrellaAggregateLimit);
            this.vendorInsuranceForm.get('autoExp').patchValue(vendorDetails.autoExp);
            this.vendorInsuranceForm.get('umbrellaAuto').patchValue(vendorDetails.umbrellaAuto);
            this.vendorInsuranceForm.get('umbrellaCoverageRequired').patchValue(vendorDetails.umbrellaCoverageRequired);
            this.vendorInsuranceForm
                .get('umbrellaWaiverSubrogation')
                .patchValue(vendorDetails.umbrellaWaiverSubrogation);
            this.vendorInsuranceForm.get('cyberExp').patchValue(vendorDetails.cyberExp);
            this.vendorInsuranceForm.get('umbrellaCyber').patchValue(vendorDetails.umbrellaCyber);
            this.vendorInsuranceForm.get('umbrellaCyberCoverage').patchValue(vendorDetails.umbrellaCyberCoverage);
            this.vendorInsuranceForm
                .get('umbrellaCyberWaiverSubrogation')
                .patchValue(vendorDetails.umbrellaCyberWaiverSubrogation);
            this.vendorInsuranceForm.get('employeeCrimeExp').patchValue(vendorDetails.employeeCrimeExp);
            this.vendorInsuranceForm
                .get('umbrellaEmployeeCrimeTheftDishonesty')
                .patchValue(vendorDetails.umbrellaEmployeeCrimeTheftDishonesty);
            this.vendorInsuranceForm
                .get('umbrellaCrimeWaiverSubrogation')
                .patchValue(vendorDetails.umbrellaCrimeWaiverSubrogation);
            this.vendorInsuranceForm.get('sexualAssaultExp').patchValue(vendorDetails.sexualAssaultExp);
            this.vendorInsuranceForm
                .get('umbrellaSexualAssaultCoverage')
                .patchValue(vendorDetails.umbrellaSexualAssaultCoverage);
            this.vendorInsuranceForm
                .get('umbrellaSexualAssaultCoverageRequired')
                .patchValue(vendorDetails.umbrellaSexualAssaultCoverageRequired);
            this.vendorInsuranceForm
                .get('umbrellaSexualAssaultWaiverSubrogation')
                .patchValue(vendorDetails.umbrellaSexualAssaultWaiverSubrogation);
            this.vendorInsuranceForm.get('doExp').patchValue(vendorDetails.doExp);
            this.vendorInsuranceForm.get('umbrellaDocoverage').patchValue(vendorDetails.umbrellaDocoverage);
            this.vendorInsuranceForm
                .get('umbrellaDocoverageRequired')
                .patchValue(vendorDetails.umbrellaDocoverageRequired);
            this.vendorInsuranceForm
                .get('umbrellaDowaiverSubrogation')
                .patchValue(vendorDetails.umbrellaDowaiverSubrogation);
            this.vendorInsuranceForm.get('umbrellaRequiredLanguage').patchValue(vendorDetails.umbrellaRequiredLanguage);
            this.vendorInsuranceForm.get('umbrellaNotes').patchValue(vendorDetails.umbrellaNotes);
        }
    }

    private _vendorDetails: VendorDetail;
    private _cleanup$ = new Subject();
    disableUtcConversionForAdminFields = false;

    constructor(private readonly _formBuilder: UntypedFormBuilder,
                private readonly _store: Store,
                private readonly ldFeatureManager: LDFeatureManager) {}

    ngOnInit(): void {
        this.vendorInsuranceForm.valueChanges
            .pipe(
                startWith(null as string),
                pairwise(),
                map(([oldState, newState]) => {
                    if (oldState === null || oldState === undefined) {
                        return true;
                    }
                    let hasChanges = false;
                    for (const key in newState) {
                        if (oldState[key] === undefined || oldState[key] !== newState[key]) {
                            hasChanges = true;
                            break;
                        }
                    }
                    return hasChanges;
                }),
                filter((hasChanges) => hasChanges && !this.vendorInsuranceForm.invalid),
                takeUntil(this._cleanup$)
            )
            .subscribe(() => {
                if (this.vendorInsuranceForm.dirty) {
                    this._vendorDetails.generalLiabilityExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('generalLiabilityExp')?.value)
                        : this.vendorInsuranceForm.get('generalLiabilityExp')?.value;
                    this._vendorDetails.generalLiabilityPerOccurrence = this.vendorInsuranceForm.get(
                        'generalLiabilityPerOccurrence'
                    ).value;
                    this._vendorDetails.generalLiabilityAggregrate =
                        this.vendorInsuranceForm.get('generalLiabilityAggregrate').value;
                    this._vendorDetails.generalLiabilityWaiverOfSubrogation = this.vendorInsuranceForm.get(
                        'generalLiabilityWaiverOfSubrogation'
                    ).value;
                    this._vendorDetails.generalLiabilityUmbrellaCoverageRequired = this.vendorInsuranceForm.get(
                        'generalLiabilityUmbrellaCoverageRequired'
                    ).value;
                    this._vendorDetails.profecionalLiabilityExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('profecionalLiabilityExp')?.value)
                        : this.vendorInsuranceForm.get('profecionalLiabilityExp')?.value;
                    this._vendorDetails.profecionalLiabilityPerOccurrence = this.vendorInsuranceForm.get(
                        'profecionalLiabilityPerOccurrence'
                    ).value;
                    this._vendorDetails.profecionalLiabilityAggregrate = this.vendorInsuranceForm.get(
                        'profecionalLiabilityAggregrate'
                    ).value;
                    this._vendorDetails.profecionalLiabilityWaiverOfSubrogation = this.vendorInsuranceForm.get(
                        'profecionalLiabilityWaiverOfSubrogation'
                    ).value;
                    this._vendorDetails.profecionalLiabilityUmbrellaCoverageRequired = this.vendorInsuranceForm.get(
                        'profecionalLiabilityUmbrellaCoverageRequired'
                    ).value;
                    this._vendorDetails.workersCompExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('workersCompExp')?.value)
                        : this.vendorInsuranceForm.get('workersCompExp')?.value;
                    this._vendorDetails.workersCompWcaccident =
                        this.vendorInsuranceForm.get('workersCompWcaccident').value;
                    this._vendorDetails.workersCompDisease = this.vendorInsuranceForm.get('workersCompDisease').value;
                    this._vendorDetails.workersCompLimit = this.vendorInsuranceForm.get('workersCompLimit').value;
                    this._vendorDetails.workersCompWaiverOfSubrogation = this.vendorInsuranceForm.get(
                        'workersCompWaiverOfSubrogation'
                    ).value;
                    this._vendorDetails.workersCompUmbrellaCoverageRequired = this.vendorInsuranceForm.get(
                        'workersCompUmbrellaCoverageRequired'
                    ).value;
                    this._vendorDetails.umbrellaExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('umbrellaExp')?.value)
                        : this.vendorInsuranceForm.get('umbrellaExp')?.value;
                    this._vendorDetails.umbrellaPerOccurrence =
                        this.vendorInsuranceForm.get('umbrellaPerOccurrence').value;
                    this._vendorDetails.umbrellaAggregateLimit =
                        this.vendorInsuranceForm.get('umbrellaAggregateLimit').value;
                    this._vendorDetails.autoExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('autoExp')?.value)
                        : this.vendorInsuranceForm.get('autoExp')?.value;
                    this._vendorDetails.umbrellaAuto = this.vendorInsuranceForm.get('umbrellaAuto').value;
                    this._vendorDetails.umbrellaCoverageRequired =
                        this.vendorInsuranceForm.get('umbrellaCoverageRequired').value;
                    this._vendorDetails.umbrellaWaiverSubrogation =
                        this.vendorInsuranceForm.get('umbrellaWaiverSubrogation').value;
                    this._vendorDetails.cyberExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('cyberExp')?.value)
                        : this.vendorInsuranceForm.get('cyberExp')?.value;
                    this._vendorDetails.umbrellaCyber = this.vendorInsuranceForm.get('umbrellaCyber').value;
                    this._vendorDetails.umbrellaCyberCoverage =
                        this.vendorInsuranceForm.get('umbrellaCyberCoverage').value;
                    this._vendorDetails.umbrellaCyberWaiverSubrogation = this.vendorInsuranceForm.get(
                        'umbrellaCyberWaiverSubrogation'
                    ).value;
                    this._vendorDetails.employeeCrimeExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('employeeCrimeExp')?.value)
                        : this.vendorInsuranceForm.get('employeeCrimeExp')?.value;
                    this._vendorDetails.umbrellaEmployeeCrimeTheftDishonesty = this.vendorInsuranceForm.get(
                        'umbrellaEmployeeCrimeTheftDishonesty'
                    ).value;
                    this._vendorDetails.umbrellaCrimeWaiverSubrogation = this.vendorInsuranceForm.get(
                        'umbrellaCrimeWaiverSubrogation'
                    ).value;
                    this._vendorDetails.sexualAssaultExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('sexualAssaultExp')?.value)
                        : this.vendorInsuranceForm.get('sexualAssaultExp')?.value;
                    this._vendorDetails.umbrellaSexualAssaultCoverage = this.vendorInsuranceForm.get(
                        'umbrellaSexualAssaultCoverage'
                    ).value;
                    this._vendorDetails.umbrellaSexualAssaultCoverageRequired = this.vendorInsuranceForm.get(
                        'umbrellaSexualAssaultCoverageRequired'
                    ).value;
                    this._vendorDetails.umbrellaSexualAssaultWaiverSubrogation = this.vendorInsuranceForm.get(
                        'umbrellaSexualAssaultWaiverSubrogation'
                    ).value;
                    this._vendorDetails.doExp = this.disableUtcConversionForAdminFields
                        ? DateHelper.localDate(this.vendorInsuranceForm.get('doExp')?.value)
                        : this.vendorInsuranceForm.get('doExp')?.value;
                    this._vendorDetails.umbrellaDocoverage = this.vendorInsuranceForm.get('umbrellaDocoverage').value;
                    this._vendorDetails.umbrellaDocoverageRequired =
                        this.vendorInsuranceForm.get('umbrellaDocoverageRequired').value;
                    this._vendorDetails.umbrellaDowaiverSubrogation =
                        this.vendorInsuranceForm.get('umbrellaDowaiverSubrogation').value;
                    this._vendorDetails.umbrellaRequiredLanguage =
                        this.vendorInsuranceForm.get('umbrellaRequiredLanguage').value;
                    this._vendorDetails.umbrellaNotes = this.vendorInsuranceForm.get('umbrellaNotes').value;

                    this.formChanged.emit({ vendor: this._vendorDetails });

                    this.vendorInsuranceForm.markAsPristine();
                }
            });

        this.ldFeatureManager
            .isEnabled(FeatureFlag.DisableUtcConversionForAdminFields)
            .pipe(takeUntil(this._cleanup$))
            .subscribe((isEnabled: boolean) => {
                this.disableUtcConversionForAdminFields = isEnabled;
            });
    }

    export(): void {
        this._store.dispatch(actions.exportVendorInsurance({ vendorId: this._vendorDetails.id }));
    }

    ngOnDestroy(): void {
        this._cleanup$.next(true);
        this._cleanup$.complete();
    }
}
