import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, catchError, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { SubmittalsVendorService } from '../../submittals-vendor.service';
import { submittalsVendorActions } from 'src/app/submittals-vendor/store/actions/';
import { SortTypes } from 'src/app/shared/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { flattenFilterPrefix } from 'src/app/shared/utilities';
import * as fromSubmittalsVendorSelectors from '../submittals-vendor.selectors';
import { downloadBlob } from 'src/app/core/utils';
import { formatDate } from '@angular/common';
import { setSubmittalSubStatusFilter } from 'src/app/shared/utilities/submittal-filters';

@Injectable()
export class SubmittalsVendorEffects {
    loadSubmittals$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(
                submittalsVendorActions.SubmittalsVendorActions.componentLoaded,
                submittalsVendorActions.SubmittalsVendorActions.loadSubmittals,
                submittalsVendorActions.SubmittalsVendorActions.setSearchQuery
            ),
            withLatestFrom(
                this.store$.pipe(select(fromSubmittalsVendorSelectors.selectVendorSubmittalsSearchQuery)),
                this.store$.pipe(select(fromSubmittalsVendorSelectors.selectSubmittalVendorLookupsSubStatusesCount))
            ),
            switchMap(([action, query, subStatusCount]) => {
                const sortCondition =
                    query.sort && query.sort.length
                        ? query.sort.map((q) => {
                              return q;
                          })
                        : query.sort;

                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };

                //on first select there are no sort args so it errors out. Need to load default
                let sortArgs = {};
                if (sortCondition.length === 0) {
                    sortArgs = {
                        sortField: 'submitted',
                        sortType: SortTypes.DESC
                    };
                } else {
                    sortArgs = {
                        sortField: sortCondition && sortCondition[0].field,
                        sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                    };
                }

                //For select all we want to also select the submittals w/out substatuses
                setSubmittalSubStatusFilter(query, subStatusCount);

                const matchArgs = flattenFilter(query.filter);

                return this.submittalsVendorService.getVendorSubmittals(pagination, sortArgs, matchArgs).pipe(
                    map((res) =>
                        submittalsVendorActions.SubmittalsVendorActions.loadSubmittalsSuccess({ submittals: res })
                    ),
                    catchError((error) =>
                        of(submittalsVendorActions.SubmittalsVendorActions.loadSubmittalsFail({ error }))
                    )
                );
            })
        )
    );

    loadVendorContacts$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(
                submittalsVendorActions.SubmittalsVendorActions.componentLoaded,
                submittalsVendorActions.SubmittalsVendorActions.loadVendorContacts
            ),
            switchMap(() =>
                this.submittalsVendorService.getVendorContacts().pipe(
                    map((agents) => {
                        let result = agents.filter((val) => val.name.replace(/\s/g, '').length);
                        return submittalsVendorActions.SubmittalsVendorActions.loadVendorContactsSuccess({
                            agents: result
                        });
                    }),
                    catchError((error) =>
                        of(submittalsVendorActions.SubmittalsVendorActions.loadVendorContactsFail({ error }))
                    )
                )
            )
        )
    );

    export$ = createEffect((): any =>
        this.actions$.pipe(
            ofType(
                submittalsVendorActions.SubmittalsVendorActions.exportAllSubmittals,
                submittalsVendorActions.SubmittalsVendorActions.exportSelectedSubmittals
            ),
            withLatestFrom(this.store$.pipe(select(fromSubmittalsVendorSelectors.selectVendorSubmittalsSearchQuery))),
            switchMap(([action, query]) => {
                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };

                const sortCondition =
                    query.sort && query.sort.length
                        ? query.sort.map((q) => {
                              return q;
                          })
                        : query.sort;

                const filter = {
                    ...query.filter,
                    filters: [...query.filter.filters]
                };

                if (action.submittalIds.length) {
                    filter.filters.push({
                        field: 'submittalIds',
                        operator: 'eq',
                        value: action.submittalIds
                    });
                }

                const matchArgs = flattenFilterPrefix(filter);

                const sortArgs = {
                    sortField: sortCondition && sortCondition[0].field,
                    sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                };

                return this.submittalsVendorService.export(pagination, sortArgs, matchArgs).pipe(
                    tap((blob) => {
                        const formattedDate = formatDate(new Date(), 'yyyy-MM-dd HH_mm_ss', 'en-US');
                        downloadBlob(blob, 'SubmittalVendors - ' + formattedDate + '.xlsx');
                    }),
                    map((blob) => submittalsVendorActions.SubmittalsVendorActions.exportAllSubmittalsSuccess()),
                    catchError((error) =>
                        of(submittalsVendorActions.SubmittalsVendorActions.exportAllSubmittalsFail({ error }))
                    )
                );
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly submittalsVendorService: SubmittalsVendorService,
        private readonly store$: Store<{}>
    ) {}
}
