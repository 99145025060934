import { loadViewRequirementDetailsSuccess } from './../actions/vendor-candidate-details.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ToasterService, ToasterStatus } from 'src/app/core/services';
import { downloadBlob } from 'src/app/core/utils';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models';
import { ContractRequirements } from 'src/app/shared/models/candidate';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ShiftsService } from 'src/app/shifts/services/shifts.service';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { FileService } from '../../services/file.service';
import { VendorCandidateContractService } from '../../services/contract.service';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { Router } from '@angular/router';
import { ViewRequirementDetailsDialogComponent } from 'src/app/shared/components/view-requirement-details-dialog/view-requirement-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class VendorCandidateDetailsEffects {
    loadVendorCandidate$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadVendorCandidate),
            switchMap((action) => {
                return this._candidateService.getVendorCandidate(action.candidateId).pipe(
                    map((candidate) => actions.loadVendorCandidateSuccess({ candidate })),
                    catchError((error: unknown) => of(actions.loadVendorCandidateFailure({ error })))
                );
            })
        );
    });

    getHasContractAtPerDiemHospital$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getHasContractAtPerDiemHospital),
            switchMap((action) => {
                return this._candidateService.getHasContractAtPerDiemHospital(action.candidateId).pipe(
                    map((hasContract) => actions.getHasContractAtPerDiemHospitalSuccess({ hasContract })),
                    catchError((error: unknown) => of(actions.getHasContractAtPerDiemHospitalFailure({ error })))
                );
            })
        );
    });

    exportContractRequirements$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.exportContractRequirements),
            switchMap((action) => {
                return this._vendorCandidateContractService.getContractRequirementsForExport(action.candidateId).pipe(
                    tap((blob) => {
                        const formattedDate = formatDate(new Date(), 'yyyy-MM-dd-HH_mm_ss', 'en-US');
                        downloadBlob(blob, `Compliance Documents-${action.candidateName}-${formattedDate}.xlsx`);
                    }),
                    map((result) => actions.exportContractRequirementsSuccess({ result })),
                    catchError((error: unknown) => of(actions.exportContractRequirementsFail({ error })))
                );
            })
        );
    });

    getContractRequirements$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getContractRequirements),
            switchMap((action) => {
                return this._vendorCandidateContractService.getContractRequirements(action.candidateId).pipe(
                    map((contracts: ContractRequirements) => {
                        return actions.getContractRequirementsSuccess({ contracts });
                    }),
                    catchError((error: unknown) => of(actions.getContractRequirementsFail({ error })))
                );
            })
        );
    });

    loadVendorCandidateFileList$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadVendorCandidateFileList),
            mergeMap((action) =>
                this._fileService.getVendorCandidateFiles(action.candidateId, action.request).pipe(
                    map((response) =>
                        actions.loadVendorCandidateFileListSuccess({
                            response,
                            candidateId: action.candidateId
                        })
                    ),
                    catchError((error: unknown) => {
                        if (error && typeof error === 'object' && 'error' in error) {
                            this._dialogService.openSnackBarErrorFromErrorResponse(error as HttpErrorResponse);
                        }
                        return of(
                            actions.loadVendorCandidateFileListFailure({
                                error
                            })
                        );
                    })
                )
            )
        );
    });

    deleteVendorCandidateFiles$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.deleteVendorCandidateFiles),
            exhaustMap((action) => {
                return this._fileService.deleteFiles(action.candidateId, action.fileIds).pipe(
                    map(() =>
                        actions.deleteVendorCandidateFilesSuccess({
                            success: true
                        })
                    ),
                    catchError((error: unknown) => {
                        if (error && typeof error === 'object' && 'errorMessage' in error) {
                            const knownError = error as { errorMessage: string };
                            this._toasterService.fail(knownError.errorMessage);
                        }
                        return of(null);
                    })
                );
            })
        );
    });

    getCurrentShiftList$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadCurrentShiftList, actions.setCurrentShiftListSearchQuery),
            withLatestFrom(this.store.select(selectors.selectCurrentShiftListQuery)),
            switchMap(([, query]) => {
                const queryArgs = this.getQueryArgs(query);
                return this._shiftsService
                    .getCurrentShifts(queryArgs.pagination, queryArgs.sort, queryArgs.matchArgs)
                    .pipe(
                        map((response) =>
                            actions.loadCurrentShiftListSuccess({
                                shifts: response
                            })
                        ),
                        catchError((error: unknown) =>
                            of(
                                actions.loadCurrentShiftListFailure({
                                    error
                                })
                            )
                        )
                    );
            })
        );
    });

    getAvailableShiftList$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadAvailableShiftList, actions.setAvailableShiftListSearchQuery),
            withLatestFrom(this.store.select(selectors.selectAvailableShiftListQuery)),
            switchMap(([, query]) => {
                const queryArgs = this.getQueryArgs(query);
                return this._shiftsService
                    .getAvailableShifts(queryArgs.pagination, queryArgs.sort, queryArgs.matchArgs)
                    .pipe(
                        map((response) =>
                            actions.loadAvailableShiftListSuccess({
                                shifts: response
                            })
                        ),
                        catchError((error: unknown) =>
                            of(
                                actions.loadAvailableShiftListFailure({
                                    error
                                })
                            )
                        )
                    );
            })
        );
    });

    checkCandidateFiles$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.checkCandidateFiles),
            exhaustMap((action) => {
                return this._candidateService.checkCandidateFiles(action.candidateId).pipe(
                    map((result) =>
                        actions.checkCandidateFilesSuccess({
                            success: result,
                            jobId: action.jobId,
                            accountManagerId: action.accountManagerId,
                            candidateId: action.candidateId,
                            submittalId: action.submittalId
                        })
                    ),
                    catchError((error) => of(actions.checkCandidateFilesFailure({ error })))
                );
            })
        );
    });

    checkCandidateFilesSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.checkCandidateFilesSuccess),
                tap((result) => {
                    if (!result.success) {
                        return;
                    }

                    this._router.navigate([`/vendor/submittals/${result.submittalId}`], {
                        queryParams: {
                            jobId: result.jobId,
                            candidateId: result.candidateId,
                            accountManagerId: result.accountManagerId
                        }
                    });
                })
            );
        },
        { dispatch: false }
    );

    loadViewRequirementDetails$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadViewRequirementDetails),
            switchMap((action) => {
                return this._vendorCandidateContractService
                    .getDocumentRequirementsByContractId(action.docTypeId, action.contractId, true, false)
                    .pipe(
                        map((response) =>
                            actions.loadViewRequirementDetailsSuccess({
                                requirements: response,
                                docTitle: action.title
                            })
                        ),
                        catchError((error: unknown) =>
                            of(
                                actions.loadViewRequirementDetailsFailure({
                                    error
                                })
                            )
                        )
                    );
            })
        );
    });

    loadViewRequirementDetailsSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(loadViewRequirementDetailsSuccess),
                tap((action) => {
                    this._matDialog.open(ViewRequirementDetailsDialogComponent, {
                        width: '500px',
                        hasBackdrop: true,
                        data: {
                            title: action.docTitle,
                            requirements: action.requirements
                        }
                    });
                })
            );
        },
        { dispatch: false }
    );

    loadViewRequirementDetailsFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.loadViewRequirementDetailsFailure),
                tap((action) => {
                    this._matDialog.open(ViewRequirementDetailsDialogComponent, {
                        width: '500px',
                        hasBackdrop: true,
                        data: {
                            title: 'No further details for this requirement.'
                        }
                    });
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly store: Store,
        private readonly _candidateService: CandidateService,
        private readonly _fileService: FileService,
        private readonly _dialogService: DialogService,
        private readonly _toasterService: ToasterService,
        private readonly _shiftsService: ShiftsService,
        private readonly _vendorCandidateContractService: VendorCandidateContractService,
        private readonly _router: Router,
        private readonly _matDialog: MatDialog
    ) {}

    private getQueryArgs(query: GridSearchQuery): { sort; pagination; matchArgs } {
        const pagination = {
            pageSize: query.take,
            skip: query.skip
        };

        const sortArgs = {
            sortField: query.sort && query.sort[0].field,
            sortType: query.sort && (query.sort[0].dir as SortTypes)
        };

        const matchArgs = flattenFilter(query.filter);

        return { sort: sortArgs, pagination, matchArgs };
    }
}
