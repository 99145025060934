import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DomainService } from 'src/app/shared/services/domain.service';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../utils';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { CoreService } from '../services/core.service';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { freeAccessItems, UserIdentity } from 'src/app/shared/models';
import { Observable, of } from 'rxjs';
import { UserProfile } from 'src/app/shared/models/account/user-profile.model';
import { Store } from '@ngrx/store';
import * as fromCore from 'src/app/core/state';
import * as coreActions from 'src/app/core/state/actions';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

@Component({
    selector: 'ayac-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild('navbarCollapse', { static: false }) navbarCollapse: ElementRef<HTMLElement>;

    userSignedIn = false;
    isCorpBtnVisible = false;
    isLoginPage = false;
    showTopMenu = true;
    updatePasswordErrorMessage = '';
    domain: string;
    identity: UserIdentity;
    serverName: string;
    showQuestions = false;
    connectUrl!: string;
    env: string;
    profiles$: Observable<UserProfile[]> = of([]);
    profilesExist$: Observable<boolean>;
    featureFlag = FeatureFlag;
    nonVendorLogin = true;

    constructor(
        @Inject('Window') private readonly _window: Window,
        @Inject(APP_CONFIG) private readonly _settings: Settings,
        private readonly _router: Router,
        private readonly _authService: AuthService,
        private readonly _coreService: CoreService,
        private readonly _domainService: DomainService,
        private readonly _identityService: IdentityService,
        private readonly _store: Store<{}>,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.serverName = this._settings.SERVER_NAME;
    }

    ngOnInit() {
        this.env = this._domainService.environment();
        this.init();
    }

    init(): void {
        this._store
            .select(fromCore.selectIsAuthenticated)
            .pipe(takeUntil(this.d$))
            .subscribe((isAuth) => {
                if (isAuth) {
                    this.runChecks();
                    this.setIdentity();
                }
            });
        this.runChecks();
        this.setIdentity();
        this.handleRouteChanges();
        this.handleAuthEvents();

        this._store.dispatch(new coreActions.LoadUserProfiles());
        this.profiles$ = this._store.select(fromCore.selectUserProfiles);
        this.profilesExist$ = this._store.select(fromCore.selectProfilesExist);
    }

    runChecks(): void {
        this.userSignedIn = this._identityService.isSignedIn();
        this.isCorpBtnVisible = this.identity?.type === 'admin';
        this.showQuestions = this._domainService.check('QUESTION_SHOW');
        this.connectUrl = this._domainService.getValue('CONNECT_URL');
        this.checkHideTopMenu();
        this.checkLoginPage();
        this.checkForVendorType();
    }

    handleAuthEvents(): void {
        this._authService.OAuthSignedIn$.asObservable()
            .pipe(skip(1), takeUntil(this.d$))
            .subscribe((signedIn) => {
                this.userSignedIn = signedIn;
                if (!signedIn) {
                    this.handleSignOut();
                }
            });
    }

    handleRouteChanges(): void {
        this._router.events
            .pipe(
                filter((e): e is NavigationEnd => e instanceof NavigationEnd),
                takeUntil(this.d$)
            )
            .subscribe(() => {
                this.runChecks();
                this.setIdentity();
            });
    }

    setIdentity(): void {
        this.identity = this._identityService.userIdentity;
    }

    checkHideTopMenu(): void {
        this.showTopMenu = true;
        this._router.url.split('/').forEach((part) => {
            const partWithoutQueryParams = part.split('?');

            if (freeAccessItems.includes(part) || freeAccessItems.includes(partWithoutQueryParams[0])) {
                this.showTopMenu = false;
            }
            if (part === 'signin' || part === 'help') {
                this.showTopMenu = true;
            }
        });
    }

    checkLoginPage(): void {
        this.isLoginPage = false;
        this._router.url.split('/').forEach((part) => {
            if (part === 'signin' || part === 'resetpassword' || part === 'externalAccess') {
                this.isLoginPage = true;
            }
        });
    }

    checkForVendorType(): void {
        const loginType = this._identityService.type;
        this.nonVendorLogin = loginType === 'vendor' ? false : true;
    }

    changePassword(): void {
        this._coreService.openChangePasswordDialog();
    }

    signOut(): void {
        this.handleSignOut();
        this._identityService.signOut();
    }

    handleSignOut(): void {
        this.hideMenu();
        this.userSignedIn = false;
    }

    goToHelpPage(event: Event): void {
        event.preventDefault();
        this._router.navigate(['help']);
    }

    goToLogin(): void {
        this._router.navigate(['signin']);
    }

    goToCorporateDirectory(): void {
        this.hideMenu();
        this._authService.openCorpDirectory();
    }

    hideMenu(): void {
        if (this.navbarCollapse?.nativeElement?.classList?.contains('in')) {
            this.navbarCollapse.nativeElement.classList.remove('in');
        }
    }

    changeProfile(userId: string) {
        this._identityService.changeProfile(userId);
    }
}
