import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ShiftsSettingsService } from '../services/shifts-settings.service';
import { ShiftReleaseSetting, ShiftReleaseSettingEdit, ShiftReleaseSettingTemplate } from 'src/app/shifts/models';
import * as dayjs from 'dayjs';
import { DateHelper } from 'src/app/shared/utilities';
import { MatRadioChange } from '@angular/material/radio';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import * as moment from 'moment';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as shiftsActions from 'src/app/shifts/store/actions';
import { ShiftsAuthService } from '../services/shifts-auth.service';

export enum ScheduleTypes {
    settings = 0,
    internal,
    agency
}
@Component({
    selector: 'ayac-shift-resource-fulfillment-scheduler',
    templateUrl: './shift-resource-fulfillment-scheduler.component.html',
    styleUrls: ['./shift-resource-fulfillment-scheduler.component.scss']
})
export class ShiftResourceFulfillmentSchedulerComponent
    extends UnsubscribeOnDestroy
    implements OnDestroy, OnChanges, OnInit
{
    @Input() settings: ShiftReleaseSetting[];
    @Input() shiftReleaseTemplate: ShiftReleaseSettingTemplate;
    @Input() hasPastDates: boolean = false;
    @Input() isInternal: boolean;
    @Input() isEdit: boolean;
    @Input() shiftReleaseSettingId?: number;
    @Input() allowBookCandidate: boolean = false;
    @Input() bonusSelected: boolean;
    @Output() onBookCandidates = new EventEmitter();
    scheduleTypes = ScheduleTypes;
    globalSettingsFormat: string[] = [];
    releaseImmediatelyFormat: string;
    featureFlags = FeatureFlag;
    bonusAssignmentPermission = false;

    form = new FormGroup({
        scheduleType: new FormControl<ScheduleTypes>(ScheduleTypes.settings),
        templateId: new FormControl<number>(0),
        showBookCandidate: new FormControl<boolean>(false)
    });

    constructor(
        private readonly shiftSettingsService: ShiftsSettingsService,
        private readonly featureManager: LDFeatureManager,
        private readonly store: Store,
        private readonly shiftAuthService: ShiftsAuthService
    ) {
        super();
    }

    ngOnInit(): void {
        this.updateSelectedScheduleType();

        this.featureManager
            .isEnabled(FeatureFlag.ShiftBonusRatesForIRP)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled) => {
                this.bonusAssignmentPermission = isEnabled && this.shiftAuthService.canAddEditShiftBonus();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.shiftReleaseTemplate) {
            this.updateShiftReleaseSettingFormat();

            this.form.patchValue({
                templateId: this.shiftReleaseTemplate.shiftReleaseSettingTemplateId
            });
        }

        if (changes.settings) {
            this.updateShiftReleaseSettingFormat();
            this.updateReleaseImmediatelyFormat();
        }

        if (changes.shiftReleaseTemplate || changes.isInternal) {
            this.updateSelectedScheduleType();
        }
    }

    updateShiftReleaseSettingFormat() {
        this.globalSettingsFormat = this.shiftSettingsService
            .disassembleShiftSetting(this.shiftReleaseTemplate)
            .map((m) => {
                let value: string;
                let timeValue: string;

                if (m.isImmediately) {
                    value = 'Release immediately';
                } else if (m.days > 0) {
                    timeValue = this.settings[0]?.shiftDate
                        ? `, at ${moment(this.settings[0].shiftDate).format('h:mm A')}`
                        : '';
                    value = `${m.days} Day${m.days > 1 ? 's' : ''} before${timeValue}`;
                } else {
                    value = 'Not Scheduled';
                }

                return `${m.label} = ${value}`;
            });
    }

    updateReleaseImmediatelyFormat(): void {
        const today = new Date();
        const releaseImmediately = new Set();

        for (const setting of this.settings) {
            const configs = this.shiftSettingsService.disassembleShiftSetting(setting);
            for (const config of configs) {
                const configDate = dayjs(setting.shiftDate).subtract(config.days, 'days').toDate();
                if (DateHelper.isBeforeDay(configDate, today)) {
                    releaseImmediately.add(config.label);
                }
            }
        }

        this.releaseImmediatelyFormat = Array.from(releaseImmediately).join(', ');
    }

    onScheduleTypeChanged(event: MatRadioChange) {
        this.store.dispatch(shiftsActions.setScheduleType({ scheduleType: this.scheduleType }));
        if (event.value !== ScheduleTypes.internal) {
            this.form.patchValue({
                showBookCandidate: false
            });
        }
    }

    toggleShowCandidates() {
        this.form.patchValue({
            showBookCandidate: true
        });

        this.onBookCandidates.emit();
    }

    get scheduleType(): ScheduleTypes {
        return this.form.get('scheduleType').value;
    }

    get formData(): ShiftReleaseSettingEdit {
        const formValue = this.form.value;

        switch (formValue.scheduleType) {
            case ScheduleTypes.agency: {
                return {
                    shiftReleaseSetting: [],
                    isInternalPool: false,
                    shiftReleaseTemplateId: null
                };
            }
            case ScheduleTypes.internal: {
                return {
                    shiftReleaseSetting: [],
                    isInternalPool: true,
                    shiftReleaseTemplateId: null
                };
            }
            case ScheduleTypes.settings: {
                return {
                    shiftReleaseSetting: this.settings,
                    isInternalPool: true,
                    shiftReleaseTemplateId: formValue.templateId
                };
            }
            default:
                return {
                    shiftReleaseSetting: [],
                    isInternalPool: false,
                    shiftReleaseTemplateId: null
                };
        }
    }

    updateSelectedScheduleType(): void {
        if (!this.isEdit) {
            this.form.patchValue({
                scheduleType: ScheduleTypes.settings,
                showBookCandidate: false
            });
        } else if (!this.isInternal) {
            this.form.patchValue({
                scheduleType: ScheduleTypes.agency,
                showBookCandidate: false
            });
        } else if (this.shiftReleaseSettingId && this.shiftReleaseSettingId > 0) {
            this.form.patchValue({
                scheduleType: ScheduleTypes.settings,
                showBookCandidate: false
            });
        } else {
            this.form.patchValue({
                scheduleType: ScheduleTypes.internal,
                showBookCandidate: true
            });
        }
        this.store.dispatch(shiftsActions.setScheduleType({ scheduleType: this.scheduleType }));
    }
}
