import { createAction, props } from '@ngrx/store';
import { ListItem } from 'src/app/shared/models/list-item';

export const loadSubmittalStages = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL STAGES] Load Vendor Submittal Stages'
);

export const loadSubmittalStagesSuccess = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Stages Load Success',
    props<{ stages: ListItem[] }>()
);

export const loadSubmittalStagesFail = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Stages Load Failure',
    props<{ error: any }>()
);

export const loadSubmittalStageReasons = createAction(
    '[VENDOR SUBMITTALS LOAD SUBMITTAL STAGE REASONS] Load Vendor Submittal Stage Reasons',
    props<{ showVendor?: boolean }>()
);

export const loadSubmittalStageReasonsSuccess = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Stage Reasons Load Success',
    props<{ stageReasons: ListItem[] }>()
);

export const loadSubmittalStageReasonsFail = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Stage Reasons Load Failure',
    props<{ error: any }>()
);

export const loadSubmittalsLookupsStatuses = createAction(
    '[VENDOR SUBMITTALS LOAD LOOKUP STATUSES] Vendor Submittal Statuses Lookup'
);

export const loadSubmittalsLookupsStatusesSuccess = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Statuses Lookup Success',
    props<{ statuses: ListItem[] }>()
);

export const loadSubmittalsLookupsStatusesFail = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Statuses Lookup Failure',
    props<{ error: unknown }>()
);

export const loadSubmittalsLookupsSubStatuses = createAction(
    '[VENDOR SUBMITTALS LOAD LOOKUP SUBSTATUSES] Vendor Submittal Sub Statuses Lookup',
    props<{ statusId: number }>()
);

export const loadSubmittalsLookupsSubStatusesSuccess = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Sub Statuses Lookup Success',
    props<{ subStatuses: ListItem[] }>()
);

export const loadSubmittalsLookupsSubStatusesFail = createAction(
    '[VENDOR SUBMITTALS SERVICE API] Vendor Submittal Sub Statuses Lookup Failure',
    props<{ error: unknown }>()
);
