import { DomainConfiguration } from 'src/app/shared/models';

export const configurations: DomainConfiguration[] = [
    {
        environment: null,
        key: 'ayaconnect.com',
        value: 'AYA'
    },
    {
        environment: null,
        key: 'lotusone.com',
        value: 'ONE'
    },
    {
        environment: null,
        key: 'hcselect.com',
        value: 'HCS'
    },
    {
        environment: null,
        key: 'lotusconnect.com',
        value: 'LTS'
    },
    {
        environment: null,
        key: 'shifts.com',
        value: 'SHFT'
    },
    {
        environment: null,
        key: 'nyu.ayaconnect.com',
        value: 'NYU'
    },

    {
        environment: 'AYA',
        key: 'TITLE',
        value: 'Aya Connect'
    },
    {
        environment: 'ONE',
        key: 'TITLE',
        value: 'LotusOne'
    },
    {
        environment: 'HCS',
        key: 'TITLE',
        value: 'Client Portal'
    },
    {
        environment: 'LTS',
        key: 'TITLE',
        value: 'Client Portal'
    },
    {
        environment: 'SHFT',
        key: 'TITLE',
        value: 'Shifts'
    },

    {
        environment: 'AYA',
        key: 'VENDOR_MENU_NAME',
        value: 'Aya Connect'
    },
    {
        environment: 'ONE',
        key: 'VENDOR_MENU_NAME',
        value: 'LotusOne'
    },
    {
        environment: 'HCS',
        key: 'VENDOR_MENU_NAME',
        value: 'Client'
    },
    {
        environment: 'LTS',
        key: 'VENDOR_MENU_NAME',
        value: 'Connect'
    },
    {
        environment: 'SHFT',
        key: 'VENDOR_MENU_NAME',
        value: 'Vendor'
    },

    {
        environment: 'AYA',
        key: 'CLIENT_MENU_NAME',
        value: 'Aya Connect'
    },
    {
        environment: 'ONE',
        key: 'CLIENT_MENU_NAME',
        value: 'LotusOne'
    },
    {
        environment: 'HCS',
        key: 'CLIENT_MENU_NAME',
        value: 'Client'
    },
    {
        environment: 'LTS',
        key: 'CLIENT_MENU_NAME',
        value: 'Connect'
    },
    {
        environment: 'SHFT',
        key: 'CLIENT_MENU_NAME',
        value: 'Client'
    },

    {
        environment: 'AYA',
        key: 'CLINICAL_MENU_NAME',
        value: 'Clinical'
    },
    {
        environment: 'ONE',
        key: 'CLINICAL_MENU_NAME',
        value: 'Clinical'
    },
    {
        environment: 'HCS',
        key: 'CLINICAL_MENU_NAME',
        value: 'HCSelect Clinical'
    },
    {
        environment: 'LTS',
        key: 'CLINICAL_MENU_NAME',
        value: 'Lotus Clinical'
    },
    {
        environment: 'SHFT',
        key: 'CLINICAL_MENU_NAME',
        value: 'Clinical'
    },

    {
        environment: 'AYA',
        key: 'PORTAL_NAME',
        value: 'AYA Portal'
    },
    {
        environment: 'ONE',
        key: 'PORTAL_NAME',
        value: 'LotusOne Portal'
    },
    {
        environment: 'HCS',
        key: 'PORTAL_NAME',
        value: 'Client Portal'
    },
    {
        environment: 'LTS',
        key: 'PORTAL_NAME',
        value: 'Client Portal'
    },
    {
        environment: 'SHFT',
        key: 'PORTAL_NAME',
        value: 'SHFT Portal'
    },

    {
        environment: 'AYA',
        key: 'SHORT_PORTAL_NAME',
        value: 'AYAPortal'
    },
    {
        environment: 'ONE',
        key: 'SHORT_PORTAL_NAME',
        value: 'ONEPortal'
    },
    {
        environment: 'HCS',
        key: 'SHORT_PORTAL_NAME',
        value: 'ClientPortal'
    },
    {
        environment: 'LTS',
        key: 'SHORT_PORTAL_NAME',
        value: 'ClientPortal'
    },
    {
        environment: 'SHFT',
        key: 'SHORT_PORTAL_NAME',
        value: 'SHFTPortal'
    },

    {
        environment: 'AYA',
        key: 'STYLES_AND_META',
        value: [
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: '/content/brand/favicon-32x32.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '16x16',
                    href: '/content/brand/favicon-16x16.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'apple-touch-icon',
                    sizes: '180x180',
                    href: '/content/brand/apple-touch-icon.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'manifest',
                    href: '/assets/manifest.json'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'mask-icon',
                    href: '/content/brand/safari-pinned-tab.svg',
                    color: '#f07622'
                }
            },
            {
                key: 'meta',
                value: {
                    name: 'msapplication-TileColor',
                    content: '#da532c'
                }
            },
            {
                key: 'meta',
                value: {
                    name: 'msapplication-config',
                    content: '/browserconfig.xml'
                }
            },
            {
                key: 'meta',
                value: {
                    name: 'theme-color',
                    content: '#ffffff'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'aya.css'
                }
            }
        ]
    },
    {
        environment: 'ONE',
        key: 'STYLES_AND_META',
        value: [
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'one.css'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '16x16',
                    href: '/content/brand/lotus-one-16x16.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: '/content/brand/lotus-one-32x32.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '192x192',
                    href: '/content/brand/lotus-one-192x192.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '512x512',
                    href: '/content/brand/lotus-one-512x512.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'apple-touch-icon',
                    sizes: '180x180',
                    href: '/content/brand/lotus-one-apple-touch-icon.png'
                }
            }
        ]
    },
    {
        environment: 'HCS',
        key: 'STYLES_AND_META',
        value: [
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'content/hccustomtheme.css'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'content/hcstyles2.css'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'content/hcstyles.css'
                }
            }
        ]
    },
    {
        environment: 'LTS',
        key: 'STYLES_AND_META',
        value: [
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'lotus.css'
                }
            }
        ]
    },
    {
        environment: 'SHFT',
        key: 'STYLES_AND_META',
        value: [
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'shifts.css'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: '/content/brand/shifts-favicon-32x32.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '192x192',
                    href: '/content/brand/shifts-favicon-192x192.png'
                }
            },
            {
                key: 'link',
                value: {
                    rel: 'apple-touch-icon',
                    sizes: '180x180',
                    href: '/content/brand/shifts-favicon-180x180.png'
                }
            }
        ]
    },
    {
        environment: 'NYU',
        key: 'STYLES_AND_META',
        value: [
            {
                key: 'link',
                value: {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: 'nyu.css'
                }
            }
        ]
    },
    {
        environment: 'AYA',
        key: 'FORMS',
        value: {
            title: 'Aya Forms',
            path: 'content/ayaforms/',
            forms: [
                {
                    file: 'Candidate Application.pdf',
                    name: 'Candidate Application',
                    primary: true
                },
                {
                    file: 'Reference Form.pdf',
                    name: 'Candidate Reference Form'
                },
                {
                    file: '7 Year Work History Form.pdf',
                    name: '7 Year Work History Form'
                },
                {
                    file: 'Candidate Application with Reference Form and Work History.pdf',
                    name: 'Candidate Application with Reference Form and Work History'
                },
                {
                    file: 'Aya - Ultrasound Technologist-Sonographer Job Description.pdf',
                    name: 'Aya - Ultrasound Technologist-Sonographer Job Description'
                },
                {
                    file: 'Aya Healthcare Fit Test Card.pdf',
                    name: 'Aya Healthcare Fit Test Card'
                },
                {
                    file: 'Aya Latex Allergy Form.pdf',
                    name: 'Aya Latex Allergy Form'
                },
                {
                    file: 'Aya Medical Release.pdf',
                    name: 'Aya Medical Release'
                },
                {
                    file: "Aya Physician's Statement.pdf",
                    name: "Aya Physician's Statement"
                },
                {
                    file: 'Aya Residency Declaration Form.pdf',
                    name: 'Aya Residency Declaration Form'
                },
                {
                    file: 'Aya RN Job Description.pdf',
                    name: 'Aya RN Job Description'
                },
                {
                    file: 'Aya Tdap Declination Form.pdf',
                    name: 'Aya Tdap Declination Form'
                },
                {
                    file: 'CNA Job Description.pdf',
                    name: 'CNA Job Description'
                },
                {
                    file: 'Hep B Form.pdf',
                    name: 'Hep B Form'
                },
                {
                    file: 'HIPAA Safety Manual Acknowledgement 2015.pdf',
                    name: 'HIPAA Safety Manual Acknowledgement 2015'
                },
                {
                    file: 'ORSurgTech Job Description.pdf',
                    name: 'ORSurgTech Job Description'
                },
                {
                    file: 'OT Job Description Revised.pdf',
                    name: 'OT Job Description Revised'
                },
                {
                    file: 'Physical Therapist Aides_Physical Therapist Assistants - Job Description.pdf',
                    name: 'Physical Therapist Aides_Physical Therapist Assistants - Job Description'
                },
                {
                    file: 'Respiratory Therapist - Job Description.pdf',
                    name: 'Respiratory Therapist - Job Description'
                },
                {
                    file: 'TB Screening Form.pdf',
                    name: 'TB Screening Form'
                }
            ]
        }
    },
    {
        environment: 'ONE',
        key: 'FORMS',
        value: {}
    },
    {
        environment: 'HCS',
        key: 'FORMS',
        value: {
            title: 'HC Select Forms',
            path: 'content/hcsforms/',
            forms: [
                {
                    file: 'Candidate Application.pdf',
                    name: 'Candidate Application',
                    primary: true
                },
                {
                    file: 'Reference Form.pdf',
                    name: 'Candidate Reference Form'
                },
                {
                    file: '7 Year Work History Form.pdf',
                    name: '7 Year Work History Form'
                },
                {
                    file: 'Candidate Application with Reference Form and Work History Rev051818jr.pdf',
                    name: 'Candidate Application with Reference Form and Work History'
                }
            ]
        }
    },
    {
        environment: 'LTS',
        key: 'FORMS',
        value: {
            title: 'Lotus Forms',
            path: 'content/ltsforms/',
            forms: []
        }
    },
    {
        environment: 'SHFT',
        key: 'FORMS',
        value: {
            title: 'Lotus Forms',
            path: 'content/ltsforms/',
            forms: []
        }
    },

    {
        environment: 'AYA',
        key: 'SUBMITTALS_SUBJECT_TXT',
        value: 'Aya Connect'
    },
    {
        environment: 'ONE',
        key: 'SUBMITTALS_SUBJECT_TXT',
        value: 'LotusOne'
    },
    {
        environment: 'HCS',
        key: 'SUBMITTALS_SUBJECT_TXT',
        value: 'Healthcare Select'
    },
    {
        environment: 'LTS',
        key: 'SUBMITTALS_SUBJECT_TXT',
        value: 'Lotus'
    },
    {
        environment: 'SHFT',
        key: 'SUBMITTALS_SUBJECT_TXT',
        value: 'SHFT'
    },

    {
        environment: 'AYA',
        key: 'JOB_VENDOR_BILL',
        value: true
    },
    {
        environment: 'HCS',
        key: 'JOB_VENDOR_BILL',
        value: false
    },
    {
        environment: 'LTS',
        key: 'JOB_VENDOR_BILL',
        value: false
    },
    {
        environment: 'SHFT',
        key: 'JOB_VENDOR_BILL',
        value: false
    },

    {
        environment: 'AYA',
        key: 'FACILITIES_VENDOR_OFFER',
        value: true
    },
    {
        environment: 'ONE',
        key: 'FACILITIES_VENDOR_OFFER',
        value: true
    },
    {
        environment: 'HCS',
        key: 'FACILITIES_VENDOR_OFFER',
        value: true
    },
    {
        environment: 'LTS',
        key: 'FACILITIES_VENDOR_OFFER',
        value: true
    },
    {
        environment: 'SHFT',
        key: 'FACILITIES_VENDOR_OFFER',
        value: true
    },

    {
        environment: 'AYA',
        key: 'AGENCY_QUESTIONS_EMAIL',
        value: 'APNConnectSupport@ayahealthcare.com'
    },
    {
        environment: 'ONE',
        key: 'AGENCY_QUESTIONS_EMAIL',
        value: 'APNConnectSupport@lotusone.com'
    },
    {
        environment: 'HCS',
        key: 'AGENCY_QUESTIONS_EMAIL',
        value: 'supplierquestions@hcselect.com'
    },
    {
        environment: 'LTS',
        key: 'AGENCY_QUESTIONS_EMAIL',
        value: 'support@lotusworkforce.com'
    },
    {
        environment: 'SHFT',
        key: 'AGENCY_QUESTIONS_EMAIL',
        value: 'APNConnectSupport@shifts.com'
    },

    {
        environment: 'AYA',
        key: 'FACILITIES_ACCEPTED_ONLY',
        value: false
    },
    {
        environment: 'ONE',
        key: 'FACILITIES_ACCEPTED_ONLY',
        value: false
    },
    {
        environment: 'HCS',
        key: 'FACILITIES_ACCEPTED_ONLY',
        value: true
    },
    {
        environment: 'LTS',
        key: 'FACILITIES_ACCEPTED_ONLY',
        value: true
    },
    {
        environment: 'SHFT',
        key: 'FACILITIES_ACCEPTED_ONLY',
        value: true
    },

    {
        environment: 'AYA',
        key: 'NOTE_LABEL',
        value: 'Aya Note'
    },
    {
        environment: 'ONE',
        key: 'NOTE_LABEL',
        value: 'LotusOne Note'
    },
    {
        environment: 'HCS',
        key: 'NOTE_LABEL',
        value: 'HCS Note'
    },
    {
        environment: 'LTS',
        key: 'NOTE_LABEL',
        value: 'LTS Note'
    },
    {
        environment: 'SHFT',
        key: 'NOTE_LABEL',
        value: 'SHFT Note'
    },

    {
        environment: 'AYA',
        key: 'VENDOR_FACILITIES_EMAIL',
        value: 'agencyquestions@ayahealthcare.com'
    },
    {
        environment: 'ONE',
        key: 'VENDOR_FACILITIES_EMAIL',
        value: 'agencyquestions@lotusone.com'
    },
    {
        environment: 'HCS',
        key: 'VENDOR_FACILITIES_EMAIL',
        value: 'contracts@hcselect.com'
    },
    {
        environment: 'LTS',
        key: 'VENDOR_FACILITIES_EMAIL',
        value: 'contracts@lotusconnect.com'
    },
    {
        environment: 'LTS',
        key: 'VENDOR_FACILITIES_EMAIL',
        value: 'agencyquestions@shifts.com'
    },

    {
        environment: 'AYA',
        key: 'FOOTER_COPY',
        value: 'Aya Healthcare, Inc. 2015-%current_date_year%. \nAll rights reserved.'
    },
    {
        environment: 'ONE',
        key: 'FOOTER_COPY',
        value: 'Aya Healthcare, Inc. 2015-%current_date_year%. \nAll rights reserved.'
    },
    {
        environment: 'HCS',
        key: 'FOOTER_COPY',
        value: 'Healthcare Select 2017-%current_date_year%. \nAll rights reserved.'
    },
    {
        environment: 'LTS',
        key: 'FOOTER_COPY',
        value: 'Lotus 2018-%current_date_year%. \nAll rights reserved.'
    },
    {
        environment: 'SHFT',
        key: 'FOOTER_COPY',
        value: 'Aya Healthcare, Inc. 2015-%current_date_year%. \nAll rights reserved.'
    },

    {
        environment: 'AYA',
        key: 'CONNECT_URL',
        value: 'http://www.ayahealthcare.com'
    },
    {
        environment: 'ONE',
        key: 'CONNECT_URL',
        value: 'http://www.lotusone.com'
    },
    {
        environment: 'HCS',
        key: 'CONNECT_URL',
        value: 'http://www.hcselect.com'
    },
    {
        environment: 'LTS',
        key: 'CONNECT_URL',
        value: 'http://www.lotusconnect.com'
    },
    {
        environment: 'SHFT',
        key: 'CONNECT_URL',
        value: 'http://www.ayahealthcare.com'
    },

    {
        environment: 'AYA',
        key: 'PRIVACY_URL',
        value: 'https://www.ayaconnect.com/privacy-policy/'
    },
    {
        environment: 'ONE',
        key: 'PRIVACY_URL',
        value: 'https://www.lotusone.com/privacy-policy/'
    },
    {
        environment: 'HCS',
        key: 'PRIVACY_URL',
        value: '/content/privacy/HC_SelectPrivacyPolicy.pdf'
    },
    {
        environment: 'LTS',
        key: 'PRIVACY_URL',
        value: 'https://www.lotusconnect.com/privacy-policy/'
    },
    {
        environment: 'SHFT',
        key: 'PRIVACY_URL',
        value: 'https://www.shifts.com/privacy-policy/'
    },

    {
        environment: 'AYA',
        key: 'TERMS_OF_USE_URL',
        value: 'https://www.ayaconnect.com/terms-of-use/'
    },
    {
        environment: 'ONE',
        key: 'TERMS_OF_USE_URL',
        value: 'https://www.lotusone.com/terms-of-use/'
    },
    {
        environment: 'HCS',
        key: 'TERMS_OF_USE_URL',
        value: '/content/privacy/HC_SelectFinalTermsOfUse.pdf'
    },
    {
        environment: 'LTS',
        key: 'TERMS_OF_USE_URL',
        value: 'https://www.lotusconnect.com/terms-of-use/'
    },
    {
        environment: 'SHFT',
        key: 'TERMS_OF_USE_URL',
        value: 'https://www.shifts.com/terms-of-use/'
    },

    {
        environment: 'AYA',
        key: 'ELECTRONIC_CONSENT',
        value: 'https://www.ayahealthcare.com/electronic-consent-agreement/'
    },
    {
        environment: 'ONE',
        key: 'ELECTRONIC_CONSENT',
        value: 'https://www.lotusone.com/electronic-consent-agreement/'
    },
    {
        environment: 'HCS',
        key: 'ELECTRONIC_CONSENT',
        value: ''
    },
    {
        environment: 'LTS',
        key: 'ELECTRONIC_CONSENT',
        value: 'https://www.lotusconnect.com/electronic-consent-agreement/'
    },
    {
        environment: 'SHFT',
        key: 'ELECTRONIC_CONSENT',
        value: 'https://www.shifts.com/electronic-consent-agreement/'
    },

    {
        environment: 'AYA',
        key: 'EMPLOYMENT_SHOW',
        value: true
    },
    {
        environment: 'ONE',
        key: 'EMPLOYMENT_SHOW',
        value: true
    },
    {
        environment: 'HCS',
        key: 'EMPLOYMENT_SHOW',
        value: true
    },
    {
        environment: 'LTS',
        key: 'EMPLOYMENT_SHOW',
        value: true
    },
    {
        environment: 'SHFT',
        key: 'EMPLOYMENT_SHOW',
        value: true
    },

    {
        environment: 'AYA',
        key: 'POSITIONS_SHOW',
        value: true
    },
    {
        environment: 'ONE',
        key: 'POSITIONS_SHOW',
        value: true
    },
    {
        environment: 'HCS',
        key: 'POSITIONS_SHOW',
        value: true
    },
    {
        environment: 'LTS',
        key: 'POSITIONS_SHOW',
        value: true
    },
    {
        environment: 'SHFT',
        key: 'POSITIONS_SHOW',
        value: true
    },

    {
        environment: 'AYA',
        key: 'VMS_SHOW',
        value: true
    },
    {
        environment: 'ONE',
        key: 'VMS_SHOW',
        value: true
    },
    {
        environment: 'HCS',
        key: 'VMS_SHOW',
        value: true
    },
    {
        environment: 'LTS',
        key: 'VMS_SHOW',
        value: true
    },
    {
        environment: 'SHFT',
        key: 'VMS_SHOW',
        value: true
    },

    {
        environment: 'AYA',
        key: 'VMS_SEPARATE',
        value: false
    },
    {
        environment: 'ONE',
        key: 'VMS_SEPARATE',
        value: false
    },
    {
        environment: 'HCS',
        key: 'VMS_SEPARATE',
        value: true
    },
    {
        environment: 'LTS',
        key: 'VMS_SEPARATE',
        value: true
    },
    {
        environment: 'LTS',
        key: 'VMS_SEPARATE',
        value: false
    },

    {
        environment: 'AYA',
        key: 'QUESTION_SHOW',
        value: true
    },
    {
        environment: 'ONE',
        key: 'QUESTION_SHOW',
        value: true
    },
    {
        environment: 'HCS',
        key: 'QUESTION_SHOW',
        value: true
    },
    {
        environment: 'LTS',
        key: 'QUESTION_SHOW',
        value: true
    },
    {
        environment: 'SHFT',
        key: 'QUESTION_SHOW',
        value: true
    },

    {
        environment: 'AYA',
        key: 'SPINNER_COLOR',
        value: '#F58A00'
    },
    {
        environment: 'ONE',
        key: 'SPINNER_COLOR',
        value: '#034A59'
    },
    {
        environment: 'HCS',
        key: 'SPINNER_COLOR',
        value: '#085394'
    },
    {
        environment: 'LTS',
        key: 'SPINNER_COLOR',
        value: '#018f3c'
    },
    {
        environment: 'SHFT',
        key: 'SPINNER_COLOR',
        value: '#009999'
    },

    {
        environment: 'AYA',
        key: 'SUPPORT_EMAIL',
        value: 'ClientConnectSupport@ayahealthcare.com'
    },
    {
        environment: 'ONE',
        key: 'SUPPORT_EMAIL',
        value: 'support@lotusone.com'
    },
    {
        environment: 'ONE',
        key: 'SUPPORT_PHONE_NUMBER',
        value: '(866) 756-8871'
    },
    {
        environment: 'HCS',
        key: 'SUPPORT_EMAIL',
        value: 'sysadmin@hcselect.com'
    },
    {
        environment: 'LTS',
        key: 'SUPPORT_EMAIL',
        value: 'ClientConnectSupport@lotusworkforce.com'
    },
    {
        environment: 'SHFT',
        key: 'SUPPORT_EMAIL',
        value: 'support@shifts.com'
    },
    {
        environment: 'AYA',
        key: 'VENDOR_SUPPORT_EMAIL',
        value: 'PartnerSupport@lotusworkforce.com'
    },
    {
        environment: 'ONE',
        key: 'VENDOR_SUPPORT_EMAIL',
        value: 'PartnerSupport@lotusone.com'
    },
    {
        environment: 'LTS',
        key: 'VENDOR_SUPPORT_EMAIL',
        value: 'PartnerSupport@lotusworkforce.com'
    },
    {
        environment: 'AYA',
        key: 'SIGN_OUT_URL',
        value: 'https://www.ayaconnect.com'
    },
    {
        environment: 'ONE',
        key: 'SIGN_OUT_URL',
        value: 'https://www.lotusone.com'
    },
    {
        environment: 'AYA',
        key: 'INT_SIGN_OUT_URL',
        value: 'https://ayaconnectint.wpengine.com'
    },
    {
        environment: 'AYA',
        key: 'HOT_SIGN_OUT_URL',
        value: 'https://ayaconnecthot.wpengine.com'
    },
    {
        environment: 'AYA',
        key: 'DEV_SIGN_OUT_URL',
        value: 'https://ayaconnectdev.wpengine.com'
    },
    {
        environment: 'HCS',
        key: 'SIGN_OUT_URL',
        value: 'app.signin'
    },
    {
        environment: 'LTS',
        key: 'SIGN_OUT_URL',
        value: 'https://www.lotusconnect.com'
    },
    {
        environment: 'LTS',
        key: 'INT_SIGN_OUT_URL',
        value: 'https://app-int.lotusconnect.com'
    },
    {
        environment: 'LTS',
        key: 'HOT_SIGN_OUT_URL',
        value: 'https://app-hot.lotusconnect.com'
    },
    {
        environment: 'LTS',
        key: 'DEV_SIGN_OUT_URL',
        value: 'https://app-dev.lotusconnect.com'
    },
    {
        environment: 'SHFT',
        key: 'SIGN_OUT_URL',
        value: 'app.signin'
    },
    {
        environment: 'AYA',
        key: 'INT_CORE_URL',
        value: 'app-int.ayaconnect.com'
    },
    {
        environment: 'AYA',
        key: 'HOT_CORE_URL',
        value: 'app-hot.ayaconnect.com'
    },
    {
        environment: 'AYA',
        key: 'DEV_CORE_URL',
        value: 'app-dev.ayaconnect.com'
    },
    {
        environment: 'AYA',
        key: 'LOCAL_CORE_URL',
        value: 'localhost'
    },
    {
        environment: 'ONE',
        key: 'INT_CORE_URL',
        value: 'app-int.lotusone.com'
    },
    {
        environment: 'ONE',
        key: 'HOT_CORE_URL',
        value: 'app-hot.lotusone.com'
    },
    {
        environment: 'ONE',
        key: 'DEV_CORE_URL',
        value: 'app-dev.lotusone.com'
    },
    {
        environment: 'ONE',
        key: 'LOCAL_CORE_URL',
        value: 'localhost'
    },
    {
        environment: 'LTS',
        key: 'INT_CORE_URL',
        value: 'app-int.lotusconnect.com'
    },
    {
        environment: 'LTS',
        key: 'HOT_CORE_URL',
        value: 'app-hot.lotusconnect.com'
    },
    {
        environment: 'LTS',
        key: 'DEV_CORE_URL',
        value: 'app-dev.lotusconnect.com'
    },
    {
        environment: 'LTS',
        key: 'LOCAL_CORE_URL',
        value: 'localhost'
    },
    {
        environment: 'LTS',
        key: 'PROD_CORE_URL',
        value: 'app.lotusconnect.com'
    },
    {
        environment: 'AYA',
        key: 'PROD_CORE_URL',
        value: 'app.ayaconnect.com'
    },
    {
        environment: 'ONE',
        key: 'PROD_CORE_URL',
        value: 'app.lotusone.com'
    },
    {
        environment: 'LTS',
        key: 'RESET_PASSWORD_BRAND_NAME',
        value: 'lotus'
    },
    {
        environment: 'AYA',
        key: 'RESET_PASSWORD_BRAND_NAME',
        value: 'ayaconnect'
    },
    {
        environment: 'ONE',
        key: 'RESET_PASSWORD_BRAND_NAME',
        value: 'lotusone'
    },
    {
        environment: 'SHFT',
        key: 'REDIRECT_APP_SEGMENT',
        value: 'shifts-connect'
    },
    {
        environment: 'AYA',
        key: 'REDIRECT_APP_SEGMENT',
        value: 'connect'
    }
];
