interface AppModule {
    appModuleName: string;
    menuName: string;
    icon: string;
    stateName: string;
    states: NavigationState[];
}
interface NavigationState {
    name?: string;
    icon?: string;
    matIcon?: string;
    state?: string;
    urlState?: string;
    isSubItem?: boolean;
    skipAuthCheck?: boolean;
    potentialRoutes?: string[];
    authorization?: string[];
}

const defaultState: NavigationState = {
    authorization: ['c', 'r', 'u', 'd']
};

export const appModules: AppModule[] = [
    {
        appModuleName: 'admin',
        menuName: 'Administration',
        icon: 'fab fa-buysellads',
        stateName: 'admin',
        states: [
            {
                ...defaultState,
                name: 'Clients',
                icon: 'fa fa-users',
                state: 'admin.clients',
                potentialRoutes: ['admin/clients', 'admin/clients_Detail', 'admin/clients_Edit']
            },
            {
                ...defaultState,
                state: 'admin.clients_Detail'
            },
            {
                ...defaultState,
                state: 'admin.clients_Edit'
            },
            {
                ...defaultState,
                name: 'Client Contacts',
                icon: 'fa fa-users',
                state: 'admin.clientContacts',
                potentialRoutes: [
                    'admin/clientContacts',
                    'admin/clientContact',
                    'admin/clientContacts_Detail',
                    'admin/ClientContacts_Edit'
                ]
            },
            {
                ...defaultState,
                state: 'admin.clientContacts_Detail'
            },
            {
                ...defaultState,
                state: 'admin.ClientContacts_Edit'
            },
            {
                ...defaultState,
                name: 'Manage Permissions',
                icon: 'fa fa-cogs',
                state: 'admin.clientContactPemissions',
                potentialRoutes: ['admin/clientContactPemissions'],
                isSubItem: true
            },
            {
                ...defaultState,
                name: 'System Contacts',
                icon: 'fa fa-users',
                state: 'admin.systemContacts',
                potentialRoutes: [
                    'admin/systemContacts',
                    'admin/systemContact',
                    'admin/systemContacts_Detail',
                    'admin/systemContacts_Edit'
                ]
            },
            {
                ...defaultState,
                name: 'Manage Permissions',
                icon: 'fa fa-cogs',
                state: 'admin.systemContactPemissions',
                potentialRoutes: ['admin/systemContactPemissions'],
                isSubItem: true
            },
            {
                ...defaultState,
                name: 'Role Permissions',
                icon: 'fa fa-cogs',
                state: 'admin.clientContactRoles',
                potentialRoutes: ['admin/clientContactRoles']
            },
            {
                ...defaultState,
                state: 'admin.systemContacts_Detail'
            },
            {
                ...defaultState,
                state: 'admin.systemContacts_Edit'
            },
            {
                ...defaultState,
                name: 'Facilities',
                icon: 'fa fa-building',
                state: 'admin.facilities',
                potentialRoutes: ['admin/facilities', 'admin/facilities_Detail', 'admin/facilities_Edit']
            },
            {
                ...defaultState,
                name: 'Contract Groups',
                icon: 'fa fa-cogs',
                state: 'admin.facilityAssociations',
                urlState: 'admin/facility/associations',
                potentialRoutes: [
                    'admin/facility/associations',
                    'admin/facilityAssociations',
                    'admin/facilityAssociations_Vendor'
                ],
                isSubItem: true
            },
            {
                ...defaultState,
                name: 'Systems',
                icon: 'fa fa-university',
                state: 'admin.facilitySystems',
                urlState: 'admin/facility/systems',
                potentialRoutes: ['admin/facility/systems', 'admin/facilitySystems_Vendor'],
                isSubItem: true
            },
            {
                ...defaultState,
                state: 'admin.facilitySystems_Vendor'
            },
            {
                ...defaultState,
                state: 'admin.facilityAssociations_Vendor'
            },
            {
                ...defaultState,
                state: 'admin.facilities_Detail'
            },
            {
                ...defaultState,
                state: 'admin.facilities_Edit'
            },
            {
                state: 'admin.scheduledReleaseRule',
                skipAuthCheck: true
            },
            {
                ...defaultState,
                name: 'Jobs',
                icon: 'fa fa-th',
                state: 'admin.jobs',
                potentialRoutes: ['admin/jobs', 'admin/job', 'admin/jobs_Detail', 'admin/jobs_Edit']
            },
            {
                ...defaultState,
                state: 'admin.jobs_Detail'
            },
            {
                ...defaultState,
                state: 'admin.jobs_Edit'
            },
            {
                ...defaultState,
                name: 'Vendors',
                icon: 'fa fa-users',
                state: 'admin.vendors',
                potentialRoutes: ['admin/vendor', 'admin/vendors', 'admin/vendors_Detail', 'admin/vendors_Edit']
            },
            {
                ...defaultState,
                state: 'admin.vendors_Detail'
            },
            {
                ...defaultState,
                state: 'admin.vendors_Edit'
            },
            {
                ...defaultState,
                name: 'Vendor Job Rules',
                state: 'admin.vendors_jobRules',
                urlState: 'admin/vendors/job-rules',
                potentialRoutes: ['admin/vendors/job-rules', 'admin/vendors/job-rules/edit'],
                icon: 'fa fa-list-check'
            },
            {
                ...defaultState,
                state: 'admin.vendors_jobRules/edit'
            },
            {
                ...defaultState,
                name: 'Candidates',
                icon: 'fa fa-users',
                state: 'admin.vendorCandidates',
                potentialRoutes: [
                    'admin/vendorCandidates',
                    'admin/vendorCandidate',
                    'admin/vendorCandidates_Detail',
                    'admin/vendorCandidates_Edit'
                ]
            },
            {
                ...defaultState,
                state: 'admin.vendorCandidates_Detail'
            },
            {
                ...defaultState,
                state: 'admin.vendorCandidates_Edit'
            },
            {
                ...defaultState,
                name: 'Vendor Contacts',
                icon: 'fa fa-users',
                state: 'admin.vendorContacts',
                potentialRoutes: [
                    'admin/vendorContacts',
                    'admin/vendorContact',
                    'admin/vendorContacts_Detail',
                    'admin/vendorContacts_Edit'
                ]
            },
            {
                ...defaultState,
                state: 'admin.vendorContacts_Detail'
            },
            {
                ...defaultState,
                state: 'admin.vendorContacts_Edit'
            },
            {
                ...defaultState,
                name: 'Reports',
                icon: 'fa fa-user-md',
                state: 'admin.reports',
                potentialRoutes: ['admin/reports', 'admin/report']
            },
            {
                ...defaultState,
                name: 'Submittals',
                icon: 'fa fa-user-md',
                state: 'admin.submittals',
                potentialRoutes: [
                    'admin/submittals',
                    'admin/submittal',
                    'admin/submittals_Detail',
                    'admin/submittals_Edit',
                    'admin/submittals_Jobs',
                    'admin/submittals_Job',
                    'admin/submittals_Candidates',
                    'admin/submittals_Candidate'
                ]
            },
            {
                ...defaultState,
                state: 'admin.submittals_Detail'
            },
            {
                ...defaultState,
                state: 'admin.submittals_Edit'
            },
            {
                ...defaultState,
                state: 'admin.submittals_Jobs'
            },
            {
                ...defaultState,
                state: 'admin.submittals_Job'
            },
            {
                ...defaultState,
                state: 'admin.submittals_Candidates'
            },
            {
                ...defaultState,
                state: 'admin.submittals_Candidate'
            },
            {
                state: 'admin.customField',
                skipAuthCheck: true
            },
            {
                ...defaultState,
                name: 'Permissions',
                icon: 'fa fa-group',
                state: 'admin.permissions',
                potentialRoutes: [
                    'admin/permissions',
                    'admin/permission',
                    'admin/perdiemorientation_details',
                    'admin/perdiemcandidate'
                ]
            },
            {
                ...defaultState,
                state: 'admin.perdiemorientation_details'
            },
            {
                ...defaultState,
                state: 'admin.perdiemcandidate'
            }
        ]
    },
    {
        appModuleName: 'vendor',
        //Look into Domain Config variables
        menuName: '%VENDOR_MENU_NAME%',
        icon: '',
        stateName: 'vendor',
        states: [
            {
                ...defaultState,
                name: 'Home',
                icon: 'fa fa-th',
                state: 'vendor.dashboard',
                potentialRoutes: ['vendor/dashboard']
            },
            {
                ...defaultState,
                name: 'Candidates',
                icon: 'fa fa-users',
                state: 'vendor.candidates',
                potentialRoutes: [
                    'vendor/candidates',
                    'vendor/candidate',
                    'vendor/submittalCandidate',
                    'vendor/candidates_Detail',
                    'vendor/candidates_Edit'
                ]
            },
            {
                ...defaultState,
                state: 'vendor.candidates_Detail'
            },
            {
                ...defaultState,
                state: 'vendor.candidates_Edit'
            },
            {
                ...defaultState,
                name: 'Vendors',
                icon: 'fa fa-group',
                state: 'vendor.vendors',
                potentialRoutes: ['vendor/vendors', 'vendor/vendor', 'vendor/vendors_Detail', 'vendor/vendors_Edit']
            },
            {
                ...defaultState,
                state: 'vendor.vendors_Detail'
            },
            {
                ...defaultState,
                state: 'vendor.vendors_Edit'
            },
            {
                ...defaultState,
                name: 'Forms',
                icon: 'fa fa-th',
                state: 'vendor.forms',
                potentialRoutes: ['vendor/forms', 'vendor/form']
            },
            {
                ...defaultState,
                name: 'Jobs',
                icon: 'fa fa-th',
                state: 'vendor.jobs',
                potentialRoutes: ['vendor/jobs', 'vendor/jobs/details', 'vendor/jobs_Detail', 'vendor/jobs_Edit']
            },
            {
                ...defaultState,
                state: 'vendor.jobs_Detail'
            },
            {
                ...defaultState,
                state: 'vendor.jobs_Edit'
            },
            {
                ...defaultState,
                name: 'Facilities',
                icon: 'fa fa-building',
                state: 'vendor.facilities',
                potentialRoutes: ['vendor/facilities', 'vendor/facility', 'vendor/facilities_Detail']
            },
            {
                ...defaultState,
                state: 'vendor.facilities_Detail'
            },
            {
                ...defaultState,
                name: 'Submittals',
                icon: 'fa fa-user-md',
                state: 'vendor.submittals',
                potentialRoutes: [
                    'vendor/submittals',
                    'vendor/submittal',
                    'vendor/offers',
                    'vendor/submittals_Detail',
                    'vendor/submittals_Edit',
                    'vendor/submittals_Jobs',
                    'vendor/submittals_Job',
                    'vendor/submittals_Candidates',
                    'vendor/submittals_Candidate',
                    'vendor/submittals_offerDetail'
                ]
            },
            {
                ...defaultState,
                state: 'vendor.submittals_Detail'
            },
            {
                ...defaultState,
                state: 'vendor.submittals_Edit'
            },
            {
                ...defaultState,
                state: 'vendor.submittals_Jobs'
            },
            {
                ...defaultState,
                state: 'vendor.submittals_Job'
            },
            {
                ...defaultState,
                state: 'vendor.submittals_Candidates'
            },
            {
                ...defaultState,
                state: 'vendor.submittals_Candidate'
            },
            {
                ...defaultState,
                state: 'vendor.submittals_offerDetail'
            },
            {
                ...defaultState,
                state: 'vendor.perdiemorientation_details'
            },
            {
                ...defaultState,
                name: 'Per Diem Submittals',
                icon: 'fa fa-user',
                state: 'vendor.perdiemsubmittals',
                potentialRoutes: ['vendor/perdiemsubmittals', 'vendor/perdiemsubmittal']
            },
            {
                ...defaultState,
                name: 'Shifts',
                icon: 'fa fa-sliders',
                state: 'vendor.perdiemscheduler',
                potentialRoutes: ['vendor/perdiemscheduler', 'vendor/perdiemscheduler_Detail']
            },
            {
                ...defaultState,
                state: 'vendor.perdiemscheduler_Detail'
            },
            {
                ...defaultState,
                name: 'Timecards',
                icon: 'fa-regular fa-clock',
                state: 'vendor.timecards',
                potentialRoutes: ['vendor/timecard', 'vendor/timecards', 'vendor/timecards_Detail']
            },
            {
                ...defaultState,
                state: 'vendor.timecards_Detail'
            },
            {
                ...defaultState,
                name: 'Reverse Invoices',
                icon: 'fa fa-file-excel',
                state: 'vendor.reverseinvoices',
                potentialRoutes: ['vendor/reverseinvoices']
            },
            {
                ...defaultState,
                name: 'Expenses',
                matIcon: 'payFilled',
                state: 'vendor.vendor_expenses',
                potentialRoutes: [
                    'vendor/vendor_expenses',
                    'vendor/vendor_expenses/add',
                    'vendor/vendor_expenses/edit',
                    'vendor/vendor_expenses/approved'
                ]
            },
            {
                ...defaultState,
                name: 'Reports',
                icon: 'fa fa-bar-chart',
                state: 'vendor.reports',
                potentialRoutes: ['vendor/reports', 'vendor/report', 'vendor/reports_Detail', 'vendor/reports/powerbi']
            },
            {
                ...defaultState,
                state: 'vendor.reports_Detail'
            }
        ]
    },
    {
        appModuleName: 'client',
        //Look into Domain Config variables
        menuName: '%CLIENT_MENU_NAME%',
        icon: '',
        stateName: 'client',
        states: [
            {
                ...defaultState,
                name: 'Home',
                icon: 'fa fa-th',
                state: 'client.home',
                potentialRoutes: ['client/home']
            },
            {
                ...defaultState,
                name: 'Dashboard',
                state: 'client.dashboard',
                potentialRoutes: ['client/dashboard']
            },
            {
                ...defaultState,
                name: 'Jobs',
                icon: 'fa fa-briefcase',
                state: 'client.jobs',
                potentialRoutes: [
                    'client/jobs',
                    'client/job',
                    'client/jobs_Detail',
                    'client/jobs_Add',
                    'client/jobs_Edit',
                    'client/jobstemplates',
                    'client/jobstemplates_Edit',
                    'client/jobsapprovals',
                    'client/jobsfacilityrules',
                    'client/jobsfacilityrule',
                    'client/jobsunitrules',
                    'client/jobsunitrule',
                    'client/approvaldetails'
                ]
            },
            {
                ...defaultState,
                state: 'client.jobs_Detail'
            },
            {
                ...defaultState,
                state: 'client.jobs_Add'
            },
            {
                ...defaultState,
                state: 'client.jobs_Edit'
            },
            {
                ...defaultState,
                state: 'client.jobstemplates'
            },
            {
                ...defaultState,
                state: 'client.jobstemplates_Edit'
            },
            {
                ...defaultState,
                state: 'client.jobsapprovals'
            },
            {
                ...defaultState,
                state: 'client.jobsfacilityrules'
            },
            {
                ...defaultState,
                state: 'client.jobsfacilityrule'
            },
            {
                ...defaultState,
                state: 'client.jobsunitrules'
            },
            {
                ...defaultState,
                state: 'client.jobsunitrule'
            },
            {
                ...defaultState,
                state: 'client.approvaldetails'
            },
            {
                ...defaultState,
                name: 'Submittals',
                icon: 'fa fa-user-md',
                state: 'client.submittals',
                potentialRoutes: [
                    'client/submittals',
                    'client/submittal',
                    'client/submittals_Detail',
                    'client/submittals_Edit',
                    'client/submittals_Jobs',
                    'client/submittals_Job',
                    'client/submittals_Candidates',
                    'client/submittals_Candidate'
                ]
            },
            {
                ...defaultState,
                state: 'client.submittals_Detail'
            },
            {
                ...defaultState,
                state: 'client.submittals_Edit'
            },
            {
                ...defaultState,
                state: 'client.submittals_Jobs'
            },
            {
                ...defaultState,
                state: 'client.submittals_Job'
            },
            {
                ...defaultState,
                state: 'client.submittals_Candidates'
            },
            {
                ...defaultState,
                state: 'client.submittals_Candidate'
            },
            {
                ...defaultState,
                name: 'Travelers',
                icon: 'fa fa-group',
                state: 'client.travelers',
                potentialRoutes: [
                    'client/travelers',
                    'client/traveler',
                    'client/travelers_Detail',
                    'client/travelers_Detail_Documents',
                    'client/travelers_compliance_documents',
                    'client/travelers_Edit',
                    'client/staffingadvisory_edit'
                ]
            },
            {
                ...defaultState,
                state: 'client.travelers_Detail'
            },
            {
                ...defaultState,
                state: 'client.travelers_Detail_Documents'
            },
            {
                state: 'client.travelers_compliance_documents',
                skipAuthCheck: true // there is a guard that manages access
            },
            {
                ...defaultState,
                state: 'client.travelers_Edit'
            },
            {
                ...defaultState,
                name: 'Expenses',
                state: 'client.expenses'
            },
            {
                ...defaultState,
                state: 'client.staffingadvisory_edit'
            },
            {
                ...defaultState,
                name: 'Per Diem',
                icon: 'fa fa-home',
                state: 'client.perdiemhome',
                potentialRoutes: ['client/perdiemhome']
            },
            {
                ...defaultState,
                name: 'Per Diem Orientations',
                icon: 'fa fa-check-square-o',
                state: 'client.perdiemorientation',
                potentialRoutes: ['client/perdiemorientation', 'client/perdiemorientation_details']
            },
            {
                ...defaultState,
                state: 'client.perdiemorientation_details'
            },
            {
                ...defaultState,
                name: 'Per Diem Submittals',
                icon: 'fa fa-user',
                state: 'client.perdiemsubmittals',
                potentialRoutes: ['client/perdiemsubmittals', 'client/perdiemsubmittal']
            },
            {
                ...defaultState,
                name: 'Shifts',
                icon: 'fa fa-sliders',
                state: 'client.perdiemscheduler',
                potentialRoutes: [
                    'client/perdiemscheduler',
                    'client/perdiemscheduler_Bulk',
                    'client/perdiemscheduler_Add',
                    'client/perdiemscheduler_Edit',
                    'client/perdiemscheduler_Approvals',
                    'client/perdiemscheduler_ApprovalsFacilityRules',
                    'client/perdiemscheduler_ApprovalsFacilityRule',
                    'client/perdiemscheduler_ApprovalsUnitRules',
                    'client/perdiemscheduler_ApprovalsUnitRule',
                    'client/perdiemscheduler_Detail'
                ]
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_Bulk'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_Add'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_Edit'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_Approvals'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_ApprovalsFacilityRules'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_ApprovalsFacilityRule'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_ApprovalsUnitRules'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_ApprovalsUnitRule'
            },
            {
                ...defaultState,
                state: 'client.perdiemscheduler_Detail'
            },
            {
                ...defaultState,
                state: 'client.shiftstemplates_view'
            },
            {
                ...defaultState,
                state: 'client.shiftstemplates_edit'
            },
            {
                ...defaultState,
                name: 'Shifts Submittals',
                icon: 'fa fa-sliders',
                state: 'client.shiftsubmittal_view',
                potentialRoutes: ['client/shiftsubmittal_view']
            },
            {
                ...defaultState,
                name: 'Shifts Submittals Details',
                icon: 'fa fa-sliders',
                state: 'client.shiftsubmittal_edit',
                potentialRoutes: ['client/shiftsubmittal_edit']
            },
            {
                ...defaultState,
                name: 'Shifts Submittals Details IRP',
                icon: 'fa fa-sliders',
                state: 'client.jobs_irp-submittals_Detail',
                potentialRoutes: ['client/jobs_irp-submittals_Detail']
            },
            {
                ...defaultState,
                name: 'Shifts Submittals Documents',
                icon: 'fa fa-sliders',
                state: 'client.shiftsubmittal_documents',
                potentialRoutes: ['client/shiftsubmittal_documents']
            },
            {
                ...defaultState,
                name: 'Local Pool',
                icon: 'fa fa-users',
                state: 'client.perdiempool',
                potentialRoutes: [
                    'client/perdiempool',
                    'client/perdiempool_Detail',
                    'client/perdiempool_Detail_Documents'
                ]
            },
            {
                ...defaultState,
                state: 'client.perdiempool_Detail'
            },
            {
                ...defaultState,
                state: 'client.perdiempool_Detail_Documents'
            },
            {
                ...defaultState,
                name: 'Internal Pool',
                icon: 'fa fa-users',
                state: 'client.internalpool',
                potentialRoutes: ['client/internalpool', 'client/internalpool_Edit']
            },
            {
                ...defaultState,
                state: 'client.internalpool_Edit'
            },
            {
                ...defaultState,
                name: 'Timecards',
                icon: 'fa fa-clock-o',
                state: 'client.timecards',
                potentialRoutes: ['client/timecards', 'client/timecard', 'client/timecards_Detail']
            },
            {
                ...defaultState,
                state: 'client.timecards_Detail'
            },
            {
                ...defaultState,
                name: 'Invoices',
                icon: 'fa fa-file-text-o',
                state: 'client.invoiced',
                potentialRoutes: ['client/invoiced', 'client/invoiced_Detail']
            },
            {
                ...defaultState,
                state: 'client.invoiced_Detail'
            },
            {
                ...defaultState,
                name: 'Invoices Attachment',
                state: 'client.invoiced_Attachment',
                potentialRoutes: ['client/invoiced_Attachment']
            },
            {
                name: 'Facility Management',
                icon: 'corporate_fare',
                state: 'client.facilityManagement',
                potentialRoutes: ['client/facilityManagement', 'client/facility-management'],
                skipAuthCheck: true
            },
            {
                ...defaultState,
                name: 'Reports',
                icon: 'fa fa-tasks',
                state: 'client.reports',
                potentialRoutes: ['client/reports', 'client/reports_Detail']
            },
            {
                ...defaultState,
                state: 'client.reports_Detail'
            },
            {
                ...defaultState,
                name: 'Contact',
                icon: 'fa fa-envelope',
                state: 'client.contacts',
                potentialRoutes: ['client/contacts', 'client/contact']
            },
            {
                ...defaultState,
                name: 'Help',
                icon: 'fa fa-file',
                state: 'client.help',
                potentialRoutes: ['client/help']
            },
            {
                ...defaultState,
                state: 'client.profession_Specialty_Limiting'
            },
            {
                state: 'client.facilityManagement_Edit',
                skipAuthCheck: true
            }
        ]
    },
    {
        appModuleName: 'clinical',
        //Look into Domain Config variables
        menuName: '%CLINICAL_MENU_NAME%',
        icon: 'fa fa-suitcase',
        stateName: 'clinical',
        states: [
            {
                ...defaultState,
                name: 'Clients',
                icon: 'fa fa-users',
                state: 'clinical.clients',
                potentialRoutes: ['clinical/clients', 'clinical/client', 'clinical/clients_Detail']
            },
            {
                ...defaultState,
                state: 'clinical.clients_Detail'
            }
        ]
    }
];
