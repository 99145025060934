export enum SubmittalStatusType {
    PENDING_REVIEW = 1,
    QUALIFIED = 2,
    INTERVIEW = 3,
    OFFER = 4,
    DECLINED = 5,
    CONTACTED = 6
}

export enum SubmittalStatuses {
    MoreInformationNeeded = 2,
    PendingReview = 5,
    UnderReviewByHiringManager = 6,
    AdditionalReviewNeeded = 7,
    CandidateNotSelected = 8,
    CandidateWithdrawingFromSubmission = 9,
    Interview = 10,
    Offer = 11,
    OfferDeclined = 13,
    Placed = 14,
    OfferRescinded = 15,
    OfferAccepted = 17
}

export enum SubmittalSubStatuses {
    NotEnoughRelevantExperience = 1,
    CertRequirementNotMet = 2,
    EducationRequirementNotMet = 3,
    FacilityVaccinationPolicyNotMet = 4,
    StartDateCouldNotBeAccommodated = 5,
    RtoScheduleRequestsCouldNotBeAccommodated = 6,
    CandidateCouldNotBeReachedForInterview = 7,
    CandidateAcceptedAnOfferElsewhere = 8,
    CandidateDeclinedToMoveForward = 9,
    Requested = 10,
    Scheduled = 11,
    Completed = 12,
    BudgetChanged = 13,
    CensusDropped = 14,
    FoundPermCandidate = 15,
    MoreInformationNeeded = 18,
    FacilityRequirementsNotMet = 19,
    FacilityDecline = 20,
    JobFilled = 21,
    DuplicateSubmittal = 23,
    Other = 24,
    ChangeOfAgency = 27,
    MileageRestriction = 29,
    CandidateNotEligibleForHire = 30,
    JobRemoved = 31
}

export const clientReadOnlySubmittalStatuses = [
    SubmittalStatuses.Offer,
    SubmittalStatuses.OfferAccepted,
    SubmittalStatuses.OfferRescinded,
    SubmittalStatuses.OfferDeclined,
    SubmittalStatuses.Placed,
    SubmittalStatuses.CandidateNotSelected,
    SubmittalStatuses.CandidateWithdrawingFromSubmission,
    SubmittalStatuses.MoreInformationNeeded
];
