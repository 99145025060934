import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateParams } from 'src/app/core/utils';
import { GridDataResult } from 'src/app/shared/grid/models/grid-data-result.model';
import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { combine } from 'src/app/shared/utilities';
import { getStringQueryFromGridSearch } from 'src/app/shared/utilities/filter-descriptor-utility';
import { Candidate, CandidateFile } from 'src/app/shared/models/candidate';
import { saveAs } from 'js/lib/file-saver/FileSaver';
import { DocumentAttributeField } from '../store/models/document-attribute-field';
import { DocumentAttribute } from '../store/models/document-attribute';
import { AssociateMetadataToContractResponseDto } from '../store/models/associate-metadata-to-contract-response-dto';
import { AssociateMetadataToContractRequest } from '../store/models/associate-metadata-to-contract-request';
import { DocumentProcessedDto } from '../store/models/document-processed-dto';
import { ComplianceCandidateDocumentDetailDto } from '../store/models/compliance-candidate-document-detail-dto';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    getStringQueryFromGridSearchRef = getStringQueryFromGridSearch;
    candidate: Candidate;
    candidate$ = new BehaviorSubject<Candidate>(null);
    private readonly _coreApiPath: string;
    private readonly _vendorCandidatesPath: string;
    private readonly _vendorCandidatesPathV2: string;
    private readonly _complianceMetadataPath: string;

    constructor(private readonly _http: HttpClient, @Inject(APP_CONFIG) readonly _settings: Settings) {
        this._coreApiPath = combine(_settings.CORE, 'ayaconnect');
        this._vendorCandidatesPath = combine(this._coreApiPath, 'vendor', 'candidates');
        this._vendorCandidatesPathV2 = combine(this._vendorCandidatesPath, 'v2');
        this._complianceMetadataPath = combine(this._coreApiPath, 'compliance', 'metadata');
    }

    getVendorCandidateFiles(candidateId: number, request: GridSearchQuery): Observable<GridDataResult> {
        const queryParams = this.getStringQueryFromGridSearchRef(request);
        const url = `${combine(this._vendorCandidatesPath, candidateId.toString(), 'files')}${queryParams}`;

        return this._http.get<GridDataResult>(url);
    }

    downloadFile(candidateFile: CandidateFile): void {
        const url = combine(
            this._vendorCandidatesPath,
            candidateFile.vendorCandidateId.toString(),
            'files',
            candidateFile.fileId.toString()
        );
        this._http
            .get(url, { responseType: 'blob' })
            .pipe(first())
            .subscribe((data) => {
                const blob = new Blob([data]);
                saveAs(blob, candidateFile.file.name + candidateFile.file.type);
            });
    }

    deleteFiles(candidateId: number, fileIds: number[]): Observable<any> {
        const fileIdQuery = fileIds.map((fileId) => `fileIds=${fileId}`).join('&');
        const url = `${combine(this._vendorCandidatesPath, candidateId.toString(), 'files')}?${fileIdQuery}`;

        return this._http.delete(url);
    }

    addFiles(candidateId: number, candidateFiles: any[]): Observable<number[]> {
        const requests: Array<Observable<number>> = candidateFiles.map((file) => {
            const uploadData = new FormData();
            uploadData.append('fileTypeId', file.fileTypeId);
            uploadData.append('file', file.fileToUpload);
            const url = combine(this._vendorCandidatesPath, candidateId.toString(), 'files');
            return this._http.post<number>(url, uploadData);
        });

        return forkJoin(requests);
    }

    uploadRequirementFile(
        candidateId: number,
        contractId: number,
        docTypeId: number,
        fileToUpload: File
    ): Observable<DocumentProcessedDto> {
        const uploadRequest = new FormData();
        uploadRequest.append('documentTypeId', docTypeId.toString());
        uploadRequest.append('candidateId', candidateId.toString());
        uploadRequest.append('originalFileName', fileToUpload.name);
        uploadRequest.append('contractId', contractId.toString());
        const fileArray = [fileToUpload];
        fileArray.forEach((file) => uploadRequest.append('files', file));

        const url = combine(`${this._vendorCandidatesPathV2}`, candidateId.toString(), 'document-upload');

        return this._http.post<DocumentProcessedDto>(url, uploadRequest);
    }

    getDocumentUploadedFile(candidateDocumentId: number): Observable<Blob> {
        const url = combine(`${this._vendorCandidatesPath}`, candidateDocumentId.toString(), 'stream');

        return this._http.get(url, { responseType: 'blob' });
    }

    getComplianceCandidateDocumentDetails(
        candidateDocumentId: number
    ): Observable<ComplianceCandidateDocumentDetailDto> {
        const url = combine(`${this._vendorCandidatesPath}`, candidateDocumentId.toString(), 'document-upload-details');

        return this._http.get<ComplianceCandidateDocumentDetailDto>(url);
    }

    getMetadataForRequirementFile(
        candidateDocumentId: number,
        docTypeId: number
    ): Observable<DocumentAttributeField[]> {
        const url = combine(this._complianceMetadataPath, 'fields');
        const params = interpolateParams({
            documentTypeId: docTypeId.toString(),
            candidateDocumentId: candidateDocumentId.toString()
        });

        return this._http.get<DocumentAttributeField[]>(url, { params });
    }

    filterDocumentAttributeFields(
        documentTypeId: string,
        documentAttributes: DocumentAttribute[]
    ): Observable<DocumentAttributeField[]> {
        const url = combine(this._complianceMetadataPath, 'fields', 'filter');
        const payLoad = {
            documentTypeId,
            values: documentAttributes
        };

        return this._http.post<DocumentAttributeField[]>(url, payLoad);
    }

    saveDocumentMetadata(
        documentFormValues: any,
        documentAttributes: DocumentAttribute[],
        candidateDocumentId: number,
        documentTypeId: number,
        contractId: number,
        isEdit: boolean
    ): Observable<AssociateMetadataToContractResponseDto> {
        const url = combine(this._complianceMetadataPath, candidateDocumentId.toString());
        const expirationDate = documentFormValues.expirationDate;
        const note = documentFormValues.note;

        const payLoad: AssociateMetadataToContractRequest = {
            documentTypeId,
            contractId,
            expirationDate,
            documentAttributes,
            note
        };

        if (isEdit) {
            return this._http.put<AssociateMetadataToContractResponseDto>(url, payLoad);
        } else {
            return this._http.post<AssociateMetadataToContractResponseDto>(url, payLoad);
        }
    }

    deleteDocument(candidateDocumentId: number): Observable<HttpResponse<any>> {
        const url = combine(this._vendorCandidatesPath, candidateDocumentId.toString());
        return this._http.delete(url, { observe: 'response' });
    }

    setCandidate(candidate: Candidate) {
        this.candidate = candidate;
        this.candidate$.next(candidate);
    }
}
