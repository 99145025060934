export const freeAccessItems = [
    'signin',
    'home',
    'account',
    'resetpassword',
    'definepassword',
    'access',
    'externalAccess',
    'units-complete',
    'units',
    'eval-form',
    'performanceeval-complete',
    'performanceeval-pdf',
    'performanceeval-base64',
    'travelers_extension',
    'travelers_signWoc',
    'devops',
    'sso',
    'login',
    'terms-and-conditions'
];

export const closedAccessItems = [];
