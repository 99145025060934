import { Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

type display = 'locale' | 'utc';

@Pipe({
    name: 'dayJSFormat',
    pure: true
})
export class DayJSFormatPipe implements PipeTransform {
    transform(dateTime: Dayjs | undefined, format: string = 'L', display: display = 'locale'): string {
        dayjs.extend(localizedFormat);

        if (!dateTime) {
            return '';
        }

        if (display === 'utc') {
            return dayjs.utc(dateTime).format(format);
        }

        return dayjs(dateTime).local().format(format);
    }
}
