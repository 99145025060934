import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import {
    exportInvoiceLineDisputes,
    exportInvoiceLineDisputesSuccess,
    exportInvoiceLineDisputesFail,
    exportInvoiceLineDisputesHistory,
    exportInvoiceLineDisputesHistorySuccess,
    exportInvoiceLineDisputesHistoryFail
} from '../invoices.actions';
import { of } from 'rxjs';
import { Utilities } from 'src/app/core/utils';
import { ToasterService } from 'src/app/core/services';
import * as dayjs from 'dayjs';

@Injectable()
export class InvoiceLineDisputesExportEffect {
    exportInvoiceDisputesHistory$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(exportInvoiceLineDisputesHistory),
            switchMap((action) => {
                const fnameIds = action.invoiceIds.length === 1 ? `-${action.invoiceIds[0]}-` : '-';
                const filename = `InvoiceLinesDisputes-History${fnameIds}${dayjs().format('MMDDYYYY')}`;

                return this._invoicesRepository.exportInvoiceLineItemDisputes(action.invoiceIds, true).pipe(
                    map((response) =>
                        exportInvoiceLineDisputesHistorySuccess({
                            blob: response,
                            filename
                        })
                    ),
                    catchError((error: unknown) => of(exportInvoiceLineDisputesHistoryFail({ error })))
                );
            })
        );
    });

    downloadInvoiceDisputesHistory$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(exportInvoiceLineDisputesHistorySuccess),
                tap((action) => {
                    Utilities.downloadBlob(action.blob, action.filename);
                })
            );
        },
        { dispatch: false }
    );

    exportInvoiceDisputesHistoryFail$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(exportInvoiceLineDisputesHistoryFail),
                tap(() => this._toaster.fail('Failed to export Invoice Line Disputes'))
            );
        },
        { dispatch: false }
    );

    exportInvoiceDisputes$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(exportInvoiceLineDisputes),
            switchMap((action) => {
                const fnameIds = action.invoiceIds.length === 1 ? `-${action.invoiceIds[0]}-` : '-';
                const filename = `InvoiceLinesDisputes${fnameIds}${dayjs().format('MMDDYYYY')}`;

                return this._invoicesRepository.exportInvoiceLineItemDisputes(action.invoiceIds, false).pipe(
                    map((response) =>
                        exportInvoiceLineDisputesSuccess({
                            blob: response,
                            filename
                        })
                    ),
                    catchError((error: unknown) => of(exportInvoiceLineDisputesFail({ error })))
                );
            })
        );
    });

    downloadInvoiceDisputes$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(exportInvoiceLineDisputesSuccess),
                tap((action) => {
                    Utilities.downloadBlob(action.blob, action.filename);
                })
            );
        },
        { dispatch: false }
    );

    exportInvoiceDisputesFail$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(exportInvoiceLineDisputesFail),
                tap(() => this._toaster.fail('Failed to export Invoice Line Disputes'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _invoicesRepository: InvoicesRepositoryService,
        private readonly _toaster: ToasterService
    ) {}
}
