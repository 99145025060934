import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { configurations as domainConfigs } from 'src/app/config/domain-config';
import { DomainConfiguration } from '../models';

const CURRENT_DATE_YEAR = '%current_date_year%';

@Injectable()
export class DomainService {
    private _environment = '';

    constructor(
        @Inject('Window') private readonly _window: Window,
        @Inject(DOCUMENT) private readonly _document: Document
    ) {
        this._environment = this.environment();

        for (const override of this._window['servicesConfig']['domainConfig']) {
            if (override.environment === this._environment) {
                this.setValue(override.key, override.value);
            }
        }
        this._document.body.classList.add(`env-${this._environment.toLowerCase()}`);
    }

    get configurations(): DomainConfiguration[] {
        return domainConfigs;
    }

    getValue(key: string): any {
        const configuration = this.configurations.find(
            (config) => config.environment === this._environment && config.key === key
        );

        if (!configuration) {
            return null;
        }

        return this._fixStringContent(configuration.value);
    }

    setValue(key: string, value: string): void {
        const configuration = this.configurations.find(
            (config) => config.environment === this._environment && config.key === key
        );

        if (configuration) {
            configuration.value = value;
        } else {
            this.configurations.push({
                environment: this._environment,
                key,
                value
            });
        }
    }

    getUrl(key: string): string {
        const urls = this.getValue('URLS');
        const url = urls[key];

        return url;
    }

    environment(): string {
        const host = this._window.location.host;
        return (
            this.configurations
                .filter((config) => !config.environment && host.includes(config.key))
                .map((config) => this._fixStringContent(config.value).toString())[0] || 'ONE'
        );
    }

    getFaviconName(): string {
        return `${this._environment.toLowerCase()}favicon.ico`;
    }

    isEnvironment(env: string): boolean {
        return this._environment === env;
    }

    check(key: string): boolean {
        return !!this.getValue(key);
    }

    signOutUrl(): string {
        return this.getValue('SIGN_OUT_URL');
    }

    intSignOutUrl(): string {
        return this.getValue('INT_SIGN_OUT_URL');
    }

    private _fixStringContent(value: unknown): unknown {
        let oldValue = value;

        if (typeof oldValue !== 'string') {
            return oldValue;
        }

        if (oldValue.includes(CURRENT_DATE_YEAR)) {
            const date = new Date();
            oldValue = oldValue.replace(CURRENT_DATE_YEAR, date.getFullYear().toString());
        }

        return oldValue;
    }
}
