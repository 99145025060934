import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { SubmittalStatuses } from 'src/app/shared/models/submittals';
import { Editor } from 'ngx-editor';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'aya-update-status-dialog',
    templateUrl: 'update-status-dialog.component.html',
    styleUrls: ['./update-status-dialog.component.scss']
})
export class UpdateStatusDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    featureFlag = FeatureFlag;
    form: FormGroup = new FormGroup({ notes: new FormControl('') }, this.notesValidator());
    noteItemIsNotValid = false;
    submittalStatuses = SubmittalStatuses;
    editor!: Editor;
    notePlaceholder = 'Note';
    notesText = 'Notes';
    isVendorClientCommunicationEnabled = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            submittalStatusName: string;
            submittalSubStatusName: string;
            submittalStatusId?: SubmittalStatuses;
            facilityDirectApplyEnabled: boolean;
        },
        private readonly _dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    get isMoreInformationNeededSelected(): boolean {
        return this.data.submittalStatusId === SubmittalStatuses.MoreInformationNeeded;
    }

    get notes(): string {
        return this.form?.get('notes')?.value;
    }

    ngOnInit() {
        this.ldFeatureManager
            .isEnabled(FeatureFlag.VendorClientCommunicationForApnDirectSubmittals)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isVendorClientCommunicationEnabled = isEnabled && this.data.facilityDirectApplyEnabled;
                if (this.isVendorClientCommunicationEnabled) {
                    this.editor = new Editor();
                    this.notePlaceholder = 'Comment';
                    this.notesText = 'Comments';
                }
            });
    }

    updateStatus() {
        this._dialogRef.close({ notes: this.form.get('notes').value });
    }

    onNoteItemValidationChanged(isValid: boolean) {
        this.noteItemIsNotValid = !isValid;
    }

    notesValidator(): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            return this.isMoreInformationNeededSelected && !this.notes ? { invalidNote: true } : null;
        };
    }
}
