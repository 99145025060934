export enum ShiftsGettingRealFeatureFlags {
    DisableEditOfBookedShiftSeries = 'DISABLE_EDIT_OF_BOOKED_SHIFT_SERIES',
    InternalPoolArchiveNewPermission = 'INTERNAL_POOL_ARCHIVE_NEW_PERMISSION',
    EnableShiftSeriesEditAfterBooking = 'ENABLE_SHIFT_SERIES_EDIT_AFTER_BOOKING',
    LimitingProfessionsAndSpecialtiesPerFacility = 'LIMITING_PROFESSIONS_AND_SPECIALTIES_PER_FACILITY',
    LimitArchiveWorkerToFullSystemUser = 'limit-archive-worker-to-full-system-user',
    ConnectShiftBonusManagement = 'WFD-2864-connect-shift-bonus-rate-management',
    RedirectShiftsInConnect = 'redirect-shifts-in-connect',
    IRPCustomizedProfAndSpec = 'connect-internal-pool-customized-prof-and-spec',
    IrpWorkerPageChangeColumnOrder = 'irp-worker-page-change-column-order',
    ErrorMessageWhenRedirectNotElible = 'error-message-when-redirect-not-eligible',
    ToolTipStatingThatTheShiftSeriesHasBeenCanceled = 'tooltip-stating-that-the-shift-series-has-been-canceled',
    EnableNyuToLeverageTheAllowEditingShiftSeriesOnceBooked = 'enable-nyu-to-leverage-the-allow-editing-shift-series-once-booked',
    ArchiveWorkerButtonVisibleBug = 'archive-worker-button-visible-bug',
    AddingClientInternalIdFieldToLocalPoolPage = 'adding-client-internal-id-field-to-local-pool-page',
    ShiftBonusRatesForIRP = 'shift-bonus-rates-for-irp'
}
