import {
    Component,
    Input,
    ContentChildren,
    QueryList,
    Output,
    EventEmitter,
    ViewChild,
    ContentChild,
    TemplateRef,
    ViewEncapsulation,
    OnInit,
    AfterViewInit,
    NgZone,
    Inject,
    ChangeDetectorRef
} from '@angular/core';
import { GridColumnDirective } from './grid-column.directive';
import { SortDescriptor, CompositeFilterDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { GridColumnFormatEnum } from '../enums/grid-column-format.enum';
import { GridColumnFilterEnum } from '../enums/grid-column-filter.enum';
import {
    FilterableSettings,
    PageChangeEvent,
    DataStateChangeEvent,
    SelectionEvent,
    RowArgs,
    SelectableMode,
    GridComponent as KendoGridComponent,
    FilterService,
    CellClickEvent,
    RowClassArgs,
    PagerSettings
} from '@progress/kendo-angular-grid';
import { GridStateChangeEvent, GridSearchQuery } from '../models';
import { GridQuickFilterComponent } from './grid-quick-filter.component';
import { GridQuickFilterEnum } from '../enums/grid-quick-filter.enum';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { DOCUMENT } from '@angular/common';
import { GridDataResult } from '../models/grid-data-result.model';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';

@Component({
    selector: 'portal-grid',
    templateUrl: 'grid.component.html',
    styleUrls: ['./grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GridComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    GridColumnFormatEnum = GridColumnFormatEnum;
    GridColumnFilterEnum = GridColumnFilterEnum;
    GridQuickFilterEnum = GridQuickFilterEnum;

    @ContentChildren(GridColumnDirective) columns: QueryList<GridColumnDirective>;
    @ContentChildren(GridQuickFilterComponent) quickFilters: QueryList<GridQuickFilterComponent>;
    @ContentChild('toolbar', { static: true }) toolbarTemplate: TemplateRef<any>;
    @ContentChild('header', { static: true }) headerTemplate: TemplateRef<any>;
    @ContentChild('headerToolbar', { static: true }) headerToolbarTemplate: TemplateRef<any>;
    @ContentChild('prefixContainer', { static: true }) prefixContainerTemplate: TemplateRef<any>;
    @ViewChild(KendoGridComponent, { static: true }) grid: KendoGridComponent;
    @ViewChild(KendoGridComponent, { read: FilterService, static: true }) filterService;

    quickFiltersHeader: GridQuickFilterComponent[];
    quickFiltersToolbar: GridQuickFilterComponent[];

    @Input() toolbarHidden = false;
    @Input() hideToolbarSpacer = false;
    @Input() headerHidden = false;
    @Input('data-title') title: string = '';
    /**
     * Use this property instead of title if you don't want global tooltip
     * over the grid.
     **/
    @Input() set headerTitle(value: string) {
        this.title = value;
    }

    @Input() clearSelectedAfterStateChange = true;

    @Input()
    set data(value: GridDataResult | any[]) {
        this.clearAllSelection(this.clearSelectedAfterStateChange);
        this._data = value;
    }

    get data(): GridDataResult | any[] {
        return this._data;
    }

    @Input() pageSize = 25;
    @Input() canExportToPdf = false;
    @Input() scrollable = 'none';
    @Input() loading = false;
    @Input() useDataFilteredPropertyForTotal = true;
    @Input() rowClass: (context: RowClassArgs) => any = () => {};
    @Input() uniqueProperty = 'id';
    @Input() useHeaderInPdf = false;
    @Input() groupable = false;
    @Input() detailViewTemplate: TemplateRef<unknown>;
    @Input() needDetailView = false;
    @Input() uniqueFilterClass: string;
    @Input() customNoRecordsMessage: string = '';

    skip: number = 0;
    sort: SortDescriptor[] = [];
    sortable = {
        allowUnsort: false,
        mode: 'single'
    };
    groups: GroupDescriptor[] = [];

    @Input() filterable: FilterableSettings = true;
    filter: CompositeFilterDescriptor = {
        logic: 'and',
        filters: []
    };

    @Input('selectable') selectableEnabled: boolean = false;
    @Input() selectableMode: SelectableMode = 'single';

    @Input() selectableResolver: (any) => boolean = () => true;
    @Input() autoFitColumns = false;

    @Input()
    get name() {
        return `nova-grid:${this._name}`;
    }
    set name(val) {
        this._name = val;
    }

    @Input() set pageable(pageable: boolean | PagerSettings) {
        if (pageable) {
            this._pageable = { pageSizes: [10, 20, 25, 50, 100] };
        } else {
            this._pageable = false;
        }
    }

    get pageable(): boolean | PagerSettings {
        return this._pageable;
    }

    @Input() set showPageSizes(showPageSizes: boolean) {
        this._pageable = { pageSizes: showPageSizes ? [10, 20, 25, 50, 100] : false };
    }

    @Input() set exportPdf(exportPdf: number) {
        if (exportPdf > 0) {
            this.grid.saveAsPDF();
        }
    }

    @Input() saveState: boolean = false;
    @Input() ignoreSelectRowColumns = new Array<string>();

    get selectableSettings() {
        return {
            enabled: this.selectableEnabled,
            mode: this.selectableMode
        };
    }

    @Input() selectedRows: any[] = [];
    allRowsSelected = false;

    kendoColumnFormat = {
        [GridColumnFormatEnum.DATE]: { date: 'short' },
        [GridColumnFormatEnum.CURRENCY]: { style: 'currency' },
        [GridColumnFormatEnum.NUMBER]: '{0:0}'
    };

    @Output() pageChange = new EventEmitter<PageChangeEvent>();
    @Output() sortChange = new EventEmitter<SortDescriptor[]>();
    @Output() dataStateChange = new EventEmitter<GridStateChangeEvent>();
    @Output() rowSelected = new EventEmitter<any>();
    @Output() cellClicked = new EventEmitter<any>();
    @Output() rowClicked = new EventEmitter<any>();
    @Output() groupChange = new EventEmitter<GroupDescriptor[]>();
    @Output() gridClicked = new EventEmitter<any>();

    @Input() set query(query: GridSearchQuery) {
        if (!query) {
            return;
        }

        const { take, skip, sort, filter, groups } = query;
        this.pageSize = take;
        this.skip = skip;
        this.sort = sort;
        this.groups = groups;
        this.filter = filter;
    }

    private _data: any[] | GridDataResult = {
        data: [],
        total: 0
    };
    private _name: string;
    private _initState: any;
    private _pageable: PagerSettings | boolean = { pageSizes: [10, 20, 25, 50, 100] };
    public get kendoGridData(): GridDataResult | any[] | undefined {
        if (!this.data) {
            return undefined;
        }

        let gridDataResult = this.data as GridDataResult;
        if (!Array.isArray(gridDataResult)) {
            return {
                data: gridDataResult.data,
                total:
                    this.useDataFilteredPropertyForTotal && (gridDataResult.filtered || gridDataResult.filtered === 0)
                        ? gridDataResult.filtered
                        : gridDataResult.total
            } as GridDataResult;
        }

        //This can be used to temporarily support grids which are still using any[] in the data input field.
        return this.data;
    }

    constructor(
        route: ActivatedRoute,
        private readonly ngZone: NgZone,
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly cd: ChangeDetectorRef,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
        this.isRowSelected = this.isRowSelected.bind(this);

        this.name = this.name || route.snapshot['_routerState'].url;
    }

    ngOnInit() {
        const savedState = this.persistentState || this.tempState;
        this._initState = this.currentState;

        if (this.saveState && savedState) {
            this.dataStateChange.emit(savedState);
        }
    }

    selectAll(): void {
        this.selectedRows = [];

        (this.grid.data as GridDataResult).data.forEach((row) => {
            this.selectedRows.push(row);
        });
        this.allRowsSelected = true;
    }

    selectRows(rows: any[]) {
        this.selectedRows = [];
        rows?.forEach((s) => this.selectedRows.push(s));
        this.allRowsSelected = (this.data as GridDataResult).data.length === this.selectedRows.length;
        this.individualItemChange(false);
    }

    onPageChange(event: PageChangeEvent) {
        this.pageChange.emit(event);
    }

    onSortChange(sort: SortDescriptor[]) {
        this.sortChange.emit(sort);
    }

    onFilterChange(filter: CompositeFilterDescriptor) {
        // Allow row selection to persist after filter change
        // this.clearSelection();
    }

    onDataStateChange(state: DataStateChangeEvent) {
        this.dataStateChange.emit(state);
        this.query = state;

        if (this.saveState) {
            this.persistentState = this.currentState;
        }
        this.allRowsSelected = false;
    }

    onSelection(event: SelectionEvent) {
        if (!event.ctrlKey && this.selectableMode === 'multiple') {
            return;
        }

        if (event.selectedRows.length) {
            if (this.selectableMode === 'single') {
                this.selectedRows = [event.selectedRows[0].dataItem];
            } else {
                Array.prototype.push.apply(
                    this.selectedRows,
                    event.selectedRows.map((row) => row.dataItem)
                );
            }
        }

        if (event.deselectedRows.length) {
            this.selectedRows = this.selectedRows.filter(
                (item) => !event.deselectedRows.find((row) => this.compareDateItems(row.dataItem, item))
            );
        }

        this.selectedRows = this.selectedRows.filter(this.selectableResolver);

        this.rowSelected.emit(this.selectedRows);
    }

    isRowSelected(e: RowArgs) {
        return this.selectedRows.some((a) => this.compareDateItems(a, e.dataItem));
    }

    onCellClick(event: CellClickEvent) {
        this.cellClicked.emit(event);

        const ignoreRowSelectColumn = this.ignoreSelectRowColumns?.find(
            (c) => c.toLowerCase() === event?.column?.field?.toLowerCase()
        );
        if (ignoreRowSelectColumn) {
            return;
        }

        this.rowClicked.emit(event.dataItem);
    }

    onGroupChange(event: GroupDescriptor[]) {
        this.groupChange.emit(event);
    }

    onSaveState() {
        this.persistentState = this.currentState;
    }

    onResetState() {
        window.localStorage.removeItem(this.name);
        this.dataStateChange.emit(this._initState);
    }

    individualItemChange(emitEvent = true): void {
        let gridDataResult: GridDataResult;
        if (!Array.isArray(this.data as GridDataResult)) {
            gridDataResult = this.data as GridDataResult;
        } else {
            gridDataResult = { data: this.data as any[], total: (this.data as any[]).length };
        }

        const selectableItems = gridDataResult.data.filter(this.selectableResolver);
        const selectAllCheckbox = this._document.getElementById(`selectAllCheckbox_${this.name}`) as HTMLInputElement;

        if (this.selectableMode === 'multiple' && this.selectedRows.length) {
            if (this.selectedRows.length !== selectableItems.length && selectAllCheckbox) {
                selectAllCheckbox.indeterminate = true;
            } else {
                if (selectAllCheckbox) {
                    selectAllCheckbox.indeterminate = false;
                    selectAllCheckbox.checked = true;
                }
            }
        } else {
            if (selectAllCheckbox) {
                selectAllCheckbox.indeterminate = false;
                selectAllCheckbox.checked = false;
            }
        }

        if (emitEvent) {
            this.rowSelected.emit(this.selectedRows);
        }
    }

    clearAllSelection(clearSelected = true) {
        if (clearSelected) {
            const element = this._document.getElementById(`selectAllCheckbox_${this.name}`) as HTMLInputElement;

            if (element) {
                element.indeterminate = false;
                element.checked = false;
            }
            this.allRowsSelected = false;
            this.selectedRows = [];
        }
    }

    onSelectAllChange($event) {
        if ($event.target.checked) {
            let gridDataResult: GridDataResult;
            if (!Array.isArray(this.data as GridDataResult)) {
                gridDataResult = this.data as GridDataResult;
            } else {
                gridDataResult = { data: this.data as any[], total: (this.data as any[]).length };
            }
            this.allRowsSelected = true;
            this.selectedRows = gridDataResult.data.filter(this.selectableResolver);
        } else {
            this.allRowsSelected = false;
            this.selectedRows = [];
        }

        this.rowSelected.emit(this.selectedRows);
    }

    clearSelection() {
        this.selectedRows = [];
        this.allRowsSelected = false;
        this.individualItemChange();
        this.rowSelected.emit(this.selectedRows);
    }

    onGridClick($event: any) {
        this.gridClicked.emit($event);
    }

    get currentState(): DataStateChangeEvent {
        return {
            // skip: this.skip,
            skip: 0,
            take: this.pageSize,
            sort: this.sort,
            // group: this.group,
            filter: this.filter
        };
    }

    get isSelectableMultipleMode() {
        return this.selectableMode === 'multiple';
    }

    get persistentState(): DataStateChangeEvent {
        if (!window.localStorage.getItem(this.name)) {
            return null;
        }
        try {
            return JSON.parse(window.localStorage.getItem(this.name));
        } catch (e) {
            return null;
        }
    }

    set persistentState(state: DataStateChangeEvent) {
        window.localStorage.setItem(this.name, JSON.stringify(state));
    }

    get tempState(): DataStateChangeEvent {
        if (!window.sessionStorage.getItem(this.name)) {
            return null;
        }
        try {
            return JSON.parse(window.sessionStorage.getItem(this.name));
        } catch (e) {
            return null;
        }
    }

    set tempState(state: DataStateChangeEvent) {
        window.sessionStorage.setItem(this.name, JSON.stringify(state));
    }

    private compareDateItems(a, b) {
        return a[this.uniqueProperty] === b[this.uniqueProperty];
    }

    ngAfterViewInit() {
        if (this.autoFitColumns) {
            this.fitColumns();
        }
        const quickFilters$ = merge(of(this.quickFilters), this.quickFilters.changes);

        quickFilters$.pipe(takeUntil(this.d$)).subscribe((items) => {
            this.quickFiltersHeader = items.filter((c) => c.place === 'header');
            this.quickFiltersToolbar = items.filter((c) => c.place === 'toolbar');
            // Added to fix "Expression has been changed after it was checked error"
            this.cd.detectChanges();
        });
    }

    private fitColumns(): void {
        this.ngZone.onStable
            .asObservable()
            .pipe(take(1))
            .subscribe(() => {
                this.grid.autoFitColumns();
            });
    }
}
