export enum ConnexusFeatureFlags {
    DisplayPreferredShiftInVendorSubmittals = 'display-preferred-shift-in-vendor-submittals',
    DisplaySubmittalLevelPreferredShiftsAndAvailableDateInSubmittalsDetails = 'display-submittal-level-preferred-shifts-and-available-date-in-submittals-details',
    AvoidRecreatingPhoneRecordsDuringVendorCandidateUpdate = 'avoid-recreating-phone-records-during-vendor-candidate-update',
    RetainSubmittalIdUponSave = 'submittal-retain-id-upon-save',
    UseAyaConnectToCallUnitDescriptionApis = 'UseAyaConnectToCallUnitDescriptionApis',
    UseLocalDateTimeForVendorCandidateSubmittalRTOAvailableDate = 'use-local-datetime-for-vendor-candidate-submittal-rto-available-date',
    SkipDetailFilesRequestNewAdminVendorCandidate = 'skip-detail-files-request-new-admin-vendor-candidate',
    AddAgentNameAndEmailForVmsSubmittals = 'add-agent-name-and-email-for-vms-submittals',
    LazyLoadVendorJobRequirementDescriptions = 'lazy-load-vendor-job-requirement-descriptions',
    SetVendorCandidateNpiFromFormControl = 'set-vendor-candidate-npi-from-form-control',
    AlwaysUseFacilityOrientationHoursForVendorJobDetails = 'always-use-facility-orientation-hours-for-vendor-job-details',
    RestrictCandidatesToMatchJobProfession = 'restrict-candidates-to-match-job-profession',
    ClientSubmittalDetailsMoveActivitySection = 'client-submittal-details-move-activity-section',
    AddContractGroupToVendorsJobs = 'add-contract-group-to-vendors-jobs',
    LazyLoadVendorCandidateContractRequirements = 'lazy-load-vendor-contract-requirements',
    VendorClientCommunicationForApnDirectSubmittals = 'enable-vendor-client-communication-for-apn-direct-submittals',
    DisplayCAMinimumWage = 'display-ca-minimum-wage',

    // Start SergiiM
    VendorJobDetailsCaMinimumWageType = 'vendor-job-details-ca-minimum-wage-type',
    VendorSubmittalsDetailsMoveStatusToTopRight = 'vendor-submittals-details-move-status-to-top-right',
    UseLocalDatetimeForVendorCandidateDateOfBirth = 'use-local-datetime-for-vendor-candidate-date-of-birth',
    DisableUtcConversionForVendorFields = 'disable-utc-conversion-for-vendor-fields',
    DisableUtcConversionForAdminFields = 'disable-utc-conversion-for-admin-fields',
    // End SergiiM

    // Start Will
    SetSendToClientEmailBodyAsStatusChangeNoteFromLiveList = 'set-send-to-client-email-body-as-status-change-note-from-live-list'
    // End Will
}
