import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import {
    loadInvoiceDetails,
    loadInvoiceDetailsFail,
    loadInvoiceDetailsSuccess,
    openInvoiceAttachmentInViewer
} from '../invoices.actions';
import { of } from 'rxjs';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';
import { VendorExpensesService } from 'src/app/vendor-expenses/services/vendor-expenses.service';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

@Injectable()
export class InvoiceDetailsEffect extends BaseEffect {
    getInvoiceDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadInvoiceDetails),
            switchMap((action) => {
                return this.invoicesRepository.getInvoiceDetails(action.id, action.isBillable).pipe(
                    map((response) =>
                        loadInvoiceDetailsSuccess({
                            invoiceDetails: response
                        })
                    ),
                    catchError((error: unknown) => of(loadInvoiceDetailsFail({ error })))
                );
            })
        );
    });

    loadInvoiceDetailsFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadInvoiceDetailsFail),
                tap((action) => this.handleError(action.error))
            );
        },
        { dispatch: false }
    );

    openAttachmentInViewer$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openInvoiceAttachmentInViewer),
                tap((action) => {
                    this.window.open(
                        `/#/client/invoiced/${action.invoiceId}/attachments/${action.documentId}/${action.documentName}`,
                        '_blank'
                    );
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        @Inject('Window') private readonly window: Window,
        private readonly actions$: Actions,
        private readonly invoicesRepository: InvoicesRepositoryService,
        private readonly vendorExpenseService: VendorExpensesService,
        private readonly ld: LDFeatureManager,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
