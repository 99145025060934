import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { VendorRequirementDocumentUploadInfo } from '../../store/models/vendor-requirement-document-upload-info';

@Component({
    selector: 'ayac-document-upload-info',
    templateUrl: './document-upload-info.component.html',
    styleUrls: ['./document-upload-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentUploadInfoComponent extends UnsubscribeOnDestroy {
    @Input() displayedColumns: string[] = ['name', 'lastModifiedDate', 'uploadedBy', 'actions'];
    @Input() dataSource = new MatTableDataSource<VendorRequirementDocumentUploadInfo>();
    @Input() isEdit = false;
    @Output() deleteAttachment = new EventEmitter<boolean>();

    get attachmentDataHasValue() {
        return this.dataSource && this.dataSource.data.length;
    }

    deleteAttachmentClick() {
        this.deleteAttachment.emit(true);
    }
}
