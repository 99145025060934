import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, catchError, switchMap, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsVendorService } from '../../submittals-vendor.service';
import { submittalsVendorActions } from 'src/app/submittals-vendor/store/actions/';

@Injectable()
export class SubmittalsVendorLookupsEffects {
    loadVendorSubmittalStages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                submittalsVendorActions.SubmittalsVendorActions.componentLoaded,
                submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalStages
            ),
            exhaustMap((actions) =>
                this.submittalsVendorService.getSubmittalStages().pipe(
                    map((stages) =>
                        submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalStagesSuccess({
                            stages: stages
                        })
                    ),
                    catchError((error) =>
                        of(submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalStagesFail({ error }))
                    )
                )
            )
        )
    );

    loadVendorSubmittalStageReasons$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalStageReasons),
            switchMap((actions) =>
                this.submittalsVendorService.getSubmittalStagesReasons(actions.showVendor).pipe(
                    map((stageReasons) =>
                        submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalStageReasonsSuccess({
                            stageReasons
                        })
                    ),
                    catchError((error) =>
                        of(
                            submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalStageReasonsFail({
                                error
                            })
                        )
                    )
                )
            )
        )
    );

    getLookupsStatuses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsStatuses),
            exhaustMap((action) =>
                this.submittalsVendorService.getStatuses().pipe(
                    map((res) =>
                        res
                            ? submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsStatusesSuccess(
                                  { statuses: res }
                              )
                            : submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsStatusesFail({
                                  error: 'Cannot load submittal statuses.'
                              })
                    ),
                    catchError((error) =>
                        of(
                            submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsStatusesFail({
                                error
                            })
                        )
                    )
                )
            )
        )
    );

    getLookupsSubStatuses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsSubStatuses),
            exhaustMap((action) =>
                this.submittalsVendorService.getSubStatuses(action.statusId).pipe(
                    map((res) =>
                        res
                            ? submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsSubStatusesSuccess(
                                  { subStatuses: res }
                              )
                            : submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsSubStatusesFail(
                                  {
                                      error: 'Cannot load submittal sub statuses.'
                                  }
                              )
                    ),
                    catchError((error) =>
                        of(
                            submittalsVendorActions.SubmittalsVendorLookupsActions.loadSubmittalsLookupsSubStatusesFail(
                                {
                                    error
                                }
                            )
                        )
                    )
                )
            )
        )
    );
    constructor(
        private readonly actions$: Actions,
        private readonly submittalsVendorService: SubmittalsVendorService
    ) {}
}
